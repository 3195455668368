
import { getAllProducts } from '../../services/ProductServices';

export function checkForDuplicateProduct(name,value, cookies, existingId) {
    
    
    let nameCheck = new Promise((resolve, reject) => {
      getAllProducts(cookies,value)
      .then((data) => {

        let isUserExists = false;
        data.product_list.forEach(product =>{  
             //Same product Name mentioned it denoted already exist.
          if((product.name.toUpperCase())=== value.trim().toUpperCase()
          && parseInt(existingId)!==product.id){
            console.log(`product exits with the name - ${value}`)
            isUserExists =true;
          }
        });
        resolve(isUserExists);
      });
    });
    return nameCheck;
  }