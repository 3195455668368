import { useEffect, useState, useMemo } from "react";
import React from 'react';
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF,generatePDFFromHTML } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import {Row,Col} from "reactstrap";
import {getWorkScheduleReport,getWorkScheduleReportCSV,getWorkScheduleReportPDF} from "../../services/ReportServices";
import {  DatePicker } from 'antd';
import { getUserAllowedPlants} from '../../services/PlantServices';
import moment  from 'moment';
import {displayErrorToast,displayError,parseBoolean,parseBooleanToString} from '../../helpers';
import {Button} from '@mui/material';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';  

const {RangePicker} = DatePicker;

function WorkScheduleReportTable() 
{
    const InitialData = {
        records: []
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 
    const [plants, setPlants] = React.useState([]);
    const [input, setInput] = useState({});

    // Initial state for from_date and to_date
    const initialFromDate = moment().startOf('month');
    const initialToDate =  moment();
    const [fromDate, setFromDate] = useState(initialFromDate);
    const [toDate, setToDate] = useState(initialToDate);
    const handleDateChange = (values) => {
        if ((values) && (values.length === 2)) {
        setFromDate(values[0]);
        setToDate(values[1]);
        }
    };

    React.useEffect(() => {
        //Load data for Plant dropdown
        getUserAllowedPlants(cookies)
        .then (
            plantList => {
                const plants = plantList.plant_list.filter(obj => obj.status).map(
                    plant => {
                        return { value: plant.id, label: plant.plant_alias }
                    }
                );
                setPlants(plants);
            }
        )
    }, []);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        console.log(name+value+"name and value")
        setInput(values => ({...values, [name]: event.target.value}))
    }

    const columns = useMemo(
        () => [
            {
                fieldName: "schedule_date",
                headerName: 'Date'
            },
            {
                fieldName: 'plant_alias', 
                headerName: 'Plant Alias'
            },
            {
                fieldName:"customer_name",
                headerName:'Customer Name'
            },
            {
                fieldName: "project_name",
                headerName: 'Site/Project Name'
            },
            {
                fieldName: 'sales_order_no', 
                headerName: 'SO Number'
            },
            // {
            //     fieldName: 'site_address', 
            //     headerName: 'Location'
            // },
            {
                fieldName: 'grade', 
                headerName: 'Grade'
            },
            {
                fieldName: 'scheduled_qty', 
                headerName: 'Qty'
            },
            {
                fieldName: 'delivery_date', 
                headerName: 'Delivery Date'
            },
            {
                fieldName: 'delivery_time', 
                headerName: 'Delivery Time'
            },
            {
                fieldName: "delivery_mode",
                headerName: 'Service'
            },
            {
                fieldName: 'structure', 
                headerName: 'Structure'
            },
            {
                fieldName: "salesrep_name",
                headerName: 'Marketting'
            },
            {
                fieldName:"contact_mobile_no",
                headerName:'Mobile No'
            },
            {
                fieldName:"status",
                headerName:'Status'
            }
        ],
        [],);
   
    const handleCSVExport = (event) => {
    };

    
    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'Work Schedule Report',
            columns
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(wrkschedule_obj => ({
                schedule_date: wrkschedule_obj.schedule_date,
                    plant_alias: wrkschedule_obj.plant_alias,
                    project_name:wrkschedule_obj.project_name,
                    sales_order_no:  wrkschedule_obj.sales_order_no,
                    site_address:wrkschedule_obj.site_address,
                    grade:wrkschedule_obj.grade,
                    status : wrkschedule_obj.status,
                    scheduled_qty:wrkschedule_obj.scheduled_qty,
                    delivery_mode:wrkschedule_obj.delivery_mode,
                    structure:wrkschedule_obj.structure,
                    salesrep_name:wrkschedule_obj.salesrep_alias,
                    delivery_date:wrkschedule_obj.delivery_date,
                    contact_mobile_no:wrkschedule_obj.contact_mobile_no,
                    delivery_time: wrkschedule_obj.delivery_time
            })),
            'WorkScheduleReport.pdf'
        );
    };
    
    const constructFormData = () => {
        const EMPTY_STRING = '';
        return {
            plant_id:input.plant_id,
            from_date:fromDate.format('DD-MM-YYYY'),
            upto_date:toDate.format('DD-MM-YYYY')
          }
    }

    const handleDownloadCsv = () => {
        // console.log(JSON.stringify(constructFormData())+"submit")
        const inputs = constructFormData()
        if ((inputs.from_date) && (inputs.upto_date) && (inputs.plant_id))
        {
            getWorkScheduleReportCSV(cookies, constructFormData())
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'work_schedule_report.csv');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch((error) => {
                console.log(error.response.data);
                displayError(error.response.data);
            });
        }
        else 
        {
            Swal.fire("Plant,from_date,upto_date must not be empty.", "", "info");  
        }
    };

    
    const handleDownloadPdf = () => {
        console.log(JSON.stringify(constructFormData())+"handleDownloadPdf")
        const inputs = constructFormData()
        console.log(JSON.stringify(inputs)+"inputs handleDownloadPdf");
        if ((inputs.from_date) && (inputs.upto_date) && (inputs.plant_id))
            {
                getWorkScheduleReportPDF(cookies, constructFormData())
        .then(response => {
            const file = new Blob([response], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
            setIsLoading(false);
        })
        .catch(error => {
            console.error(error);
            displayError(error, 'Custom print/export failed');
            setIsLoading(false);
        });
            }
            else 
            {
                Swal.fire("Plant,from_date,upto_date must not be empty.", "", "info");  
            }
    };

    const handleSubmit=(event)=> 
    {
        event.preventDefault();
        console.log(JSON.stringify(constructFormData())+"submit")
        // const p = constructFormData()
        if ((fromDate) && (toDate))
        {
            getWorkScheduleReport(cookies, constructFormData())
            .then(response => {
                const tableData = response.work_schedule_report_list
                .map(wrkschedule_obj => ({
                    schedule_date: wrkschedule_obj.schedule_date,
                    plant_alias: wrkschedule_obj.plant_alias,
                    project_name:wrkschedule_obj.project_name,
                    sales_order_no:  wrkschedule_obj.sales_order_prefix + wrkschedule_obj.sales_order_no,
                    site_address:wrkschedule_obj.site_address_1 +", "+ wrkschedule_obj.site_address_2 +", "+ wrkschedule_obj.site_address_3,
                    grade:wrkschedule_obj.grade,
                    status : wrkschedule_obj.status,
                    scheduled_qty:wrkschedule_obj.scheduled_qty,
                    delivery_mode:wrkschedule_obj.delivery_mode,
                    structure:wrkschedule_obj.structure,
                    salesrep_name:wrkschedule_obj.salesrep_alias,
                    delivery_date:wrkschedule_obj.delivery_date,
                    delivery_time: wrkschedule_obj.delivery_time,
                    contact_mobile_no:wrkschedule_obj.contact_mobile_no,
                    customer_name:wrkschedule_obj.customer_name
                    }));
                setData({
                    records: tableData
                });
                setIsLoading(false);
            }).catch((error) => {
                console.log(error.response.data);
                displayError(error.response.data);
            });
        }
        else 
        {
            Swal.fire("Plant,From date,Upto date must not be empty.", "", "info");  
        }
    }

    return (
            <>
                <form className="form" onSubmit={handleSubmit}>
                    <div className="container"> 
                       <Row> 
                            <Col xl={1} lg={4} md={4}></Col>
                            <Col xl={10} lg={4} md={4}>
                                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel ">
                                    <select className="browser-default custom-select col-sm-5"  onChange={handleChange} value={input.plant_id || ""} name ="plant_id" required id="myData">
                                        <option value="">Please Select Plant</option>
                                        {plants.map((item) => (
                                            <option key={item.value} value={item.value}>
                                                {item.label}</option>
                                        ))}
                                    </select>&nbsp;&nbsp;  

                                    <RangePicker
                                        defaultValue={[initialFromDate, initialToDate]}
                                        format="DD-MM-YYYY"
                                        onChange={handleDateChange}
                                        style={{height:"40px",width:"220px"}}
                                    />
                                    &nbsp;&nbsp;

                                    <Button  type="submit"
                                        style={{backgroundColor: "RGB(188,232,253)",fontWeight:"bold",
                                        height:"30px",display: "flex",
                                        alignItems: "center",  
                                        justifyContent: "center"}}>
                                        <i className="fa fa-search fa-2x" id="myData" aria-hidden="true" style={{color:"rgb(93, 156, 233)"}}></i>
                                    </Button>
                                    <Button color="primary" onClick={handleDownloadCsv} startIcon={<FileDownloadRoundedIcon/>}  variant="contained">CSV</Button>
                                    &nbsp;&nbsp; 
                                    <Button color="primary" onClick={handleDownloadPdf} startIcon={<FileDownloadRoundedIcon/>}  variant="contained">PDF</Button>
                                    &nbsp;&nbsp;  
                                </div>
                             </Col>
                        </Row> 
                    </div> 
                </form>
                <div className="container item-list-table-container">
                    <div className="table-responsive">
                        <ReactTableWrapper
                            title='CustomerData Report'
                            columns={columns}
                            data={data.records}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                            enableExportToolbar={false}
                        />
                    </div>
                </div> 
            </>
);
}

export default WorkScheduleReportTable;