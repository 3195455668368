import React from 'react';
import { useState,useEffect } from "react";
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Button,
} from "reactstrap";
// import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import '../../App.css';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { useCookies } from 'react-cookie';
// import { Link } from 'react-router-dom';
import '../../button.css';
import {displayErrorToast,displayError} from '../../helpers';
// import LoadingOverlay from '../../components/LoadingOverlay';
// import ErrorToast from '../../ErrorToast';
import SettingsIcon from '../../components/SettingsIcon';
// import FloatingControls from '../../components/FloatingControls';
import { updateSettings,getSettingsDetails} from '../../services/SettingsServices';
function Settings() {
  const [cookies] = useCookies(['myToken']);
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [selectedOption_2, setSelectedOption_2] = useState('');
  const [inProgress, setInProgress] = useState(false);
  const Option = (props) => {
      return (
          <>
              <components.Option {...props}>
                  <input
                  type="checkbox"
                  checked={props.isSelected}
                  onChange={() => null}/>{" "}
              <label>{props.label}</label>
              </components.Option>
          </>
      );
  };

  const [inputs, setInputs] = useState({});
  const options = [{ value: 'completed', label: 'Completed' },
      { value: 'to_complete', label: 'To complete' },
      { value: 'in_progress', label: 'Inprogress' },
      { value: 'not_started', label: 'Not started' }
  ]
  const options_2 = [
    { value: 'true', label: 'Active' },
    { value: 'false', label: 'Inactive' }
]
  const navigate = useNavigate();

  const handleSubmit=(event)=> {  
    event.preventDefault();
    try {
          Swal.fire({title: 'Do you want to update?',  
          showCancelButton: true,  
          confirmButtonText: `Yes`,  
          cancelButtonText: `No`,
          }).then((result) => {  
            if (result.isConfirmed) { 
              console.log(JSON.stringify(selectedOption)+"selectedOption");
              updateSettings(cookies, {
                show_workschedules  :selectedOption.map(option=>option.value),
                workschedule_status:selectedOption_2.map(option=>option.value)
                },inputs.id)
                .then((response) =>
                {
                  Swal.fire('Updated!', '', 'success') 
                  // Cancel();
                })
                .catch((error) =>
                {
                console.log(error+"error");
                displayError(error.response.data,"Save Failed");
              });
            } 
            else if (result.isDismissed) 
            {    
              Swal.fire('Not updated', '', 'info')  
            }
          });
    }
    catch(e)
    {
      displayErrorToast(e);
    }
  }  

      // const Reload = () => {
      //   window.location.reload();
      // }      

  const handleChange = (selected) => {
      setSelectedOption(selected);
  };
  const handleChange_2 = (selected) => {
    setSelectedOption_2(selected);
};

  const handleSettings =()=>{
      setShowModal(true);
      getSettingsDetails(cookies)
      .then((response) => {
        setInputs(response.settings_list[0]);
        if (response.settings_list.length > 0) {
          const selectedValues = response.settings_list[0].show_workschedules.map(option => {
              const matchingOption = options.find(x => x.value === option);
              return matchingOption ? { value: option, label: matchingOption.label } : null;
          }).filter(Boolean);
          setSelectedOption(selectedValues);
          const selectedValues_2 = response.settings_list[0].workschedule_status.map(option => {
            const matchingOption = options_2.find(x => x.value === option);
            return matchingOption ? { value: option, label: matchingOption.label } : null;
        }).filter(Boolean);
        setSelectedOption_2(selectedValues_2);

      }
      })
      .catch((error) => {
        console.log(error+"error");
        displayError(error.response.data,"Update Failed");
    });
  };

  const Close = () => {
      setShowModal(false)
  }
  return (
    <>
      {(cookies['myToken'])&&
          <SettingsIcon onClickHandler={handleSettings} />
      }
      
      {showModal && <div className="modal-backdrop fade show"></div>}  {/* ✅ Fades Background */}
        <div className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }} tabIndex="-1">
          <div className="modal-dialog modal-xl"> {/* Centers the modal */}
            <div className="modal-content">
              <div className="modal-body display">
                <div className="container item-list-table-container">
                  <form className="form" onSubmit={handleSubmit}>
                    <div id="header">
                        <h4 className = "text font-weight-bold page-title" > Settings </h4>
                    </div>

                    <div className="container"> 
                      <Row> 
                        <Col xl={12} lg={12} md={12}> 
                          <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel">
                            <label htmlFor="show_workschedules" className="form-group col-sm-4 text-center ">Show Workschedules </label>
                              {/* <div style={{width: '70%'}} > */}
                              <ReactSelect
                                options={options}
                                isMulti= {true}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{Option}}
                                isClearable={true}
                                value={selectedOption}
                                onChange={handleChange}
                                className={"form-control col-sm-8  mandatory-form-control"}
                                placeholder="Please select"
                              />
                              {/* </div> */}
                            <label htmlFor="show_workschedule_by_status" className="form-group col-sm-4 text-center">Workschedule status </label>
                            <ReactSelect
                                options={options_2}
                                isMulti= {true}
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{Option}}
                                isClearable={true}
                                value={selectedOption_2}
                                onChange={handleChange_2}
                                className={"form-control col-sm-8  mandatory-form-control"}
                                placeholder="Please select"
                              />
                          </div>
                        </Col>
                      </Row> 
                    </div>
                    <div className="footer text-center"><br/>
                        <Button  type="submit"  style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(42,245,39,0.8)"}} className="btn btn-twitter">Update</Button> &nbsp;&nbsp;
                        <Button  type="button"style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter" onClick={Close}>Close</Button>
                    </div>
                  </form>
                </div> 
              </div>
            </div>
          </div>
        </div>
    </>
  );
}
export default Settings;
