import React from 'react';
import { useState, useRef } from "react";
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Button,
} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import '../../App.css';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import '../../button.css';
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import {displayErrorToast,displayError} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { getAllCompanies } from '../../services/CompanyServices';
import {  getAllApprovalSetting, createApprovalSetting } from '../../services/ApprovalSettingService';
import {parseBoolean}from '../../helpers'
import { getSalesOrderApprovalConditions } from './ApprovalSettingUtils';
function ApprovalSetting() {

  const VT_PURCHASEORDER = "purchase_order";
  const VT_SALESORDER = "sales_order";

  const [cookies] = useCookies(['myToken']);
  const [inputs, setInputs] = useState({
    voucher_type:'',
    company_id:'',
    is_appr_needed:'',
    is_so_wait_on_save:'',
    is_mail_needed:'',
    mail_ids:'',
  });
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
   const [companies, setCompanies] = useState([]);
   React.useEffect(() => {
    getAllCompanies(cookies)
    .then (
        companyList => {
            
            const companies = companyList.company_data.filter(obj => obj.status).map(
                company => {
                    return { value: company.id, label: company.entity_name }
                }
            );
            setCompanies(companies);
        }
    )
}, []);

  const [Options,setOptions]=useState([]);

  const Option = (props) => {
    return (
      <>
          <components.Option {...props}>
              <input
              type="checkbox"
              checked={props.isSelected}
              onChange={() => null}/>{""}
            <label>{props.label}</label>
          </components.Option>
      </>
    );
  };
  const selectInputRef = useRef();
  const [selectedOption, setSelectedOption] = useState(null);
  const handleSoWaiting = (selected) => {
    setSelectedOption(selected);
  };
  
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    try{
        if (name === 'voucher_type') {

          if (value === VT_SALESORDER) {

            document.getElementById("is_so_wait_on_save").disabled = false;
            document.getElementById("is_so_wait_on_save").required = true;
            setOptions(getSalesOrderApprovalConditions(false));
          } else if (value === VT_PURCHASEORDER) {

            document.getElementById("is_so_wait_on_save").disabled = true;
            document.getElementById("is_so_wait_on_save").required = false;
            setInputs(values => ({...values, ['is_so_wait_on_save']:'false'}));
            setOptions(getSalesOrderApprovalConditions(true));
            setSelectedOption(null);
            selectInputRef.current.setValue("");
          }
        } else if(name == 'is_appr_needed') {

         if(value === 'false')
          {
            document.getElementById("is_so_wait_on_save").disabled = true;
            document.getElementById("is_so_wait_on_save").required = false;
            setInputs(values => ({...values, ['is_so_wait_on_save']:'false'}))
            setOptions(getSalesOrderApprovalConditions(true))
            setSelectedOption(null)
            selectInputRef.current.setValue("")
          }
          else if((value === 'true') && (inputs.voucher_type === VT_SALESORDER))
          {
            document.getElementById("is_so_wait_on_save").disabled = false;
            document.getElementById("is_so_wait_on_save").required = true;
            setOptions(getSalesOrderApprovalConditions(false))
          } 
          else if((value === 'true') && (inputs.voucher_type
             === VT_PURCHASEORDER))
          {
            document.getElementById("is_so_wait_on_save").disabled = true;
            document.getElementById("is_so_wait_on_save").required = false;
            setInputs(values => ({...values, ['is_so_wait_on_save']:'false'}))
            setOptions(getSalesOrderApprovalConditions(true))
            setSelectedOption(null)
            selectInputRef.current.setValue("")
          }
        }
        else if (name == 'is_so_wait_on_save')
        {
            if(value == 'false')
            {
              setOptions(getSalesOrderApprovalConditions(false))
            }
            else
            {
              setOptions(getSalesOrderApprovalConditions(true))
              setSelectedOption(null)
              selectInputRef.current.setValue("")
            }
        }
        else if (name == 'is_mail_needed')
        {
          if(value == 'false')
          {
            document.getElementById("mail_ids").disabled = true;
            document.getElementById("mail_ids").required = false;
            setInputs(values => ({...values, ['mail_ids']:''})) 

          }
          else
          {
            document.getElementById("mail_ids").disabled = false
            document.getElementById("mail_ids").required = true;
          }
        }
        setInputs(values => ({...values, [name]: value}))
      }
      catch (e)
      {
       displayErrorToast(e);
      }
   }

  const SendSaveRequest =() => {
        createApprovalSetting(cookies, 
    {
      voucher_type:inputs.voucher_type,
      company_id:parseInt(inputs.company_id),
      is_appr_needed:parseBoolean(inputs.is_appr_needed),
      is_so_wait_on_save:parseBoolean(inputs.is_so_wait_on_save),
      is_mail_needed:parseBoolean(inputs.is_mail_needed),
      mail_ids:inputs.mail_ids.split(','),
      conditions_for_so_waiting :(selectedOption ? selectedOption.map(condition=>condition.value) : [])
    },
    
  )
    .then(resp => 
    {
      console.log(JSON.stringify(resp)+"response_ApprovalSetting");
      Swal.fire('Saved!', '', 'success') 
      Cancel();
    }).catch(function (error) 
    {
      console.log(error+"error_ApprovalSetting");
      displayError(error.response.data,"Save Failed");
     
    })
};

const validateApprovalSettings = async () => {

  return new Promise((resolve, reject) => {
    getAllApprovalSetting(cookies).then(settingsList => {
    
      let message = null;
      const settingsForVoucherType = 
        settingsList.approval_mail_settings_list.filter(setting => setting.voucher_type === inputs.voucher_type);
      if (settingsForVoucherType.length > 0) {
        if (parseInt(inputs.company_id) === -1
          && settingsForVoucherType.filter(setting => !setting.company.id).length > 0) {
            message = `Approval Setting for ${inputs.voucher_type} already exists for ALL company`;
        } else if (parseInt(inputs.company_id) === -1
          && settingsForVoucherType.filter(setting => setting.company.id).length > 0) {
            message = `Already created individually for ${inputs.voucher_type}`;
        } else if (settingsForVoucherType.filter(setting => setting.company.id === parseInt(inputs.company_id)).length > 0) {
          message = `This company is already registered for ${inputs.voucher_type}`;
        } else if (parseInt(inputs.company_id) !== -1
          && settingsForVoucherType.filter(setting => !setting.company.id).length > 0) {
            message = `Approval Setting for ${inputs.voucher_type} already exists for ALL company. Cannot create for individual`;
        }
        
      }
      
      resolve(message);
  
    })
  });
}

const handleSubmit=(event)=> {   ///If all the required fields are field u can select the save button then choose the option yes or no
    event.preventDefault();

    if ((inputs.voucher_type == 'sales_order') 
          && parseBoolean(String(inputs.is_appr_needed)) 
          && !parseBoolean(String(inputs.is_so_wait_on_save))
          && (!selectedOption || selectedOption.length == 0)) {
          Swal.fire('Please give conditions for SO WAITING...', '', 'info');
          
        } else {

          validateApprovalSettings().then(errorMessage => {

            if (errorMessage) {
              Swal.fire(errorMessage, '', 'info')
            } else {
              // First time create for voucher type ${inputs.voucher_type}. 
              Swal.fire({title: `Do you want to save?`,  
                showCancelButton: true,  
                confirmButtonText: `Yes`,  
                cancelButtonText: `No`,
              }).then((result) => {  
                if (result.isConfirmed) { 
                  SendSaveRequest();
                } 
                else if (result.isDismissed) {    
                  Swal.fire('Not saved', '', 'info')  
                }
              });
            }
          });
          
        }
 
  }  
 
  const Reload = () => {
    window.location.reload();
  }      
      
      const Back = () => {
        navigate('/Home')
      }

      const Cancel = () => {
        setInputs(() => "");
        setSelectedOption(null)
        selectInputRef.current.setValue("")
      }
  return (
    <>
    
    <div id="outer-container"  className="App" > 
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <ErrorToast/>
        <LoadingOverlay inProgress={inProgress}/>

        <div id="page-wrap">
            <form className="form" onSubmit={handleSubmit}>
                <div id="header">
                    <h3 className = "text font-weight-bold page-title">APPROVAL SETTING FORM</h3>
                </div>
                <FloatingControls tableLink="/ApprovalSettingTable" onCancel={Cancel} enableCancel={true}/>
          <div className="container-fluid">
          <Row>
                      <Col xl={4} lg={12} md={12}>
                      </Col>
              <Col xl={4} lg={12} md={12}>
                  <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue  form-control-panel ">
                  <h5 className='col-sm-11'><b>ApprovalSetting Details</b></h5><br/><br/>
                        <label htmlFor="voucher_type"className="form-group col-sm-5 text-right ">Voucher Type</label>
                        <select  id="voucher_type" required name="voucher_type" onChange={handleChange} value={inputs.voucher_type || ""}className="form-control  col-sm-7 browser-default custom-select">
                            <option value=""></option>
                            <option value="purchase_order">Purchase Order</option>
                            <option value="sales_order">Sales Order</option>
                        </select><br/>

                        <label htmlFor="company_id"className="form-group col-sm-5 text-right">Company</label>
                        <select  id="company_id" required name="company_id" onChange={handleChange} value={inputs.company_id || ""}className="form-control  col-sm-7 browser-default custom-select">
                            <option value=""></option>
                          
                            {companies.map((item) => (
                              <option key={item.value} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                        </select><br/>

                        
                        <label htmlFor="is_appr_needed"className="form-group col-sm-5 text-right ">Is Approval Needed?</label>
                        <select  id="is_appr_needed" required name="is_appr_needed" onChange={handleChange} value={inputs.is_appr_needed || ""}className="form-control  col-sm-7 browser-default custom-select">
                            <option value=""></option>
                            <option value="true">Yes</option> 
                            <option value="false">No</option>
                        </select><br/>

                        <label htmlFor="is_so_wait_on_save"className="form-group col-sm-5 text-right ">Is So Wait On Save?</label>
                        <select  id="is_so_wait_on_save" required name="is_so_wait_on_save" onChange={handleChange} value={inputs.is_so_wait_on_save || ""}className="form-control  col-sm-7 browser-default custom-select">
                            <option value=""></option>
                            <option value="true">Yes</option> 
                            <option value="false">No</option>
                        </select><br/>

                        
                        <label htmlFor="conditions_for_so_waiting"className="form-group col-sm-5 text-right">Conditions for SO Waiting </label>
                        <div id ="conditions_for_so_waiting"style={{width: '58%'}} >
                          <ReactSelect
                          options={Options}
                          isMulti= {true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{Option}}
                          isClearable={true}
                          isSearchable={false}
                          defaultValue={selectedOption}
                          ref={selectInputRef}
                          onChange={handleSoWaiting}
                          placeholder="Please select Condition"
                          className='mandatory-form-control'
                          isOptionDisabled={(option) => option.isdisabled}
                        />
                        </div>
                      <br/>

                        <label htmlFor="is_mail_needed"className="form-group col-sm-5 text-right ">Is Mail Need to be send to Email?</label>
                        <select  id="is_mail_needed" required name="is_mail_needed" onChange={handleChange} value={inputs.is_mail_needed || ""}className="form-control  col-sm-7 browser-default custom-select">
                        <option value=""></option>
                        <option value="true">Yes</option> 
                        <option value="false">No</option>
                        </select><br/>
                        
                        <label htmlFor="mail_ids"className="form-group col-sm-5 text-right  ">Email id(comma separated)</label>
                        <input type="mail_ids" id="mail_ids"required name="mail_ids"onChange={handleChange} value={inputs.mail_ids|| ""} className="form-control col-sm-7" multiple/><br/>
                      </div>
                  </Col>
          </Row> 
          
          </div>
              <div className="footer text-center"><br/>
                  <Button  type="submit"  style={{width:"80px",fontWeight:"bold"}} className="btn btn-twitter">Save</Button> &nbsp;&nbsp;
                  <Button type="button" className="btn btn-twitter"style={{width:"80px",fontWeight:"bold"}} onClick={Cancel} >Reset</Button> &nbsp;&nbsp;
                  <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={() =>{navigate('/ApprovalSettingTable')}} >View</Button>&nbsp;&nbsp;
                  <Button className="btn btn-twitter" type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
              </div>
              </form> 
          </div><br/>
          </div> 
          
      </>
  );
}
export default ApprovalSetting;
