import { getApiBaseUrl,getApiClient } from "./serviceconfig";
var apiBaseUrl = getApiBaseUrl();

export async function updateSettings(cookies, inputs, settingsId) {
    return getApiClient(cookies)
    .put(`${apiBaseUrl}/settings/${settingsId}/`, inputs)
}

export async function getSettingsDetails(cookies) {
  return getApiClient(cookies).get(`${apiBaseUrl}/settings/`)
  .then(response => response.data)
}
