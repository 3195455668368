import React from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter as Router, Redirect, Route,Link,NavLink, Routes } from 'react-router-dom';
import { useState,useEffect } from "react";
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Button,
} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import '../../App.css';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import {displayErrorToast,displayError,parseBoolean} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { checkForDuplicateGang } from './GangUtils';
import { createGang } from "../../services/GangServices";
import { getAllVendors } from '../../services/VendorServices';
import StatusDropDown from '../../components/StatusDropDown';

function Gang() 
{
  const [cookies] = useCookies(['myToken']);
  const [inputs, setInputs] = useState({});
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(true);
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  const [vendors, setVendors] = React.useState([]);

  React.useEffect(() => {
    getAllVendors(cookies)
    .then (
        vendorList => {
            
            const vendors = vendorList.vendor_list.filter(obj => obj.status).map(
              vendor => {
                    return { value: vendor.id, label: vendor.entity_name }
                }
            );
            setVendors(vendors);
        }
    )
}, []);




  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(value+"value")
    console.log(name+"name")
    setInputs(values => ({...values, [name]: value}))
  try{  
        if(name === 'entity_name')     //Gang Name must not be empty,otherwise showing error message  
        {
   
         if(!value.trim())
          {
            setError(values => ({...values, [name]: "Gang Name must not be empty..."}))
            setIsSubmitting(false); 
          }
         
        }
        else if(name === 'gangContactEmailid')
        // pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
        {
          if(value.trim())
          {
            if(!/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/.test(value.trim()))
            {
              setError(values => ({...values, [name]: "Invalid Email Id..."}))
              setIsSubmitting(false); 
            }
            else
            {
              setError(values => ({...values, [name]: ''}))
              setIsSubmitting(true);
            }
          }
          else
          {
            setError(values => ({...values, [name]: ''}))
            setIsSubmitting(true);
          }
        }
        console.log(value+"value")
        console.log(name+"name")
        setInputs(values => ({...values, [name]: value}))
    }
    catch(e)
    {
      displayErrorToast(e);
    }
  }
      


  const handleSubmit=(event)=> {
    event.preventDefault();
  

    if ((Object.values(error).every(x => !x)) && isSubmitting) { 
      console.log(JSON.stringify(inputs)+"inputsubmit")  //If the fields are correct details it is valid choosing yes or no option,otherwise it is invalid
            Swal.fire({title: 'Do you want to save?',  
            showCancelButton: true,  
            confirmButtonText: `Yes`,  
            cancelButtonText: `No`,
            }).then((result) => {  
              if (result.isConfirmed) { 
                  console.log(JSON.stringify(inputs)+"inputsubmit")
                  createGang(cookies, {
          
                    entity_name: (inputs.entity_name).toUpperCase(),
                    entity_vendor_id:inputs.entity_vendor_id,
                    workers_qty: inputs.workers_qty,
                    fitters_qty:inputs.fitters_qty,
                    supervisor_qty:inputs.supervisor_qty,
                    contact_person:inputs.contact_person,
                    contact_designation:inputs.contact_designation,
                    contact_mobile_no: inputs.contact_mobile_no,
                    contact_email_id: inputs.contact_email_id,
                    valid_from_date:inputs.valid_from_date,
                    valid_upto_date:inputs.valid_upto_date,
                    user_remarks:inputs.user_remarks,
                    status:parseBoolean(inputs.status)
                 })
                 //console.log(createAccountMaster)
                .then(response => {
                    Swal.fire("Saved!", "", "success");
                    view();
                }).catch((error) => {
                    console.log(error.response.data);
                    displayError(error.response.data,"Saved Failed");
                });
                
              } 
              else if (result.isDismissed) {
                Swal.fire("Not Saved", "", "info");
              }
            });
      }

} 
      const Reload = () => {
        window.location.reload();
      }      
      
      const view = () => {
        navigate('/GangTable')
      }        
      
      const Back = () => {
        navigate('/Home')
      }

      const Cancel = () => {
        setInputs(() => "")
      }
  return (
    <>
    <div id="outer-container"  className="App" > 
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <ErrorToast/>
        <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
            <form className="form" onSubmit={handleSubmit}>
                <div id="header"> 
                <h4 className = "text font-weight-bold page-title">Gang Registration & Setup</h4>
                </div>
                <FloatingControls tableLink="/GangTable" onCancel={Cancel} enableCancel={true}/>
                <div className="container"  > 
                    <Row> 
                        <Col xl={6} lg={12} md={12}>
                            <div className="form-row table-bordered shadow p-2 my-2 border p-2 mb-3 bg-blue form-control-panel">
                            <h5 className='col-sm-11'><b>Gang Details</b></h5><br/><br/>
                                <label htmlFor="entity_name" className="form-group col-sm-4 text-right">Name </label>
                                <input type="text" id="entity_name" name="entity_name" className="form-control col-sm-7 mandatory-form-control" onChange={handleChange} required value={inputs.entity_name || ""} placeholder="Please fill the NAME field *"  /><br/><br/>
                                 {error.entity_name && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.entity_name}</strong><br/></>)} 

                                 
                                {/* <label htmlFor="entity_name" className="form-group col-sm-4 text-right">Name</label>
                                <input type="text" name="entity_name" onChange={handleChange} value={inputs.entity_name || ""} className="form-control col-sm-7"/><br/><br/> */}
                                
                                <label htmlFor="entity_vendor_id" className="form-group col-sm-4 text-right"> Vendor Name </label>
                                    <select  id="entity_vendor_id" name='entity_vendor_id'onChange={handleChange} required value={inputs.entity_vendor_id || ""}className="browser-default custom-select form-control col-sm-7 mandatory-form-control" >
                                    <option value="">Select Type</option>
                                    {vendors.map((item) => (
                                          <option key={item.value} value={item.value}>
                                            {item.label}
                                          </option>
                                        ))}
                                    </select><br/><br/>

                                <label htmlFor="contact_person" className="form-group col-sm-4 text-right">Contact Person</label>
                                <input type="text" name="contact_person" onChange={handleChange} value={inputs.contact_person || ""} className="form-control col-sm-7"/><br/><br/>
                                
                                <label htmlFor="contact_designation" className="form-group col-sm-4 text-right">Designation</label>
                                <input type="text" name="contact_designation"onChange={handleChange} value={inputs.contact_designation || ""}className="form-control col-sm-7" /><br/><br/>
                                            
                                <label htmlFor="contact_mobile_no" className="form-group col-sm-4 text-right">Mobile No.</label>
                                <input type="text" name="contact_mobile_no"onChange={handleChange} value={inputs.contact_mobile_no || ""} className="form-control col-sm-7" /><br/><br/>
                                
                                <label htmlFor="contact_email_id" className="form-group col-sm-4 text-right">Email Id</label>
                                <input type="email" name="contact_email_id"onChange={handleChange} value={inputs.contact_email_id || ""} placeholder="Enter Email id" className="form-control col-sm-7"  /><br/>
                                {error.contact_email_id && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.contact_email_id}</strong><br/></>)}
                                <br/>
                            </div>
                        </Col>
                        <Col xl={6} lg={12} md={12}>
                            <div className="form-row table-bordered shadow p-2 my-2 border p-2 mb-3 bg-blue form-control-panel">
                            <h5 className='col-sm-11'><b>Workers Detailes</b></h5><br/><br/>
                                <label htmlFor="workers_qty" className="form-group col-sm-4 text-right">Workers Qty </label>
                                <input type="number" min="0" name="workers_qty" onChange={handleChange} value={inputs.workers_qty || 0 } className="form-control col-sm-7 mandatory-form-control" required /><br/><br/>
                                
                                <label htmlFor="fitters_qty" className="form-group col-sm-4 text-right">Fitters Qty </label>
                                <input type="number" min="0"  onChange={handleChange} value={inputs.fitters_qty || 0 } name="fitters_qty" className="form-control col-sm-7 mandatory-form-control"  required /><br/><br/>
                                
                                <label htmlFor="supervisor_qty." className="form-group col-sm-4 text-right">Supervisior Qty </label>
                                <input type="number" min="0"  onChange={handleChange} value={inputs.supervisor_qty || 0 } name="supervisor_qty" className="form-control col-sm-7 mandatory-form-control" required /><br/><br/>
                                <label htmlFor="valid_from_date" className="form-group col-sm-4 text-right">Contract Valid From </label>
                                  <input type="date" name="valid_from_date" className="form-control col-sm-3 mandatory-form-control"onChange={handleChange} value={inputs.valid_from_date || ""} required/>
                                  
                                  <label htmlFor="valid_upto_date" className="form-group col-sm-1 text-right">To </label>
                                  <input type="date" name="valid_upto_date" className="form-control col-sm-3 mandatory-form-control" onChange={handleChange} value={inputs.valid_upto_date || ""}required/><br/><br/>

                                  <label htmlFor="user_remarks" className="form-group col-sm-4 text-right">Remarks</label>
                                  <input type="text" id="user_remarks" name="user_remarks" onChange={handleChange} maxLength="1000"value={inputs.user_remarks || ""} className="form-control col-sm-7" /><br/><br/>
                                  
                                  <StatusDropDown status={inputs.status} onChange={handleChange}/>
   
                            </div>
                        </Col>
                    </Row> 
                </div>
                
                <div className="footer text-center"><br/>
                    <Button  type="submit"  style={{width:"80px",fontWeight:"bold"}} className="btn btn-twitter">Save</Button> &nbsp;&nbsp;
                    <Button type="button"style={{width:"80px",fontWeight:"bold"}} className="btn btn-twitter" onClick={Cancel}>Cancel</Button> &nbsp;&nbsp;
                    <Button  type="button"style={{width:"80px",fontWeight:"bold"}}className="btn btn-twitter" onClick={view}>View</Button>&nbsp;&nbsp;
                    <Button   type="button"style={{width:"80px",fontWeight:"bold"}} className="btn btn-twitter"onClick={Back}>Home</Button>
                </div>
                {/* </div> */}
            </form><br/>
        </div><br/>
    </div> 
    </>
  );
}
export default Gang;

