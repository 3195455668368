import ConstructionIcon from '@mui/icons-material/Construction';
function SettingsIcon({onClickHandler}) {

    return(
        <>
            <ConstructionIcon onClick={onClickHandler}
            sx={{cursor: 'pointer',
                color: 'white',
                fontSize: '40px',
                backgroundColor: 'primary.dark',
                position:"fixed",
                top: "10px",
                right: "10px",
                margin: "8px",
                padding: "5px",
                borderRadius: "50%",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
            }}
            />
        </>
    );
}

export default SettingsIcon;