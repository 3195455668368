import React from 'react';
import { useState,useEffect} from "react";
import Swal from "sweetalert2";
import {Row,Col,Button} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import { default as ReactSelect } from "react-select";
import { Link,useParams } from 'react-router-dom';
import '../../button.css';
import {displayErrorToast,displayError} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { checkForDuplicateProduct } from './ProductUtils';
import { updateProducts,getProductDetails } from "../../services/ProductServices";
import { getAllGroups } from "../../services/GroupFormServices";
import { getAllTax } from "../../services/TaxServices";
import StatusDropDown from '../../components/StatusDropDown';
import { getAllUnits } from "../../services/UnitServices";

function ProductEdit() {
    const [cookies] = useCookies(['myToken']);
    const [inputs, setInputs] = useState({
     
        category_id :'',
        name : '',
        unit_id : '',
        quantity : 1,
        vendor_part_code : '',
        is_gst_applicable :"",
        tax_id : '',
        hsn_sac_code : '',
        hsn_sac_description :'',
        prod_gst_type :"Goods",
        user_remarks :'',
        is_batch_report_connected :"false",
        status :""
   
    });
    const [error, setError] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(true);
    const navigate = useNavigate();
    const [groups, setGroups] = React.useState([]);
    const [selectedUnit, setSelectedUnit] = useState(null);
   
    const [units, setUnits] = React.useState([]);
    const [group_id, setGroupId] = React.useState([]);

    const [taxes, setTaxes] = React.useState([]);
    const [inProgress, setInProgress] = useState(false);

    const onUnitSelect = (selected) => {
        setSelectedUnit(selected);
        setInputs(values => ({...values,['unit_id']:selected.value}))
    };

    React.useEffect(() => {
        getAllUnits(cookies)
        .then (
            unitList => {
                
                const units = unitList.unit_list.map(
                    unit => {
                        return { value: unit.id, label: unit.name }
                    }
                );
                setUnits(units);
            }
        )
    }, []);

    useEffect( () => {
        getAllGroups(cookies)
        .then(response => {
     const group =response.accounts_grouping_list.filter(obj => obj.entity_name == 'PRODUCT' )
            const a =[]
                            for(var i=0; i<response.accounts_grouping_list.length; i++) 
                            {
                                if((response.accounts_grouping_list[i].parent_id)=== (group[0].id))
                                {
                                    a.push(response.accounts_grouping_list[i]);
                                }
                            }
                          
                            setGroups(a);
         
    
        });
     
      }, []);
      useEffect( () => {
        getAllTax(cookies)
        .then(response => {
      
            const tax_value=[]
                            for(var j=0; j<response.tax_list.length; j++) 
                            {
                                if(response.tax_list[j].status)
                                {
                                    tax_value.push(response.tax_list[j]);
                                  
                                }
                            }
                            setTaxes(tax_value);
                            console.log(JSON.stringify(tax_value)+"BBB")
         
    
        });
     
      }, []);
    

      useEffect( () => {
        getAllUnits(cookies)
        .then(response => {
     
            const unit_value=[]
                            for(var j=0; j<response.unit_list.length; j++) 
                            {
                                if(response.unit_list[j].status)
                                {
                                    unit_value.push(response.unit_list[j]);
                                  
                                }
                            }
                            setUnits(unit_value);
                            console.log(JSON.stringify(unit_value)+"BBB")
         
    
        });
     
      }, []);

      const handleChangeTaxApplicable = (event) =>{
        const name = event.target.name;
        const value = event.target.value;
        if(value === 'false'){
            const selected_prod = taxes.filter(obj => obj.name == 'NO TAX')
            console.log(JSON.stringify(selected_prod)+"selected prod")
                              
            setInputs(values => ({...values, ['tax_id']: selected_prod[0].id}))
       
           document.getElementById('tax_id').disabled = true;
           document.getElementById('tax_id').required = false;
           document.getElementById('tax_id').className = document.getElementById('tax_id').className.replace('mandatory-form-control',''); 
      
         
        }
        else
        {
            document.getElementById('tax_id').disabled = false;
            document.getElementById('tax_id').required = true;
            document.getElementById('tax_id').className += ' mandatory-form-control'; 
      
        }
        setInputs(values => ({...values, [name]: event.target.value}))
      }
    
    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        if(name === 'name')   //Product must not be empty if it is already exist u can create new product name
        {
            if(!value.trim())
            {
                // setError(values => ({...values, [name]: "Product Name must not be empty..."}))
                setIsSubmitting(false); 
            }
            else{
                      
                setError(values => ({...values, [name]: ""}))
                setIsSubmitting(true); 
              }
         
        }
        else if(name === 'quantity')
        {
            if(value.trim())
            {
                if(!/^[0-9]*$/.test(value.trim()))
                {
                setError(values => ({...values, [name]: "Positive Numbers only allowed..."}))
                setIsSubmitting(false);
             
                }
                else
                {
                setError(values => ({...values, [name]: ''}))
                setIsSubmitting(true);
            
                }
            }
            else
            {
                setError(values => ({...values, [name]: ''}))
                setIsSubmitting(true);
            }
        }

        setInputs(values => ({...values, [name]: event.target.value}))
    }

    const handleSubmit=(event)=> {
        event.preventDefault();
        try{
          checkForDuplicateProduct(event.target.name.name, event.target.name.value, cookies, id)
        .then(isNameExists => {
          if (isNameExists) {
            setError(values => ({...values, [event.target.name.name]: 'Product name already exists.'}))
            
          } else {
        
      
            if (Object.values(error).every((x) => !x) && isSubmitting) { 
        //If the fields are correct details it is valid choosing yes or no option,otherwise it is invalid
                Swal.fire({title: 'Do you want to update?',  
                showCancelButton: true,  
                confirmButtonText: `Yes`,  
                cancelButtonText: `No`,
                }).then((result) => {  
                  if (result.isConfirmed) { 
                      console.log(JSON.stringify(inputs)+"inputsubmit")
                      updateProducts(cookies, {
                        id:inputs.id,
                        name:inputs.name,
                        category_id:inputs.category_id,
                        unit_id:inputs.unit_id,
                        quantity:inputs.quantity,
                        vendor_part_code:inputs.vendor_part_code,
                        is_gst_applicable:inputs.is_gst_applicable,
                        tax_id: inputs.tax_id,
                        hsn_sac_code:inputs.hsn_sac_code,
                        hsn_sac_description:inputs.hsn_sac_description,
                        prod_gst_type: inputs.prod_gst_type,
                        user_remarks: inputs.user_remarks,
                        is_batch_report_connected:inputs.is_batch_report_connected,
                        status:inputs.status
                     }, id)
                    .then(response => {
                        Swal.fire("Updated!", "", "success");
                        view();
                    }).catch((error) => {
                        //console.log(error.response.data);
                        displayError(error.response.data,"Updated Failed");
                    });
                    
                  } 
                  else if (result.isDismissed) {
                    Swal.fire("Not Updated", "", "info");
                  }
                });
          
          }
    
    }
    });
    } catch (e) {
      displayErrorToast(e);
    } 
     } 

        const Reload = () => {
          window.location.reload();
        }      
        const view = () => {
          navigate('/ProductTable')
        }  
        const Back = () => {
          navigate('/Home')
        }
        const Cancel = () => {
          
            setInputs({
                category_id :'',
                name : '',
                unit_id : '',
                quantity : 1,
                vendor_part_code : '',
                is_gst_applicable :"",
                tax_id : '',
                hsn_sac_code : '',
                hsn_sac_description :'',
                prod_gst_type :"Goods",
                user_remarks :'',
                is_batch_report_connected :"No",
                status :""
           
            });
        }
        
        const {id}=useParams()
        React.useEffect(() => {

          if (id)
         {
        setInProgress(true);
        getProductDetails(cookies, id)
          
          .then(response => {
              
              console.log(JSON.stringify(response))
              setInProgress(false);
         
          
              setInputs(values => ({...values,['id']:response.id,
              ['category_id']: response.category.id, 
              ['name']: response.name,
              ['unit_id']:response.unit.id,
              ['quantity']:response.quantity,
              ['vendor_part_code']: response.vendor_part_code, 
              ['is_gst_applicable']: response.is_gst_applicable,
              ['tax_id']:response.tax.id,
              ['hsn_sac_code']:response.hsn_sac_code,
              ['hsn_sac_description']: response.hsn_sac_description, 
              ['prod_gst_type']: response.prod_gst_type,
              ['user_remarks']:response.user_remarks,
              ['is_batch_report_connected']:response.is_batch_report_connected,
             ['status']:String(response.status)}));
             setSelectedUnit ({value:response.unit.id,label:response.unit.name})
             if (response.is_gst_applicable) {
         
                document.getElementById('tax_id').disabled = false;
            } else {
                document.getElementById('tax_id').disabled=true;
              
            }
             getAllUnits(cookies)
             .then (
                 unitList => {
                     
                     const units = unitList.unit_list.map(
                         unit => {
                             return { value: unit.id, label: unit.name }
                         }
                     );
                    // Sort the units alphabetically based on the 'label' property
                    units.sort((a, b) => a.label.localeCompare(b.label));

                     setUnits(units);
                 }
             )
          })
    
          .catch(error => {
              setInProgress(false);
              displayError(error.response.data, "Loading Failed");
            });
      }
        }, []);
    return (
    <>
     <div id="outer-container"  className="App" > 
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <ErrorToast/>
        <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
        <form onSubmit={handleSubmit}> 
          <div id="header">
              <h4 className = "text font-weight-bold page-title" >Update Product Registration & Setup </h4>
          </div>
         
            <FloatingControls tableLink="/ProductTable" enableCancel={false}/>
            <div className="container"> 
                <Row> 
                <Col xl={6} lg={12} md={12}>   
                <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel" >
                <h5 className='col-sm-11'><b>Product Details</b></h5><br/><br/>
                            <label htmlFor="category_id" className="form-group col-sm-4 text-right">Group Name </label>
                            <select  id="category_id" required name="category_id" className="browser-default custom-select form-control col-sm-7 mandatory-form-control" onChange={handleChange} value={inputs.category_id || ""}>
                                {groups.map((item) => (
                                    <option key={item.id} value={item.id}>
                                    {item.entity_name}
                                    </option>
                                ))}
                            </select><br/><br/>
                   
                            <label htmlFor="name" className="form-group col-sm-4 text-right">Product Name </label>
                            <input type="text" id="name"  name="name" placeholder="Name Of The Product" className="form-control  col-sm-7 mandatory-form-control"   required onChange={handleChange} value={inputs.name || ""}/><br/><br/>
                            {error.name && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.name}</strong><br/></>)}
                      
                            <label htmlFor="unit_id"className="form-group col-sm-4 text-right">Unit</label>
                                <div style={{width: '58%'}} >
                                  <ReactSelect
                                    options={units}
                                    isMulti= {false}
                                    isSearchable={true}
                                    value={selectedUnit}
                                    onChange={onUnitSelect}
                                    className={"mandatory-form-control react-select-dropdown"}
                                    placeholder="Please Select Unit"
                                  />
                                  </div>
                                <br/><br/>
                    
                            <label htmlFor="vendor_part_code" className="form-group col-sm-4 text-right">Vendor Part Code</label>
                            <input type="text"id="vendor_part_code" name="vendor_part_code" onChange={handleChange} value={inputs.vendor_part_code || ""}placeholder="Enter Code Number"    className="form-control  col-sm-7"/><br/><br/>
                      
                            <label htmlFor="quantity" className="form-group col-sm-4 text-right">Qty </label>
                            <input type="number" min="0" name="quantity" id="quantity" onChange={handleChange} value={inputs.quantity || ""}placeholder="Input Numbers"  required  className="form-control  col-sm-7 mandatory-form-control" /><br/><br/>
                            {error.quantity && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.quantity}</strong><br/></>)}
                      
                            <label htmlFor="is_batch_report_connected" className="form-group col-sm-4 text-right"> Is Connect Batching Report?</label>
                            <select id="is_batch_report_connected" name="is_batch_report_connected" onChange={handleChange} value={inputs.is_batch_report_connected || ""}required className="form-control col-sm-7 browser-default custom-select mandatory-form-control">
                                <option value="false">No</option>
                                <option value="true">Yes</option>
                            </select><br/><br/>
                            </div>
                            <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3" style={{backgroundColor:"RGB(188,232,253)",borderRadius:"10px",fontWeight:"bold"}}>
                            <h5 className='col-sm-11'><b>Status Details</b></h5><br/><br/>
                            <label htmlFor="user_remarks" className="form-group col-sm-4 text-right">Remarks</label>
                            <input type="text" id="user_remarks" name="user_remarks"onChange={handleChange} value={inputs.user_remarks || ""} maxLength="1000" placeholder="IF ANY"  className="form-control  col-sm-7" /><br/><br/>
                            
                            <StatusDropDown status={inputs.status} onChange={handleChange}/>
                        </div>
                </Col>
                    <Col xl={6} lg={12} md={12}>   
                   
                    <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel" >
                    <h5 className='col-sm-11'><b>GST Details</b></h5><br/><br/>
                            <label htmlFor="is_gst_applicable" className="form-group col-sm-4 text-right">Is GST Applicable? </label>
                            <select id="is_gst_applicable" onChange={handleChangeTaxApplicable} value={inputs.is_gst_applicable || "false"}name="is_gst_applicable" required className="form-control col-sm-7 browser-default custom-select mandatory-form-control">
                                <option value="true" >Yes</option>
                                <option value="false" >No</option>
                            </select><br/><br/>

                    
                            <label htmlFor="tax_id" className="form-group col-sm-4 text-right">Tax Code </label>
                            <select  id="tax_id" onChange={handleChange} value={inputs.tax_id || ""} required name='tax_id' className="form-control col-sm-7 browser-default custom-select"  disabled={true}>
                                {taxes.map((item) => (
                                    <option key={item.id} value={item.id}>
                                    {item.name}
                                    </option>
                                ))}
                            </select><br/><br/>
                           
                            
                            <label htmlFor="prod_gst_type" className="form-group col-sm-4 text-right">GST Type</label>
                            <select name="prod_gst_type" id="prod_gst_type" onChange={handleChange} value={inputs.prod_gst_type || ""}className="form-control col-sm-7 browser-default custom-select">
                                <option value="Goods" >Goods</option>
                                <option value="Services">Services</option>
                            </select><br/>
                            <label htmlFor="hsn_sac_code" className="form-group col-sm-4 text-right">HSN / SAC Code</label>
                            <input type="text" id="hsn_sac_code" name="hsn_sac_code" onChange={handleChange} value={inputs.hsn_sac_code || ""}className="form-control  col-sm-7" placeholder="Enter Number" /><br/><br/>
                      
                            <label htmlFor="hsn_sac_description" className="form-group col-sm-4 text-right">HSN/SAC Description</label>
                            <input type="text" id="hsn_sac_description"name="hsn_sac_description" onChange={handleChange} value={inputs.hsn_sac_description || ""}className="form-control  col-sm-7" placeholder="Enter Some Text.." /><br/>
                        </div>
                        
                    
                    </Col>
                </Row>             
            </div>
                              <div className="footer text-center">
                              <Button  type="submit"  style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter">Update</Button> &nbsp;&nbsp;

                    <Button   type="button"style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter"onClick={view}>View</Button>&nbsp;&nbsp;
                    <Button  type="button"style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter"onClick={Back}>Home</Button>
                              </div>
        </form>
        </div>
    </div>
         </>
    );
  }
  export default ProductEdit;
  