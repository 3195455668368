import React, { useState } from "react";
import "./Home.css";
import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useCookies } from 'react-cookie';
import { getUserAllowedPlants} from './services/PlantServices';
import { getDashboardDetails} from './services/DashboardServices';
import { getBgImageLogo } from './services/LoginServices';
import {displayErrorToast,parseBoolean,displayError} from './helpers';
import {  DatePicker } from 'antd';
import moment  from 'moment';
import Sidebar from './Sidebar';
import {Button} from '@mui/material';
import {Row,Col} from "reactstrap";
const {RangePicker} = DatePicker;

Chart.register(ChartDataLabels);

const Cards = ({ day, yesterday, week, month, year }) => (
  <div className="cards-container">
    <div className="card" id="dayCard">
      <span className="card-title">Today</span>
      <p>{day.toFixed(2)}</p>
    </div>
    <div className="card" id="yesterdayCard">
      <span className="card-title">Yesterday</span>
      <p>{yesterday.toFixed(2)}</p>
    </div>
    <div className="card" id="weekCard">
      <span className="card-title">Week</span>
      <p>{week.toFixed(2)}</p>
    </div>
    <div className="card" id="monthCard">
      <span className="card-title">Month</span>
      <p>{month.toFixed(2)}</p>
    </div>
    <div className="card" id="yearCard">
      <span className="card-title">Year</span>
      <p>{year.toFixed(2)}</p>
    </div>
  </div>
);

const Charts = () => (
  <div className="charts-container">
    <div className="chart-box">
      <canvas id="salesChart"></canvas>
    </div>
    <div className="chart-box">
      <canvas id="marketingChart"></canvas>
    </div>
    <div className="chart-box">
      <canvas id="pieChart"></canvas>
    </div>
    <div className="chart-box">
      <canvas id="lineChart"></canvas>
    </div>
  </div>
);

const statusColors = {
    'NOT STARTED': 'warning',     // Yellow
    'IN PROGRESS': 'primary',     // Blue
    'TO COMPLETE': 'success',     // Green
    'COMPLETED': 'secondary'          
};

const DataTable = ({ data }) => (
  <div className="table-container">
    <h3>Schedules</h3>
    {data.map((obj, index) => (
        <div key={index}>
          <h6>DELIVERY DATE: {obj.delivery_date} 
            {/* <span></span>  */}
            </h6>
          {obj.entries.length > 0 ? (
            <table id="dataTable">
              <thead>
                <tr>
                  <th  style={{width:"50px"}}>Job No.</th>
                  <th>Time</th>
                  <th>Customer Name</th>
                  <th>Site</th>
                  <th  style={{width:"50px"}}>Grade</th>
                  <th style={{width:"100px"}}>Schedule Qty</th>
                  <th style={{width:"100px"}}>Achieved Qty</th>
                  <th  style={{width:"100px"}}>Delivery Mode</th>
                  <th  style={{width:"100px"}}>Concrete Structure</th>
                  <th>CSM</th>
                  <th style={{width:"60px"}}>Status</th>
                </tr>
              </thead>
              <tbody>
                {obj.entries.map((row, idx) => (
                  <tr key={idx}>
                    <td>{row.schedule_no}</td>
                    <td>{row.delivery_time}</td>
                    <td>{row.customer_name}</td>
                    <td>{row.project_name}</td>
                    <td>{row.grade}</td>
                    <td style={{textAlign:"right"}}>{row.scheduled_qty.toFixed(2)}</td>
                    <td style={{textAlign:"right"}}>{row.supplied_qty.toFixed(2)}</td>
                    <td>{row.delivery_mode.toUpperCase()}</td>
                    <td>{row.concrete_structure}</td>
                    <td>{row.salesrep_alias}</td>
                    <td
                      style={{ verticalAlign: "middle",marginTop:"15px" }}
                      className={`badge badge-${statusColors[row.status] || "secondary"}`}
                    >
                      {row.status}
                    </td>
                  </tr>
                ))}
                <tr>
                    <td colspan="5"> </td>
                    <td style={{textAlign:"right",fontWeight:"bold",color:"blue",fontSize:"18px"}}>{obj.scheduled_qty.toFixed(2)}</td>
                    <td style={{textAlign:"right",fontWeight:"bold",color:"blue",fontSize:"18px"}}> {obj.supplied_qty.toFixed(2)}</td>
                    <td  colspan="4"></td>
                </tr>
              </tbody>
            </table>
          ) : (
            <p>No data available</p>
          )}
        </div>
      ))}
  </div>
);

const InventoryDashboard = () => (
  <div id="inventoryDashboard">
    <h2>Inventory Dashboard</h2>
    <p>This is the inventory management section.</p>
  </div>
);

const Home = () => {
    const [salesData, setSalesData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    // eslint-disable-next-line
    const [uniquePlants, setUniquePlants] = useState(new Set());
    // eslint-disable-next-line
    const [uniqueMonths, setUniqueMonths] = useState(new Set());
    const [charts, setCharts] = useState({});
    const [day, setDay] = useState(0);
    const [yesterday, setYesterday] = useState(0);
    const [week, setWeek] = useState(0);
    const [month, setMonth] = useState(0);
    const [year, setYear] = useState(0);
    const [showInventory, setShowInventory] = useState(false); // State to toggle dashboard
    const [inputs, setInputs] = useState({});
    const [companyName,setCompanyName] = useState("Litvik Software Labs Pvt. Ltd.");
    const [plants, setPlants] = React.useState([]);
    const [cookies] = useCookies(['myToken']);
    // Initial state for from_date and to_date
    const initialFromDate = moment().startOf('month');
    const initialToDate =  moment();
    const [fromDate, setFromDate] = useState(initialFromDate);
    const [toDate, setToDate] = useState(initialToDate);
    const [data,setData]=useState([]);
    const handleDateChange = (values) => {
        if ((values) && (values.length === 2)) {
        setFromDate(values[0]);
        setToDate(values[1]);
        console.log(inputs.plant_id,values[0].format('DD-MM-YYYY'),values[1].format('DD-MM-YYYY')+"plant_id,values[0],values[1]")
        handleDashboard(inputs.plant_id,values[0].format('DD-MM-YYYY'),values[1].format('DD-MM-YYYY'))
        }
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        try{
            setInputs(values => ({...values, [name]: value}));
            console.log(value,fromDate.format('DD-MM-YYYY'),toDate.format('DD-MM-YYYY')+"value,fromDate,toDate")
            handleDashboard(value,fromDate.format('DD-MM-YYYY'),toDate.format('DD-MM-YYYY'))
        }
        catch (e) {
            displayErrorToast(e);
        }
    }

    const handleDashboard = (plant_id,fromDate,toDate) => {
        console.log(plant_id,fromDate,toDate+"plant_id,fromDate,toDate handleDashboard")
        getDashboardDetails(cookies,plant_id,fromDate,toDate)
        .then(response => {
            setData(response)
            setFilteredData(response.work_schedule_list);
            setDay(response.today_sum); 
            setYesterday(response.yesterday_sum);
            setWeek(response.week_sum);
            setMonth(response.month_sum);
            setYear(response.year_sum);
            // setIsLoading(false);
        })
        .catch((error) =>
        {
            // setIsLoading(false);
            console.log(error+"error");
            // displayError(error.response.data," Failed");
        })
    }
    
	React.useEffect(() => {
        getBgImageLogo(cookies)
        .then (
            response => {
                const companies = response.company_list.filter(obj => obj.status)
				console.log(JSON.stringify(companies)+"companies");
				const company = companies[0];
				if (company){
					console.log(JSON.stringify(company)+"company");
					setCompanyName(company.name);
				}
            }
        )
    }, []);
    
    React.useEffect(() => {
        const fetchData = () => {
            getUserAllowedPlants(cookies)
            .then(plantList => {
                const plants = plantList.plant_list.map(plant => ({
                    value: plant.id,
                    label: plant.plant_alias
                }));
    
                setPlants(plants);
    
                if (plants.length > 0) {
                    setInputs(values => ({ ...values, ['plant_id']: plants[0].value }));
    
                    getDashboardDetails(
                        cookies,
                        plants[0].value,
                        fromDate.format('DD-MM-YYYY'),
                        toDate.format('DD-MM-YYYY')
                    )
                    .then(response => {
                        setData(response);
                        setFilteredData(response.work_schedule_list);
                        // updateCards(response.work_schedule_list); 
                        setDay(response.today_sum); 
                        setYesterday(response.yesterday_sum);
                        setWeek(response.week_sum);
                        setMonth(response.month_sum);
                        setYear(response.year_sum);
                    })
                    .catch((error) => {
                        console.log(error + "error");
                    });
                }
            });
        };
    
        // Fetch data initially
        fetchData();
    
        // Set interval to call fetchData every 20 seconds
        const interval = setInterval(fetchData, 20000);  // 20000ms = 20 seconds
    
        // Cleanup interval on component unmount
        return () => clearInterval(interval);
    }, []);
    

    const toggleDashboard = () => {
        setShowInventory(!showInventory); // Toggle between Sales and Inventory
    };

    const updateDashboard = () => {
        const selectedPlant = document.getElementById("plant_selector").value;
        const selectedMonthYear = document.getElementById("monthSelector").value;

        let filteredData = salesData.filter((row) => {
        const plantMatch = !selectedPlant || row["Plant Name"] === selectedPlant;
        const monthMatch =
            !selectedMonthYear || row["Month Name"] === selectedMonthYear;
        return plantMatch && monthMatch;
        });
        console.log(selectedMonthYear+"selectedMonthYear")
        console.log("Filtered Data:", filteredData); // Debugging
        // updateCards(filteredData, selectedMonthYear);
        updateCharts(filteredData);
        setFilteredData(filteredData); // Update filtered data for the table
    };

    const isSameDay = (date1, date2) => {
        return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate()
        );
    };


    const updateCharts = (data) => {
        let salesByDate = {};
        let salesByGrade = {};
        let salesByRep = {};

        const selectedMonthYear = document.getElementById("monthSelector").value;
        let selectedYear, selectedMonth;
        if (selectedMonthYear) {
        const [monthName, year] = selectedMonthYear.split(" ");
        selectedYear = parseInt(year);
        selectedMonth = new Date(`${monthName} 1, ${year}`).getMonth();
        }

        if (selectedMonthYear) {
        const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
        for (let day = 1; day <= daysInMonth; day++) {
            const date = new Date(selectedYear, selectedMonth, day);
            const dateKey = formatDate(date);
            salesByDate[dateKey] = 0;
        }
        }

        data.forEach((row) => {
        const rowDate = new Date(row["Date"]);
        const amount = parseFloat(row["Amount"]) || 0;
        const grade = row["Dc Grade"];
        const salesrep = row["Salesrep name"]?.trim() || "Unknown";

        if (rowDate instanceof Date && !isNaN(rowDate)) {
            const dateKey = formatDate(rowDate);
            if (salesByDate[dateKey] !== undefined) {
            salesByDate[dateKey] += amount;
            }
        }

        if (grade) {
            salesByGrade[grade] = (salesByGrade[grade] || 0) + (parseFloat(row["Dc Qty"]) || 0);
        }

        if (salesrep) {
            salesByRep[salesrep] = (salesByRep[salesrep] || 0) + amount;
        }
        });

        const barColors = ["#FF5733", "#33FF57", "#3357FF", "#FFD700", "#8A2BE2", "#20B2AA"];
        const lineColor = "rgba(46, 204, 113, 0.7)";

        const sortedDates = Object.keys(salesByDate).sort((a, b) => new Date(a) - new Date(b));
        const sortedSalesByDate = sortedDates.map((date) => salesByDate[date]);

        renderChart("lineChart", "line", sortedDates, sortedSalesByDate, [lineColor]);
        renderChart("salesChart", "bar", Object.keys(salesByGrade), Object.values(salesByGrade), barColors);
        renderChart("marketingChart", "bar", Object.keys(salesByRep), Object.values(salesByRep), barColors);

        const pieChartData = Object.keys(salesByGrade).map((grade) => ({
        grade: grade,
        qty: salesByGrade[grade],
        }));

        renderPieChart("pieChart", pieChartData, barColors);
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    };

    const renderChart = (canvasId, type, labels, data, colors) => {
        let ctx = document.getElementById(canvasId).getContext("2d");

        if (charts[canvasId]) charts[canvasId].destroy();

        const newChart = new Chart(ctx, {
        type: type,
        data: {
            labels: labels,
            datasets: [
            {
                label:
                canvasId === "lineChart"
                    ? "Sales by Date"
                    : canvasId === "salesChart"
                    ? "Quantity Sold by Grade"
                    : canvasId === "marketingChart"
                    ? "Sales by Salesrep"
                    : "Sales",
                data: data,
                backgroundColor: colors,
                borderColor: colors,
                borderWidth: 1,
            },
            ],
        },
        options: {
            responsive: true,
            scales: {
            x: {
                title: {
                display: true,
                text:
                    canvasId === "lineChart"
                    ? "Date"
                    : canvasId === "salesChart"
                    ? "Grade"
                    : canvasId === "marketingChart"
                    ? "Salesrep"
                    : "Category",
                },
            },
            y: {
                title: {
                display: true,
                text:
                    canvasId === "salesChart" ? "Quantity Sold" : "Sales Amount",
                },
                beginAtZero: true,
            },
            },
            plugins: {
            legend: { display: false },
            title: { display: false },
            datalabels: {
                display: true,
                color: "#000",
                anchor: "end",
                align: "top",
                formatter: function (value) {
                return value;
                },
            },
            },
        },
        plugins: [ChartDataLabels],
        });

        setCharts((prevCharts) => ({ ...prevCharts, [canvasId]: newChart }));
    };

    const renderPieChart = (canvasId, data, colors) => {
        let ctx = document.getElementById(canvasId).getContext("2d");

        if (charts[canvasId]) charts[canvasId].destroy();

        const totalQty = data.reduce((sum, item) => sum + item.qty, 0);

        const newChart = new Chart(ctx, {
        type: "pie",
        data: {
            labels: data.map((item) => item.grade),
            datasets: [
            {
                data: data.map((item) => item.qty),
                backgroundColor: colors,
                borderColor: colors,
                borderWidth: 1,
            },
            ],
        },
        options: {
            responsive: true,
            plugins: {
            legend: { display: true },
            tooltip: {
                callbacks: {
                label: function (context) {
                    const label = context.label || "";
                    const value = context.raw || 0;
                    const percentage = ((value / totalQty) * 100).toFixed(2);
                    return `${label}: ${value} (${percentage}%)`;
                },
                },
            },
            datalabels: {
                display: function (context) {
                const value = context.dataset.data[context.dataIndex];
                const percentage = (value / totalQty) * 100;
                return percentage >= 5;
                },
                color: "#000",
                font: {
                size: 12,
                weight: "bold",
                },
                formatter: function (value, context) {
                const percentage = ((value / totalQty) * 100).toFixed(2);
                return `${value}\n(${percentage}%)`;
                },
                align: "center",
                anchor: "center",
                padding: 6,
                textAlign: "center",
            },
            },
        },
        plugins: [ChartDataLabels],
        });
        setCharts((prevCharts) => ({ ...prevCharts, [canvasId]: newChart }));
    };

  return (
    <>
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <div id="page-wrap">
            {/* <h1 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>Welcome to {companyName}</h1> */}
        </div>
                               <Row> 
                                    <Col xl={3} lg={4} md={4}></Col>
                                    <Col xl={9} lg={4} md={4}>
                                            <select className="browser-default custom-select col-sm-5" style={{marginLeft:"30px"}} onChange={handleChange} value={inputs.plant_id || ""} name ="plant_id" required id="plant_selector">
                                                <option value="">Please Select Plant</option>
                                                {plants.map((item) => (
                                                    <option key={item.value} value={item.value}>
                                                        {item.label}</option>
                                                ))}
                                            </select>&nbsp;&nbsp;  
                                            <RangePicker
                                                defaultValue={[initialFromDate, initialToDate]}
                                                format="DD-MM-YYYY"
                                                onChange={handleDateChange}
                                                placement="topLeft"
                                                style={{height:"40px",width:"220px"}}
                                                id="monthSelector"
                                            />
                                            &nbsp;&nbsp;  
                                            {/* <Button  id="toggleDashboard"  color="primary" onClick={toggleDashboard}   variant="contained">Switch to Inventory</Button> */}
                                            &nbsp;&nbsp; 
                                     </Col>
                                </Row> 
        {showInventory ? (
            <InventoryDashboard />
        ) : (
            <div id="salesDashboard">
            <div className="sales-dashboard-title"></div>
            <Cards day={day} yesterday={yesterday} week={week} month={month} year={year} />
             {/*<div className="charts-title"></div>
            <Charts />*/}
            <DataTable data={filteredData} />
            </div>
        )}
    </>
  );
};

export default Home;