import React from 'react';
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Row, Col, Button } from "reactstrap";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { useCookies } from 'react-cookie';
import { moment } from 'moment';
import { useLocation, Link,useParams } from 'react-router-dom';
import {displayErrorToast,parseBoolean,displayError,parseBooleanToString} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import SummaryIcon from '../../components/SummaryIcon';
import { getHTMLForSummaryPopup } from '../../utils/PopupUtils';
import { getCurrentDate, getCurrentTime} from '../../utils/DateUtils';
import { getCustomerSiteDetails,getAllCustomerSites } from '../../services/CustomerSiteServices';
import { getCustomerMainDetails} from '../../services/CustomerMainServices';
import { getCompanyDetails} from '../../services/CompanyServices';
import { getAllPlants,getPlantDetails } from '../../services/PlantServices';
import { getAllEquipments } from '../../services/EquipmentServices';
import { updateDeliveryChallan,getAllDeliveryChallans,getDropdownOptions,cancelDeliveryChallan,getDeliveryChallanDetails, deleteDeliveryChallan,getDeliveryChallanEmail } from '../../services/DeliveryChallanServices';
import { getSalesOrderDetails } from '../../services/SalesOrderServices';
import { getDesignMixForWorkSchedule } from '../../services/DesignMixServices';
import { getAllProducts } from '../../services/ProductServices';
import { getAllWorkSchedules, getWorkScheduleForDeliveryChallan,getWorkScheduleDetails } from '../../services/WorkScheduleServices';
import { getDisplayDate } from '../../utils/DateUtils';
import { showBillingPop, showCustomerSitePop, showDmixPopup, showProjectrPop, showSalesorderPop, showWorkschedulePop } from './DeliveryChallanUtils';
import NumberSetting from '../../components/NumberSetting';
import { getAllPlantConfigurations } from '../../services/PlantConfigurationServices';
import ViewOnlyTextbox from '../../components/ViewOnlyTextbox';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import DmixIcon from '../../components/DmixIcon';
import Creatable from "react-select/creatable";
import Select from "react-select";

function DeliveryChallanEdit() {
  const location = useLocation();
  const [cookies] = useCookies(['myToken']);
  const [userName] = useCookies(['myName']);
  const [userId] = useCookies(['myUserId']);
  const [category] = useCookies(['myCategory']);
  const [superuser] = useCookies(['mySuperuser']);
  const [inputs, setInputs] = useState({});
  const [inProgress, setInProgress] = useState(false);
  const [initial_dc_qty, setInitialDcQty] = useState(0);
  const [initial_cum_qty, setInitialCumQty] = useState(0);
  const [equipments, setEquipments] = React.useState([]);
  const [userplants, setUserPlant] = React.useState([]);
  const [customervehicles,setCustomerVehicles] =React.useState([{ value: '', label: '' }]);
  const [error, setError] = useState({});
  const [selectedWorkSchedule,setSelectedWorkSchedule] = useState({});
  const [isLoading, setIsLoading] = useState(true);  
  const [workschedules,setWorkschedules]=React.useState([]);
  const [filteredplants,setFilteredPlants] = useState([]);
  const [allWorkschedules,setAllWorkschedules] = useState([]);
  const [finishedgoods, setFinishedGoods] = React.useState([]);
  const [plants,setPlants] =React.useState([]);
  const [isSubmitting, setIsSubmitting] = useState(true);
  const navigate = useNavigate();
  const [selectedCustomer,setSelectedCustomer]=React.useState({})
  const [selectedSite,setSelectedSite]= React.useState({});
  const [plantConfig,setPlantConfig] = React.useState([]);
  const [showModal2, setShowModal2] = useState(false);
  const [options, setOptions] = useState([{ value: '', label: '' }]);
  const [drivers, setDrivers] = useState([{ value: '', label: '' }]);
  const [selectedOperator, setSelectedOperator] = useState('');
  const [selectedDriver, setSelectedDriver] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState('');
    const handleCreatable = (selected) => {
      setSelectedOperator(selected);
      console.log("Selected Option:", selected);
    };
  
    const handleDriver = (selected) => {
      setSelectedDriver(selected);
      console.log("Selected driver:", selected);
    };

    const handleVehicleChange = (selected) => {
      setSelectedVehicle(selected);
      console.log("Selected:", selected);
    };

  React.useEffect(() => {
      setInputs(values => ({...values,
          ['delivery_challan_date']: getCurrentDate(),
          ['delivery_challan_time']: getCurrentTime(),
        
      }))
  }, []);
     
  React.useEffect(() => {
      getAllPlants(cookies)
      .then (
          plantList => {
              const plant = plantList.plant_list.filter(obj => obj.status).map(
                  plant => {
                      return { value: plant.id, label: plant.plant_alias }
                  }
              );
              setPlants(plant);
          }
      )
  }, []);

  React.useEffect(() => {
    getAllWorkSchedules(cookies)
      .then (
          workscheduleList => {
              const workschedules = workscheduleList.work_schedule_list.map(
                workschedule => {
                  return { value: workschedule.id, 
                      label: workschedule.schedule_no}
                      //  + "-" + workschedule.sales_order_detail.prefix + workschedule.sales_order_detail.order_no + "(" + workschedule.sales_order_detail.product.name + ")"}
                  }
              );
              console.log(JSON.stringify(workschedules)+"work_schedule_list")
              setWorkschedules(workschedules);
          }
      )
  }, []);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    try
    {
      setInputs(values => ({...values, [name]: value}));
      if(name === 'plant_id' && value){
          setIsLoading(true);
          getWorkScheduleForDeliveryChallan(cookies,value)
          .then( workscheduleList => {
            const workschedules = workscheduleList.work_schedule_list.map(
                workschedule => {
                    return { value: workschedule.id, 
                      label: workschedule.schedule_no + "-" + workschedule.sales_order_detail.sales_order.prefix + workschedule.sales_order_detail.sales_order.order_no + "(" + workschedule.sales_order_detail.product.name + ")"}
                }
            );
            setWorkschedules(workschedules);
            setAllWorkschedules(workscheduleList.work_schedule_list)
          })
          .catch((error) =>
          {
              setIsLoading(false);
              console.log(error.response.data);
              displayError(error.response.data," Failed");
          })
      } 
      else if (name === 'plant_id' && !value) {
          setInputs(values => ({...values, ['delivery_challan_no']: ''}));
      }
      else if(name === 'transport_mode')
      {
        if(value === 'customer')
        {
          document.getElementById('customer_vehicle_no').style.display="block";
          document.getElementById('vehicle_id').style.display="none";
        }
        else if(value === 'own')
        {
          document.getElementById('customer_vehicle_no').style.display="none";
          document.getElementById('vehicle_id').style.display="block";
        }
      }
    }
    catch (e) {
      displayErrorToast(e);
    }
    setInputs(values => ({...values, [name]: event.target.value}))
  }
 
  const handleVehicleDetailsIconClick = () => {
    setShowModal2(true);
  };      

  const handleDeliveryChallanQty = (event)=>{
    const name = event.target.name;
    const value = event.target.value;
    if (name === 'production_qty' || name === 'purchase_qty' || name === 'received_qty'|| name ==='other_qty' || name ==='client_adjust_qty') {
      setInputs(values => ({...values, [name]: event.target.value}))
      var  productionqty = document.getElementById('production_qty').value;
      console.log(productionqty+"productionqty")
      var purchaseqty = document.getElementById('purchase_qty').value; 
      console.log(purchaseqty+"purchaseqty")
      var receivedqty =  document.getElementById('received_qty').value; 
      console.log(receivedqty+"receivedqty")
      var otherqty = document.getElementById('other_qty').value;
      console.log(otherqty+"otherqty")
      var clientadjustqty = document.getElementById('client_adjust_qty').value;
      console.log(clientadjustqty+"clientadjustqty")
      if((productionqty === '') || (productionqty === NaN) ){
        productionqty = 0;
      }
      if((purchaseqty === '') || (purchaseqty === NaN) ){
        purchaseqty = 0;
      }
      if((receivedqty === '') || (receivedqty === NaN) ){
        receivedqty = 0;
      }
      if((otherqty === '') || (otherqty === NaN) ){
        otherqty = 0;
      }
      if((clientadjustqty === '') || (clientadjustqty === NaN) ){
        clientadjustqty = 0;
      }
      console.log(productionqty+"productionqty")
      var dcqty = parseFloat(productionqty) + parseFloat(purchaseqty) + parseFloat(receivedqty) + parseFloat(otherqty) + parseFloat(clientadjustqty);
      var difference = (dcqty)-initial_dc_qty;
      var cumulative_qty = (parseFloat(initial_cum_qty)+ parseFloat(difference)).toFixed(2)
      console.log(cumulative_qty+"cumulative_qty");
      console.log(parseFloat(dcqty)+"parseFloat(dcqty)");
      setInputs(values => ({...values, ['dc_qty']: dcqty,['cumulative_quantity']:cumulative_qty}))
      const step1 = parseFloat(inputs.dc_qty) / parseFloat(inputs.mixer_capacity);
      const step2 = Math.ceil(step1);
      const step3 = parseFloat(inputs.dc_qty) / step2;
      setInputs((values) => ({...values, ['batch_size']: step3.toFixed(2),
      }));
    }
    setInputs(values => ({...values, [name]: event.target.value}))
  };
  
  const handleChangeWorkscheduleDetails = (event) => {
      const workscheduleId = event.target.value;
      const propertyName = event.target.name;
      if (workscheduleId) {
        setInputs(values =>({...values,[propertyName]:workscheduleId}));
        const selected_ws = allWorkschedules.filter(obj => obj.id == workscheduleId)[0];
        setSelectedWorkSchedule(selected_ws);
        if(selected_ws.sales_order_detail.sales_order.transport_mode === 'customer')
        {
          setInputs(values =>({...values,['transport_mode']:'customer'}))
          document.getElementById('customer_vehicle_no').style.display="block";
          document.getElementById('vehicle_id').style.display="none";
          document.getElementById('transport_mode').disabled = true;
        }
        else if(selected_ws.sales_order_detail.sales_order.transport_mode === 'own')
        {
          setInputs(values =>({...values,['transport_mode']:'own'}))
          document.getElementById('customer_vehicle_no').style.display="none";
          document.getElementById('vehicle_id').style.display="block";
          document.getElementById('transport_mode').disabled = true;
        }
        else{
          setInputs(values =>({...values,['transport_mode']:'own'}))
          document.getElementById('transport_mode').disabled = false;
          document.getElementById('customer_vehicle_no').style.display="none";
          document.getElementById('vehicle_id').style.display="block";
        }
        setInputs(values =>({...values,
            ['production_grade_id']:selected_ws.sales_order_detail.product.id,
            ['purchase_grade_id']:selected_ws.sales_order_detail.product.id,
            ['received_grade_id']: selected_ws.sales_order_detail.product.id,
            ['other_grade_id']: selected_ws.sales_order_detail.product.id,
            ['dc_grage_id']:  selected_ws.sales_order_detail.product.id,
            ['schedule_qty']:selected_ws.scheduled_qty,
            ['balance_qty']:selected_ws.balance_qty,
            ['mixer_capacity']:selected_ws.plant.mixer_capacity,
        }));
        setIsLoading(true);
        getCompanyDetails(cookies,selected_ws.sales_order_detail.sales_order.company.id)  //get plant list
        .then(company =>{
            setIsLoading(false);
            setFilteredPlants(company.plants.map( plant =>{
                return {value: plant.id,label : plant.alias}
            }));
        });
      }
  }
      
  const handleDeliveryChallanDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if(name === "creation_date"){
      setInputs(values => ({...values, 'delivery_challan_date': event.target.value}))
    } else {
      setInputs(values => ({...values, 'delivery_challan_time': event.target.value}))
    }
  }
  
  const updateDCNumberAndPrefix = (prefix, serial_no) => {
    setInputs(values => ({...values,
        ['delivery_challan_num_prefix']:(prefix+serial_no),
        ['delivery_challan_no']:serial_no,
        ['prefix']:prefix}));
  } 

  const showCustomerDetails = () => {
    showBillingPop(selectedCustomer);
  }
      
  const showSiteDetails = () => {
    if (selectedWorkSchedule.id) {
        getCustomerSiteDetails(cookies,selectedWorkSchedule.sales_order_detail.sales_order.consignee.id)
        .then(consignee => {
            showCustomerSitePop(consignee);
        })
    }
  }
  
  const showProjectDetails = () => {
      if (selectedWorkSchedule.id) {
          getCustomerSiteDetails(cookies,selectedWorkSchedule.sales_order_detail.sales_order.consignee.id)
          .then(delivery_details => {
            showProjectrPop(delivery_details);
          })
      }
  }

  const showWorkScheduleDetails = () => {
    if (selectedWorkSchedule.id) {
         showWorkschedulePop(selectedWorkSchedule);
    }
  }

  const showSalesOrderDetails = () => {
    if (selectedWorkSchedule.id) {
      showSalesorderPop(selectedWorkSchedule);
    }
  }
  
  const showDesignMixTable = () =>{
    if (selectedWorkSchedule.id) {
      getDesignMixForWorkSchedule(cookies,selectedWorkSchedule.id)
      .then(designmix_list => {
          showDmixPopup(designmix_list,plantConfig);
      })
    }
  }

  const constructFormData = () => {
    const EMPTY_STRING = '';
    return {
        id :inputs.id,
        plant_id:parseInt(inputs.plant_id) ,
        production_plant_id:parseInt(inputs.production_plant_id),
        work_schedule_id:parseInt(inputs.work_schedule_id),
        delivery_challan_no:inputs.delivery_challan_no,
        delivery_challan_date:getDisplayDate(inputs.delivery_challan_date),
        delivery_challan_time:inputs.delivery_challan_time,
        prefix:inputs.prefix,
        production_grade_id:parseInt(inputs.production_grade_id),
        purchase_grade_id:parseInt(inputs.purchase_grade_id),
        received_grade_id:parseInt(inputs.received_grade_id),
        other_grade_id:parseInt(inputs.other_grade_id),
        production_qty:inputs.production_qty?parseFloat(inputs.production_qty):0,
        purchase_qty:inputs.purchase_qty?parseFloat(inputs.purchase_qty):0,
        other_qty:inputs.other_qty?parseFloat(inputs.other_qty):0,
        received_qty:inputs.received_qty?parseFloat(inputs.received_qty):0,
        balance_qty:parseFloat(inputs.balance_qty),
        mixer_capacity:parseFloat(inputs.mixer_capacity),
        client_adjust_qty:inputs.client_adjust_qty?parseFloat(inputs.client_adjust_qty):0,
        is_client_adj_qty_incld_inv:inputs.is_client_adj_qty_incld_inv?parseBoolean(inputs.is_client_adj_qty_incld_inv):true,
        dc_qty:parseFloat(inputs.dc_qty),
        vehicle_id:parseInt(selectedVehicle.value),
        transport_mode:inputs.transport_mode,
        customer_vehicle_no:inputs.customer_vehicle_no,
        // customer_vehicle_no:selectedOptionCustomerVehicles.value,
        operator_name:selectedOperator?selectedOperator.value:'',
        cumulative_sl_no: parseInt(inputs.cumulative_serial_no),
        cumulative_qty:inputs.cumulative_quantity?parseFloat(inputs.cumulative_quantity):0,
        batch_size:parseFloat(inputs.batch_size),
        batch_no:parseInt(inputs.batch_no),
        user_remarks:inputs.user_remarks,
        checkout_no:inputs.checkout_no,
        checkout_date:getDisplayDate(inputs.checkout_date),
        checkout_time:inputs.checkout_time,
        gross_weight:parseInt(inputs.gross_weight),
        net_weight:parseInt(inputs.net_weight),
        tare_weight:parseInt(inputs.tare_weight),
        weighment_slip_time:inputs.weighment_slip_time,
        weighment_slip_no:inputs.weighment_slip_no,
        weighment_slip_date:getDisplayDate(inputs.weighment_slip_date),
        driver_name:selectedDriver?selectedDriver.value:'',
        driver_mobile_no:inputs.driver_mobile_no,
        opening_km:parseInt(inputs.opening_km),
        opening_hm:parseInt(inputs.opening_hm),
        slump:parseInt(inputs.slump)
    }
  } 
      
 //If all the required fields are filled u can select the save button then choose the option yes or no
  const handleSubmit = (event) => {
    event.preventDefault();
    if ((Object.values(error).every(x => !x)) && isSubmitting) { 
        Swal.fire({title: 'Do you want to Update?',  
          showCancelButton: true,  
          confirmButtonText: `Yes`,  
          cancelButtonText: `No`,
          }).then((result) => {  
            if (result.isConfirmed) 
            { 
              updateDeliveryChallan(cookies, constructFormData(),id)
              .then(function (response) {
                Swal.fire('Updated!', '', 'success') 
                view();
              })
              .catch((error) =>
              {
                console.log(error.response.data);
                displayError(error.response.data,"Update Failed");
              })
              
            } 
            else if (result.isDismissed) 
            {    
              Swal.fire('Not Updated', '', 'info')  
            }
          });
    }
    else
    {
      Swal.fire('Please check * marked fields are filled and details given are valid...', '', 'info')   
    }
  }

  const {id}=useParams()

  React.useEffect(() => {
    getAllProducts(cookies)
    .then (
        productList => {
            setFinishedGoods( 
              productList.product_list.filter(product => product.status && product.category.name === 'FINISHED GOODS')
                .map(
                    product => {
                        return { value: product.id, label: product.name }
                    }
                ));
        }
    );  
    getDropdownOptions(cookies)
        .then (
          response => {
            setOptions(response.operators.map(
                  obj => {
                      return { value: obj, label: obj }
                  }
              ))
            setDrivers(response.drivers.map(
              obj => {
                  return { value: obj, label: obj }
              }
          ))
            setCustomerVehicles(response.customer_vehicles.map(
              obj => {
                  return { value: obj, label: obj }
              }
          ))
          //   setOptions(values =>([...values,response.operators.map(
          //     obj => {
          //         return { value: obj, label: obj }
          //     }
          // )]));
          //   setDrivers(values =>([...values,response.drivers.map(
          //     obj => {
          //         return { value: obj, label: obj }
          //     }
          // )]));
          //   setCustomerVehicles(values =>([...values,response.customer_vehicles.map(
          //     obj => {
          //         return { value: obj, label: obj }
          //     }
          // )]));
          }
        )
    document.getElementById('customer_vehicle_no').style.display="none";
    if (id) {
  setInProgress(true);

  getDeliveryChallanDetails(cookies, id)
  .then(deliverychallan_object => {
    setInProgress(false);
    setInputs({...deliverychallan_object, 
        ['id']:deliverychallan_object.id,
        ['plant_id']:deliverychallan_object.work_schedule.plant.id,
        ['production_plant_id']:deliverychallan_object.production_plant.id,
        ['work_schedule_id']:deliverychallan_object.work_schedule.id,
        ['delivery_challan_no']:deliverychallan_object.delivery_challan_no,
        ['delivery_challan_date']:getDisplayDate(deliverychallan_object.delivery_challan_date),
        ['delivery_challan_time']:deliverychallan_object.delivery_challan_time,
        ['prefix']:deliverychallan_object.prefix,
        ['production_grade_id']:deliverychallan_object.production_grade.id,
        ['purchase_grade_id']:deliverychallan_object.purchase_grade.id,
        ['received_grade_id']:deliverychallan_object.received_grade.id,
        ['other_grade_id']:deliverychallan_object.other_grade.id,
        ['production_qty'] :parseFloat(deliverychallan_object.production_qty),
        ['purchase_qty']:parseFloat(deliverychallan_object.purchase_qty),
        ['other_qty']:parseFloat(deliverychallan_object.other_qty),
        ['received_qty']:parseFloat(deliverychallan_object.received_qty),
        ['schedule_qty']:parseFloat(deliverychallan_object.work_schedule.schedule_qty),
        ['balance_qty']:parseFloat(deliverychallan_object.work_schedule.balance_qty),
        ['mixer_capacity']:parseFloat(deliverychallan_object.work_schedule.plant.mixer_capacity),
        ['client_adjust_qty']:parseFloat(deliverychallan_object.client_adjust_qty),
        ['is_client_adj_qty_incld_inv'] :parseBoolean(deliverychallan_object.work_schedule.is_client_adj_qty_incld_inv),
        ['dc_qty'] :parseFloat(deliverychallan_object.dc_qty),
        ['vehicle_id'] :deliverychallan_object.vehicle.id,
        ['transport_mode'] :deliverychallan_object.transport_mode,
        ['customer_vehicle_no']:deliverychallan_object.customer_vehicle_no,
        // ['operator_name'] :deliverychallan_object.operator_name,
        ['cumulative_serial_no'] :deliverychallan_object.cumulative_sl_no,
        ['cumulative_quantity'] :parseFloat(deliverychallan_object.cumulative_qty),
        ['batch_size'] :parseFloat(deliverychallan_object.batch_size),
        ['batch_no'] :parseInt(deliverychallan_object.batch_no),
        ['user_remarks'] :deliverychallan_object.user_remarks,
        ['editing_details'] :deliverychallan_object.editing_details,
        ['is_schedule_completed']:deliverychallan_object.work_schedule.is_schedule_completed,
        ['gross_weight']: deliverychallan_object.gross_weight,
        ['net_weight']: deliverychallan_object.net_weight,
        ['tare_weight']:deliverychallan_object.tare_weight,
        ['weighment_slip_time']: deliverychallan_object.weighment_slip_time,
        ['weighment_slip_no']: deliverychallan_object.weighment_slip_no,
        ['weighment_slip_date']: getDisplayDate(deliverychallan_object.weighment_slip_date),
        // ['driver_name']: deliverychallan_object.driver_name,
        ['driver_mobile_no']: deliverychallan_object.driver_mobile_no,
        ['opening_km']: deliverychallan_object.opening_km,
        ['opening_hm']: deliverychallan_object.opening_hm,
        ['slump']: deliverychallan_object.slump});
        setSelectedVehicle({'label':deliverychallan_object.vehicle.name,'value':deliverychallan_object.vehicle.id})
        if (deliverychallan_object.operator_name) {
          setSelectedOperator({
            value: deliverychallan_object.operator_name,
            label: deliverychallan_object.operator_name
          });
        }
        if (deliverychallan_object.driver_name) {
          setSelectedDriver({
            value: deliverychallan_object.driver_name,
            label: deliverychallan_object.driver_name
          });
        }
        // setSelectedOperator(deliverychallan_object.operator_name)
        setInitialDcQty(deliverychallan_object.dc_qty)
        setInitialCumQty(deliverychallan_object.cumulative_qty)

        if(deliverychallan_object.transport_mode === 'own'){
          document.getElementById('customer_vehicle_no').style.display="none";
          document.getElementById('vehicle_id').style.display="block";
        }
        else {
          document.getElementById('customer_vehicle_no').style.display="block";
          document.getElementById('vehicle_id').style.display="none";
        }
        if ((deliverychallan_object.work_schedule.sales_order_detail.sales_order.transport_mode === 'own')||(deliverychallan_object.work_schedule.sales_order_detail.sales_order.transport_mode === 'customer')){
          document.getElementById('transport_mode').disabled=true;
        }
      
      setEquipments(equipments => [...equipments, 
          ({['value']: deliverychallan_object.vehicle.id,
            ['label']: deliverychallan_object.vehicle.name})
        ]);
        setSelectedWorkSchedule(deliverychallan_object.work_schedule);  
        
      

        getCompanyDetails(cookies,deliverychallan_object.work_schedule.sales_order_detail.sales_order.company.id)  //get plant list
        .then(company =>{
            setIsLoading(false);
            setFilteredPlants(company.plants.map( plant =>{
                return {value: plant.id,label : plant.alias}
            }));
      });
      
      getAllPlantConfigurations(cookies,deliverychallan_object.work_schedule.plant.id)
      .then (
          plantConfigList => {
              setPlantConfig(plantConfigList);
          }
      )

        getCustomerMainDetails(cookies,deliverychallan_object.work_schedule.sales_order_detail.sales_order.consignee.customer.id)    
            .then(customerObject => {              //Set the details of the customer in the form fields
                setSelectedCustomer(customerObject)
                })
        getCustomerSiteDetails(cookies,deliverychallan_object.work_schedule.sales_order_detail.sales_order.consignee.id)
        .then(customerObject => {                     //Set the details of the customer in the form fields

          
            setSelectedSite(customerObject);
        });
        getPlantDetails(cookies,deliverychallan_object.work_schedule.plant.id)  //get plant list
                .then(plants =>{
                
                        return {value: plants.id,label : plants.name}
                        
                    })

        // getSalesOrderDetails(cookies,deliverychallan_object.sales_order_detail.sales_order.id)
        getSalesOrderDetails(cookies,deliverychallan_object.work_schedule.sales_order_detail.sales_order.id)
          .then(salesorder =>{
              
            return {value: salesorder.id,label : salesorder.name}
        
        })
        // getDesignMixForWorkSchedule(cookies,deliverychallan_object.selectedWorkSchedule.sales_order_detail.id)
        getDesignMixForWorkSchedule(cookies,deliverychallan_object.work_schedule.sales_order_detail.id)          
        .then(designmix_list => {
                    return {value: designmix_list.id,label : designmix_list.name}
                  })
        getAllEquipments(cookies)
                  .then (
                          equipmentObject => {
                        // console.log(JSON.stringify(equipmentObject)+"getAllEquipments")
                            const equipments = equipmentObject.equipment_list.filter(obj => ((obj.equip_type.name === 'TRANSIT MIXER')&& (obj.status))).map(
                                equipment => {
                                  console.log(JSON.stringify(equipment)+"equipment")
                                    if(equipment.is_equip_ready){
                                      return { value: equipment.id, label: equipment.equip_name }
                                    }
                                    else if((deliverychallan_object.transport_mode === 'own') && (equipment.id === deliverychallan_object.vehicle.id)){
                                      return { value: equipment.id, label: equipment.equip_name }
                                    }
                                    // If neither condition is met, return null
          return null; 
                                }
                            ).filter((equipment) => equipment !== null);
                            console.log(JSON.stringify(equipments)+"equipments")
                            setEquipments(equipments);
                        }
                    )       
                  
  })
  .catch(error => {
    setInProgress(false);
    console.log(error+"error")
    displayError(error.response.data, "Loading Failed");
  });
    }
  }, []);

  const onDeleteDeliveryChallan = (event) => {
    event.preventDefault();
    Swal.fire({title: 'Are you sure to Delete?',  
    showCancelButton: true,  
    confirmButtonText: `Yes`,
    cancelButtonText: `No`,
    }).then((result) => {  
      if (result.isConfirmed) { 
        setIsLoading(true);
        deleteDeliveryChallan(cookies, id)
        .then(response => {
            console.log(`Delivery Challan with id ${id} deleted`);
            Swal.fire('Deleted Successfully!', '', 'success');
            view();
        })
        .catch(error => {
            console.log(error.response.data);
            displayError(error.response.data,'Delete Failed');
        }); 
        }
      else if (result.isDismissed) {    
        Swal.fire('Not Deleted', '', 'info')  
      }
    });
  };

  const onCancelDeliveryChallan = (event) => {
    event.preventDefault();
    Swal.fire({title: 'Do you want to cancel the Delivery Challan?',  
    showCancelButton: true,  
    confirmButtonText: `Yes`,
    cancelButtonText: `No`,
    }).then((result) => {  
      if (result.isConfirmed) { 
        setIsLoading(true);
        cancelDeliveryChallan(cookies, id)
        .then(response => {
            console.log(`Delivery Challan with id ${id} canceled`);
            Swal.fire('Cancel Successfully!', '', 'success');
            view();
        })
        .catch(error => {
            console.log(error.response.data);
            displayError(error.response.data,'Cancel Failed');
        }); 
      }
      else if (result.isDismissed) {    
        Swal.fire('Not Canceled', '', 'info')  
      }
    });
  };

  const onEmailDeliveryChallan = (event) => {
    event.preventDefault();
    Swal.fire({title: 'Do you want to send the Email?',  
    showCancelButton: true,  
    confirmButtonText: `Yes`,
    cancelButtonText: `No`,
    }).then((result) => {  
      if (result.isConfirmed) { 
        setIsLoading(true);
        getDeliveryChallanEmail(cookies, id)
        .then(response => {
            console.log(`Delivery Challan with id ${id} Email`);
            Swal.fire('Email Sent Successfully!', '', 'success');
            view();
        })
        .catch(error => {
            console.log(error.response.data);
            displayError(error.response.data,'Email Failed');
        }); 
      }
      else if (result.isDismissed) {    
        Swal.fire('Not EmailID', '', 'info')  
      }
    });
  };

  const validate_items = (name,value,regular_expr,error_message) =>
  {
      try{
        if(value.trim())     //If  required condition is valid it accept ,otherwise it is Invalid.
        {
            if(!regular_expr.test(value.trim())) //If regex is not statisfied ,then it is false
            {
              console.log(value+"value");
              setError(values => ({...values, [name]: error_message}))
              setIsSubmitting(false); 
            }
            else            //If regex is statisfied ,then it is true
            {
              setError(values => ({...values, [name]: ''}))
              setIsSubmitting(true);
            }
        }
        // else                 //If value must not be empty this condition is false
        // {
        //     setError(values => ({...values, [name]: 'must not be empty...'}))
        //     setIsSubmitting(false);
        // }                    // If condition is valid then display the values
        setInputs(values => ({...values, [name]: value}))
      }
      catch (e)
      {
        displayErrorToast(e);
      }
  }

  const handleChangeWeightDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name === 'tare_weight' || name === 'gross_weight') {
        var tare_weight = document.getElementById('tare_weight').value;
        var gross_weight = document.getElementById('gross_weight').value;     
        if((tare_weight === '') || (tare_weight === NaN) ){
            tare_weight = 0;
        }
        if((gross_weight === '') || (gross_weight === NaN) ){
            gross_weight = 0;
        }
        console.log(tare_weight+"tare_weight")
        console.log(gross_weight+"gross_weight")
        var net_weight = parseFloat(gross_weight) - parseFloat(tare_weight);
        console.log(net_weight+"net_weight")
        setInputs(values => ({...values, ['net_weight']: net_weight})) 
    }
    setInputs(values => ({...values, [name]: event.target.value}))
  };
       
  const Reload = () => {
    window.location.reload();
  }  

  const Cancel = () => {
    setInputs(() => "")
  }

  const view = () => {
    navigate('/DeliveryChallanTable')
  } 

  return (
    <>
      <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
      <ErrorToast/>
      <LoadingOverlay inProgress={inProgress}/>
      <div id="page-wrap">
        <form onSubmit={handleSubmit} >
          <div id="header">
            <h3 className="text font-weight-bold page-title">DELIVERY CHALLAN EDIT FORM</h3>
          </div>
          <FloatingControls tableLink="/DeliveryChallanTable" onCancel={Cancel} enableCancel={true}/>
          <div className="container">
            <Row>
              <Col xl={12} lg={12} md={12} >   
                <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3  form-control-panel " >
                  <h5 className='col-sm-11'><b>Work Schedule Details</b></h5><br/><br/>
                  <label htmlFor="plant_id" className="form-group col-sm-2 text-right"> Plant Location: </label>
                  <select id="plant_id"name="plant_id" className="form-control col-sm-3  browser-default custom-select  mandatory-form-control"  required disabled={true} onChange={handleChange} value={inputs.plant_id || ""}>
                          <option value="">Select plant</option>
                          {plants.map((item) => (
                          <option key={item.value} value={item.value}>
                              {item.label}</option>
                          ))}
                  </select>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                  <label htmlFor="work_schedule_id" className="form-group col-sm-2 text-right"> Work Schedule</label>
                  <select id="work_schedule_id"name="work_schedule_id" className="form-control col-sm-3  browser-default custom-select  mandatory-form-control"  required disabled={true} onChange={ handleChangeWorkscheduleDetails} value={inputs.work_schedule_id || ""}>
                          <option value="">Select Work Schedule</option>
                          {workschedules.map((item) => (
                          <option key={item.value} value={item.value}>
                              {item.label}</option>
                          ))}
                  </select>
                  <SummaryIcon onClickHandler={showWorkScheduleDetails}/> &nbsp;
                  <DmixIcon onClickHandler={showDesignMixTable}/>
                  {/* <a className="btn-dmix" onClick={showDesignMixTable} >DMIX</a> */}
                  <ViewOnlyTextbox 
                  name="billing_name" 
                  value={selectedWorkSchedule?.sales_order_detail?.sales_order?.consignee?.customer?.name || ""}
                  labelClassName="form-group col-sm-2 text-right"
                  label="Billing Name"
                  inputClassName="form-control col-sm-3"
                  showSummaryDetails={showCustomerDetails}
                  /> 
                  <br/>
                                              
                  <ViewOnlyTextbox 
                  name="ship_to" 
                  value={selectedWorkSchedule?.sales_order_detail?.sales_order?.consignee?.name|| ""}
                  labelClassName="form-group col-sm-2 text-right"
                  label="Ship To"
                  inputClassName="form-control col-sm-3"
                  showSummaryDetails={showSiteDetails}
                  /> 

                  <ViewOnlyTextbox 
                  name="ship_to" 
                  value={selectedWorkSchedule?.sales_order_detail?.sales_order?.consignee?.project_name|| ""}
                  labelClassName="form-group col-sm-2 text-right"
                  label="Project/Site"
                  inputClassName="form-control col-sm-3"
                  showSummaryDetails={showProjectDetails}
                  />   
                  
                  <ViewOnlyTextbox 
                  name="sales_order_no" 
                  value={
                    `${selectedWorkSchedule?.sales_order_detail?.sales_order?.prefix || ""}${selectedWorkSchedule?.sales_order_detail?.sales_order?.order_no || ""}`
                }
                  // {selectedWorkSchedule?.sales_order_detail?.sales_order?.order_no || ""}
                  labelClassName="form-group col-sm-2 text-right"
                  label="Sales order No"
                  inputClassName="form-control col-sm-3"
                  showSummaryDetails={showSalesOrderDetails}
                  />
                </div>
              </Col>
              <Col xl={6} lg={12} md={12}>
                <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel ">
                  <label htmlFor="production_plant_id" className="form-group col-sm-4 text-right"> Production Plant </label>
                  <select id="production_plant_id"name="production_plant_id" className="form-control col-sm-7  browser-default custom-select  mandatory-form-control"  required onChange={handleChange} value={inputs.production_plant_id || ""}>
                    <option value="">Select plant</option>
                    {filteredplants.map((item) => (
                        <option key={item.value} value={item.value}>{item.label}</option>
                    ))}
                  </select>

                  <NumberSetting 
                      handleDateChange={handleDeliveryChallanDetails} 
                      serial_no={inputs.delivery_challan_no} 
                      creation_date={inputs.delivery_challan_date}
                      creation_time={inputs.delivery_challan_time}
                      prefix={inputs.prefix}
                      company_id={inputs.company_id}
                      voucher_type={"delivery_challan"}
                      handleNumberAndPrefixUpdate={updateDCNumberAndPrefix}
                      cookies={cookies}
                      serial_no_title={'DeliveryChallan No'}
                      hideTime={false}/>

                  <label htmlFor="batch_no" className="form-group col-sm-4 text-right">Batch No</label>
                  <input type="number" onChange={handleChange} name="batch_no"  className="form-control col-sm-7  mandatory-form-control" id="batch_no"  value={inputs.batch_no || ""}  /><br />

                  <label htmlFor="production_grade_id" className="form-group col-sm-4 text-right">Production Grade | Qty </label>
                  <select id="production_grade_id"name="production_grade_id" className="form-control col-sm-4  browser-default custom-select  mandatory-form-control" required type="number" min="0"  onChange={handleChange} value={inputs.production_grade_id || ""}>
                    {/* {(selectedWorkSchedule.id ? 
                      <option value="{selectedWorkSchedule.sales_order_detail.product.id}">{selectedWorkSchedule.sales_order_detail.product.name}</option>
                      : null
                    )} */}
                     {finishedgoods.map((item) => (
                        <option key={item.value} value={item.value}>{item.label}</option>
                    ))}
                  </select>   
                  
                  <input required type="number" step="any" min="0" value={inputs.production_qty || 0} onChange={handleDeliveryChallanQty} name="production_qty" id="production_qty" className="form-control col-sm-3" />

                  <label htmlFor="purchase_grade_id" className="form-group col-sm-4 text-right">Purchase Grade | Qty </label>
                  <select id="purchase_grade_id"name="purchase_grade_id" className="form-control col-sm-4  browser-default custom-select  mandatory-form-control" required type="number" min="0"  onChange={handleChange} value={inputs.purchase_grade_id||""}>
                    {finishedgoods.map((item) => (
                        <option key={item.value} value={item.value}>{item.label}</option>
                    ))}
                  </select>   

                  <input required type="number" min="0"  step="any" value={inputs.purchase_qty || 0} onChange={handleDeliveryChallanQty} name="purchase_qty" id="purchase_qty" className="form-control col-sm-3" />

                  <label htmlFor="received_grade_id" className="form-group col-sm-4 text-right">Receive Grade | Qty </label>
                  <select id="received_grade_id"name="received_grade_id" className="form-control col-sm-4  browser-default custom-select  mandatory-form-control" required type="number" min="0"  onChange={handleChange} value={inputs.received_grade_id||""}>
                    {finishedgoods.map((item) => (
                        <option key={item.value} value={item.value}>{item.label}</option>
                    ))}
                  </select>  

                  <input required type="number" min="0"  step="any" value={inputs.received_qty || 0} onChange={handleDeliveryChallanQty} id="received_qty" name="received_qty" className="form-control col-sm-3" />

                  <label htmlFor="other_grade_id" className="form-group col-sm-4 text-right">Other Grade | Qty </label>
                  <select id="other_grade_id" name="other_grade_id" className="form-control col-sm-3  browser-default custom-select  mandatory-form-control" required type="number" min="0"  onChange={handleChange} value={inputs.other_grade_id||""}>
                    {finishedgoods.map((item) => (
                        <option key={item.value} value={item.value}>{item.label}</option>
                    ))}
                    </select>   
                  <input required type="number" min="0"  step="any" name="other_qty" value={inputs.other_qty || 0} onChange={handleDeliveryChallanQty} id="other_qty" className="form-control col-sm-4" />

                  <label htmlFor="client_adjust_qty" className="form-group col-sm-4 text-right">Client Adjust | Qty </label>
                  <input type="number" min="0"  step="any" value={inputs.client_adjust_qty || 0} onChange={handleDeliveryChallanQty} name="client_adjust_qty" id="client_adjust_qty" className="form-control col-sm-7" />
                  
                  <label htmlFor="is_client_adj_qty_incld_inv" className="form-group col-sm-4 text-right">Client Adjust|Qty Invoice</label>
                  <select id="is_client_adj_qty_incld_inv" name="is_client_adj_qty_incld_inv" className="form-control col-sm-7  browser-default custom-select"  onChange={handleChange} value={inputs.is_client_adj_qty_incld_inv || ""}>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select><br />
                  
                  <label htmlFor="dc_qty" className="form-group col-sm-4 text-right">DC Grade|Qty</label>
                  <select id="dc_qty"name="dc_qty" className="form-control col-sm-4  browser-default custom-select  mandatory-form-control" required type="number"  step="any"  onChange={handleChange} value={inputs.dc_qty ||0
                  }>
                  {(selectedWorkSchedule.id ? 
                      <option value="{selectedWorkSchedule.sales_order_detail.product.id}">{selectedWorkSchedule.sales_order_detail.product.name}</option>
                      : null)}
                  </select>   
                  <input required type="number"  step="any" min="0" value={inputs.dc_qty || 0}   className="form-control col-sm-3" name="dc_qty" id="dc_qty"/>
                </div>
              </Col>
              <Col xl={6} lg={12} md={12}>
                  <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel">
                    <label htmlFor="transport_mode" className="form-group col-sm-4 text-right">Mode of Transport</label>
                    <select id="transport_mode"name="transport_mode" className="form-control col-sm-7  mandatory-form-control" onChange={handleChange} value={inputs.transport_mode || ""} required>
                        <option value="own" >Own</option>
                        <option value="customer" >Customer</option>
                    </select><br/>

                    <label htmlFor="vehicle_id" className="form-group col-sm-4 text-right">Vehicle No</label>
                    {/* <select id="vehicle_id" name="vehicle_id" className="col-sm-7 mandatory-form-control" onChange={handleChange} value={inputs.vehicle_id || ""} required>
                      <option value="">Select Vehicle No</option>
                        {equipments.map((item) => (
                            <option key={item.value} value={item.value}>
                                {item.label}</option>
                        ))}
                    </select> */}
                       <div id ="vehicle_id" className="col-sm-7 mandatory-form-control">
                        <Select
                        options={equipments} // Pass the options
                        onChange={handleVehicleChange} // Handle selection
                        value={selectedVehicle} // Set the current selection
                        placeholder="Select a Vehicle..."
                        isSearchable // Enable search functionality
                      />
                      </div>
                    <input type="text" className="form-control col-sm-7 mandatory-form-control" id="customer_vehicle_no" value={inputs.customer_vehicle_no || ""}  name="customer_vehicle_no" onChange={handleChange} /><br />

                    <label htmlFor="operator_name" className="form-group col-sm-4 text-right">Plant Operator Name </label>
                    <Creatable
                    options={options}
                    onChange={handleCreatable}
                    value={selectedOperator}
                    placeholder="Type to search or create an option..."
                    className="form-control col-sm-7"
                  />  
                    <label htmlFor="schedule_qty" className="form-group col-sm-4 text-right">Schedule Qty</label>
                    <input type="text" className="form-control col-sm-7" id="schedule_qty" value={inputs.schedule_qty || ""} style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />

                    <label htmlFor="balance_qty" className="form-group col-sm-4 text-right">Balance Qty</label>
                    <input type="text" className="form-control col-sm-7" id="balance_qty"   value={inputs.balance_qty || ""} style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />

                    <label htmlFor="mixer_capacity" className="form-group col-sm-4 text-right">Mixer Capacity</label>
                    <input type="text" className="form-control col-sm-7" id="mixer_capacity"   step="any"  value={inputs.mixer_capacity || ""} style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />

                    <label htmlFor="cumulative_serial_no" className="form-group col-sm-4 text-right">Cumulative SI.No.|Qty </label>
                    <input required type="number"  id ="cumulative_serial_no" name="cumulative_serial_no" value={inputs.cumulative_serial_no||""} className="form-control col-sm-4" style={{ backgroundColor: "white", cursor: "not-allowed" }}/>
                    <input required type="number" min="0"  step="any"  value={inputs.cumulative_quantity || 0}  className="form-control col-sm-3" name="cumulative_quantity" id="cumulative_quantity"/>

                    <label htmlFor="user_remarks" className="form-group col-sm-4 text-right">Remarks,if any</label>
                    <input type="text" className="form-control col-sm-7" id="user_remarks" value={inputs.user_remarks}  name="user_remarks" onChange={handleChange}/><br />
                  
                  <div className="modal" tabIndex="-1" style={{ display: showModal2 ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-xl" style={{ height: "500px"}}>
                      <div className="modal-content">
                        <div className="modal-body display" style={{backgroundColor:"pink" }}>
                          <Row> 
                            <Col xl={6} lg={12} md={12}> 
                              <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel">
                                <h5 className='col-sm-11'><b>Weightment Details</b></h5><br/><br/>
                                <label htmlFor="weighment_slip_no" className="form-group col-sm-4 text-right">Weightment Slip No.</label>
                                <input type="number" id="weighment_slip_no"name="weighment_slip_no" onChange={handleChange} value={inputs.weighment_slip_no || ""}className="form-control col-sm-7"/>

                                <label htmlFor="weighment_slip_date" className="form-group col-sm-4 text-right">Weightment Date/Time</label>
                                <input type="date" className="form-control col-sm-3  mandatory-form-control" name="weighment_slip_date" value={inputs.weighment_slip_date || ""} id="weighment_slip_date" onChange={handleChange} />
                                <input type="time" id="weighment_slip_time"required name="weighment_slip_time" onChange={handleChange} value={inputs.weighment_slip_time || ""}className="form-control col-sm-4  mandatory-form-control"/>

                                <label htmlFor="gross_weight" className="form-group col-sm-4 text-right">Gross Weight</label>
                                <input type="number" id="gross_weight" name="gross_weight" onChange={handleChangeWeightDetails} value={inputs.gross_weight || 0} className="form-control col-sm-7"/>

                                <label htmlFor="tare_weight " className="form-group col-sm-4 text-right">Tare Weight</label>
                                <input type="number"  name="tare_weight" id="tare_weight" onChange={handleChangeWeightDetails} value={inputs.tare_weight || 0} className="form-control col-sm-7"/>

                                <label htmlFor="net_weight " className="form-group col-sm-4 text-right">Net Weight</label>
                                <input type="number" id="net_weight" name="net_weight" onChange={handleChangeWeightDetails}   style={{backgroundColor:"white", cursor: "not-allowed"}}  value={inputs.net_weight || 0} className="form-control col-sm-7"/>
                                <br/>      
                              </div>  
                            </Col>
                            <Col xl={6} lg={12} md={12}> 
                              <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3 form-control-panel" >
                                <h5 className='col-sm-11'><b>Vehicle Details</b></h5><br/><br/>
                                <label htmlFor="driver_name" className="form-group col-sm-4 text-right">Driver Name</label>
                                <Creatable
                                options={drivers}
                                onChange={handleDriver}
                                value={selectedDriver}
                                placeholder="Type to search or create an option..."
                                className="form-control col-sm-7"
                               /> 
                                <label htmlFor="driver_mobile_no"className="form-group col-sm-4 text-right  ">Mobile No</label>
                                <input type="text" id="driver_mobile_no" name="driver_mobile_no" 
                                onChange={handleChange}
                                value={inputs.driver_mobile_no|| ""} pattern="[0-9]{10}"  maxLength="10" className="form-control col-sm-7 " /><br/>
                                {error.driver_mobile_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.driver_mobile_no}</strong><br/></>)}

                                {/* <label htmlFor="opening_km" className="form-group col-sm-4 text-right">Opening KM</label>
                                <input  type="number"id="opening_km"  value={inputs.opening_km || 0} name="opening_km"   readOnly={true} className="form-control col-sm-7 mandatory-form-control" style={{backgroundColor:"white", cursor:"not-allowed"}}/>

                                <label htmlFor="opening_hm" className="form-group col-sm-4 text-right">Opening HM</label>
                                <input  type="number"id="opening_hm" name="opening_hm" className="form-control col-sm-7 mandatory-form-control"  value={inputs.opening_hm || 0} readOnly={true} style={{backgroundColor:"white", cursor:"not-allowed"}}/> */}

                                <label htmlFor="slump" className="form-group col-sm-4 text-right">Slump</label>
                                <input type="number" id="slump"name="slump" className="form-control col-sm-7" value={inputs.slump || 0} onChange={handleChange}/>
                              </div>
                            </Col>
                          </Row>                   
                        </div>
                        <div className="text-center" style={{backgroundColor:"pink" }}>
                            <button type="button" className="btn btn-primary" onClick={() => setShowModal2(false)}>Close</button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <label htmlFor="editing_dtl" className="form-group col-sm-4 text-right">Editing Dtls</label>
                  <textarea type="text" className="form-control col-sm-7" id="editing_dtl"  value={inputs.editing_dtl||""} name="editing_dtl" style={{ backgroundColor: "white", cursor: "not-allowed" }} />
                  
                  <label htmlFor="vehicle" className="form-group col-sm-4 text-right">Vehicle Details</label>
                  <EditSharpIcon color="#1565c0" onClick={handleVehicleDetailsIconClick}  
                  sx={{
                      cursor: 'pointer'
                  }} />
                </div>
              </Col>
            </Row>
          </div>
          
          <div className="footer text-center">
              {((superuser['mySuperuser']) || (category['myCategory'].includes("change_del_challan_master") === true)) &&(
                  <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Update</Button> 
              )}&nbsp;&nbsp;
            
              {((superuser['mySuperuser']) || (category['myCategory'].includes("view_del_challan_master") === true)|| (category['myCategory'].includes("change_del_challan_master") === true) || (category['myCategory'].includes("delete_del_challan_master") === true)) &&(  
                  <Button className="btn btn-twitter" type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>
              )}&nbsp;&nbsp;

              {((superuser['mySuperuser']) || (category['myCategory'].includes("add_del_challan_master") === true)) &&(
              <Button type="reset" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>New</Button>
              )}&nbsp;&nbsp;

              {!inputs.is_schedule_completed && 
                  (<>
                      <Button className="btn btn-delete"  type="button"style={{width:"80px",fontWeight:"bold"}}  onClick={onDeleteDeliveryChallan}>Delete</Button>&nbsp;&nbsp;
                      <Button className="btn btn-cancel"  type="button"style={{width:"100px",fontWeight:"bold"}} onClick={onCancelDeliveryChallan}>Cancel DC</Button>&nbsp;&nbsp;
                  </>
              )} 
              
              <Button className="btn btn-email"  type="button"style={{width:"80px",fontWeight:"bold"}}  onClick={onEmailDeliveryChallan}>Email</Button>&nbsp;&nbsp;
          </div>
        </form>
      </div>
    </>
  );
}
export default DeliveryChallanEdit;
