import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Button,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { useNavigate } from "react-router";
import Sidebar from "../../Sidebar";
import Swal from "sweetalert2";
import { useState } from "react";
import { Link, useParams, NavLink } from "react-router-dom";
import React, { Component, Fragment } from "react";
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
// import { MdExitToApp } from "react-icons/md";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from "react-cookie";
import {displayError,displayErrorToast,parseBoolean, parseBooleanToString} from '../../helpers';
import { checkForDuplicateCompany } from './CompanyUtils';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { getCompanyDetails, updateCompany, createCompany } from '../../services/CompanyServices';
import StatusDropDown from '../../components/StatusDropDown';
import { getIndianStates } from '../../utils/PicklistProvider';
import { getApiBaseUrl, getApiClient } from "../../services/serviceconfig";
import { getFileName } from '../../utils/FileNameUtils';

var imageUrl = getApiBaseUrl().slice(0, -4);
console.log(imageUrl+"imageUrl");
const axios = require("axios").default;
function Company() {
  const [cookies] = useCookies(["myToken"]);
  const navigate = useNavigate();
  const [inProgress, setInProgress] = useState(false);
  const [companyFormData, setCompanyFormData] = useState({is_batching_report_needed:'false'});

  const [plants,setPlants] = useState([])
  const [selectedFile, setSelectedFile] = useState({
    // cmpLogo: "",
    // cmpSeal: "",
  });
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(true);
  const { id } = useParams();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    try{
          if (name === "entity_name") {            //Company name must not be empty,otherwise showing error message  
          
            if (!value.trim()) {
              console.log("Company data is empty");
              setError((values) => ({
                ...values,
                [name]: "Company Name must not be empty...",
              }));
              setIsSubmitting(false);
            } else {
              setError((values) => ({
                ...values,
                [name]: '',
              }));
              setIsSubmitting(true);
            }
          
          } else if (name === "logo" || name === "seal" || name === "bg_image") {
            console.log(event.target.files[0].name + "event.target.files[0].name");
            console.log(name + "name");
            setSelectedFile((values) => ({
              ...values,
              [name]: event.target.files[0],
            }));
          }

          setCompanyFormData((values) => ({ ...values, [name]: event.target.value }));
        }

  catch (e)
  {
   displayErrorToast(e);
  }
}
const validate_items = (name,value,regular_expr,error_message) =>
{
   try{
     
        if(value.trim())            //If regex is statisfied condition is valid it accept ,otherwise it is Invalid                      
        {
            if(!regular_expr.test(value.trim()))
            {
                setError(values => ({...values, [name]: error_message}))
                setIsSubmitting(false); 
            }
            else
            {
                setError(values => ({...values, [name]: ''}))
                setIsSubmitting(true);
            }
        } else if (name === 'pan_no' || name === 'gst_no') {
          
          setError(values => ({...values, [name]: 'must not be empty...'}))
          setIsSubmitting(true);
        } else {
          setError(values => ({...values, [name]: ''}))
          setIsSubmitting(true);
        }
        setCompanyFormData(values => ({...values, [name]: value}))
    }
 
   catch (e)
      {
       displayErrorToast(e);
      }
 }  

  const handleSubmit = (event) => {
    event.preventDefault();
   var companyId = event.currentTarget.dataset.id;
    try{
        checkForDuplicateCompany(event.target.entity_name.value,cookies,companyId)
      .then(isNameExists => {
          
        if (isNameExists) {
          setError(values => ({...values, [event.target.entity_name.name]: 'Company name already exists.'}));
        } else {

          if (Object.values(error).every((x) => !x) && isSubmitting) {        //If the fields are correct details it is valid choosing yes or no option,otherwise it is invalid
            if (companyId) {
              handleUpdate();
            } else {
              handleCreate();
            }
          } else {
            Swal.fire("Please check * marked fields are filled and details given are valid...","","info" );
          }
        }
    }); 
  } catch (e) {
      displayErrorToast(e);
  } 
        
 } 
 
 const constructFormData = () => {

  const EMPTY_STRING = '';
  const dataArray = new FormData();
        if (selectedFile.logo) {
          dataArray.append("logo", selectedFile.logo);
        }
        if (selectedFile.bg_image) {
          dataArray.append("bg_image", selectedFile.bg_image);
        }
        if (selectedFile.seal) {
          dataArray.append("seal", selectedFile.seal);
        }
        dataArray.append("id",companyFormData.id); 
          dataArray.append("alias", companyFormData.alias);
          dataArray.append("entity_name", companyFormData.entity_name.toUpperCase());
          dataArray.append("address_1",companyFormData.address_1 || EMPTY_STRING);
          dataArray.append("address_2",companyFormData.address_2 || EMPTY_STRING);
          dataArray.append("address_3",companyFormData.address_3  || EMPTY_STRING);
          dataArray.append("pincode",companyFormData.pincode  || EMPTY_STRING);
          dataArray.append("state",companyFormData.state  || EMPTY_STRING);
          dataArray.append("phone_number",companyFormData.phone_number  || EMPTY_STRING);
          dataArray.append("mobile_number",companyFormData.mobile_number  || EMPTY_STRING);
          dataArray.append("email_id",companyFormData.email_id  || EMPTY_STRING);
          dataArray.append("web",companyFormData.web  || EMPTY_STRING);
          dataArray.append("commencement_dt",companyFormData.commencement_dt);
          dataArray.append("pan_no",companyFormData.pan_no.toUpperCase());
          dataArray.append("cin_no",companyFormData.cin_no || EMPTY_STRING);
          dataArray.append("gst_no",companyFormData.gst_no.toUpperCase());
          
           dataArray.append("cst_no",companyFormData.cst_no || EMPTY_STRING);
          dataArray.append("lut_no",companyFormData.lut_no || EMPTY_STRING);
          dataArray.append("tan_no",companyFormData.tan_no || EMPTY_STRING);
          dataArray.append("pf_no",companyFormData.pf_no || EMPTY_STRING);
          dataArray.append("esi_no",companyFormData.esi_no || EMPTY_STRING);
          dataArray.append("opening_dt",companyFormData.opening_dt);
          dataArray.append("closing_dt",companyFormData.closing_dt);
          dataArray.append("is_batching_report_needed",companyFormData.is_batching_report_needed);
          dataArray.append("status",parseBoolean(companyFormData.status)); 
          dataArray.append("user_remarks",companyFormData.user_remarks || EMPTY_STRING);
    return dataArray;
 }

  const handleCreate = () => {

    Swal.fire({title: 'Do you want to save?',  
      showCancelButton: true,  
      confirmButtonText: `Yes`,  
      cancelButtonText: `No`,
      }).then((result) => {  
        if (result.isConfirmed) 
        { 
          const dataArray = constructFormData();
          console.log(JSON.stringify(dataArray)+"dataArray")
          createCompany(cookies, dataArray)
          .then(function (response) {
            Swal.fire('Saved!', '', 'success') 
            Reload();
            //Cancel();
          }).catch(function (error) {
            console.log(error);
            displayError(error.response.data,'Save Failed');
          });
          setInProgress(false);
        }
        else if (result.isDismissed) 
        {    
          Swal.fire('Not saved', '', 'info')  
          
        }
      });
  }

  const handleUpdate = () => {
    Swal.fire({
      title: "Do you want to Update?",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      cancelButtonText: `No`,
       }).then((result) => {
        if (result.isConfirmed) {
           const dataArray = constructFormData();
         
       updateCompany(cookies,dataArray,id)
          .then(response => {
            Swal.fire("Updated!", "", "success");
            Reload();
        })
        .catch((error) => {
            console.log(error.response.data);
            displayError(error.response.data,"Update Failed");
    });
        // Swal.fire("Updated!", "", "success");
        // Reload();
      } else if (result.isDismissed) {
        Swal.fire("Not Updated", "", "info");
      }
    });
  }

  const Reload = () => {
    navigate("/CompanyTable");
  };

  const view = () => {
    navigate("/CompanyTable");
  };




  const Back = () => {
    navigate("/Home");
  };
 
  const Cancel = () => {
    setCompanyFormData(values => ({status: 'true'}));
  }

  React.useEffect(() => {
    if (id) {

          setInProgress(true);

          getCompanyDetails(cookies, id)
          .then((companyObject) => {

            // setCompanyFormData(values => ({...values, 
            //   id: companyObject.id,

            // }));
            setCompanyFormData({...companyObject, ['status']:String(companyObject.status)});
            setPlants(companyObject.plants)
            console.log(plants)
            // getPlantDetails(cookies, id)
            // .then((response) => {
              setInProgress(false);
            //   setPlants(response);
            })
            .catch((error) => {
              console.log(error);
              setInProgress(false);
            });
}
else{
  
  setCompanyFormData((values) => ({...values, status: 'true'}))
}
  }, []);
  
  const func1 = (arg1, arg2) => {

  };

  return (
    <>
        <Sidebar pageWrapId={"page-wrap"} outerContainerId={"outer-container"}/>
        <ErrorToast/>
        <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
          <form onSubmit={handleSubmit} data-id={companyFormData.id} encType="multipart/form-data">
            <div id="header">
              <h4 className="text font-weight-bold page-title"> {(id ? 'Update' : '')} Company Registration & Setup  </h4>
            </div>

            <FloatingControls tableLink="/CompanyTable" onCancel={Cancel} enableCancel={!id}/>
            <div className="container"> 
              <Row> 
              <Col xl={6} lg={12} md={12}>   
             
                      <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel">  
                      <h5 className='col-sm-11'><b>Company Details</b></h5><br/><br/>  
                              <label htmlFor="entity_name"className="form-group col-sm-4 text-right  ">Company Name </label>
                              <input type="text" id="entity_name" name="entity_name" onChange={handleChange} value={companyFormData.entity_name || ""} className="form-control col-sm-7 mandatory-form-control"  placeholder="Please fill the NAME field *" maxLength="100" required /><br/>
                              {error.entity_name && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.entity_name}</strong><br/></>)}
                              
                              <label htmlFor="alias"className="form-group col-sm-4 text-right  "> Alias</label>
                              <input type="text" id="alias" name="alias" onChange={handleChange} value={companyFormData.alias || ""}  className="form-control col-sm-7 "  placeholder="Please fill the Alias Field *" /><br/>
                              
                              <label htmlFor="address_1"className="form-group col-sm-4 text-right  "> Address</label>
                              <input type="text" id="address_1" name="address_1" onChange={handleChange} value={companyFormData.address_1 || ""} className="form-control col-sm-7  rounded " /><br/>
                              
                              <label htmlFor="address_2" className="form-group col-sm-4 text-right  "></label>
                              <input type="text" id="address_2"  name="address_2" onChange={handleChange} value={companyFormData.address_2 || ""} className="form-control col-sm-7 rounded "  /><br/>
                              
                              <label htmlFor="address_3"  className="form-group col-sm-4 text-right  "></label>
                              <input type="text" id="address_3" name="address_3" onChange={handleChange} value={companyFormData.address_3 || ""} className="form-control col-sm-7 rounded "  /><br/>
                              
                              <label htmlFor="pincode"className="form-group col-sm-4 text-right  ">Pin Code</label>
                              <input name="pincode" id="pincode" onChange={ (event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid Pincode")} maxLength = "6" value={companyFormData.pincode || ""} className="form-control col-sm-7" type = "text" /><br/>
                              {error.pincode && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.pincode}</strong><br/></>)}
                              
                              <label htmlFor="state"className="form-group col-sm-4 text-right  ">State</label>
                              <select id="state" name="state" className="browser-default custom-select form-control col-sm-7 mandatory-form-control" required onChange={handleChange} value={companyFormData.state || ""}>
                              <option value="">Select State</option>
                                    {
                                      getIndianStates()
                                      .map(indian_state => <option key={indian_state.value} value={indian_state.value}>{indian_state.name}</option>
                                      )
                                    }
                                  </select><br/><br/>
                                            
                              <label htmlFor="phone_number"className="form-group col-sm-4 text-right  ">Phone No</label>
                              <input  type="tel" id="phone_number" name="phone_number"onChange={handleChange} value={companyFormData.phone_number || ""} className="form-control col-sm-7" /><br/>
                              
                              <label htmlFor="mobile_number"className="form-group col-sm-4 text-right  ">Mobile No</label>
                              <input type="text" id="mobile_number" name="mobile_number" onChange={handleChange} value={companyFormData.mobile_number || ""} pattern="[0-9]{10}"  maxLength="10" className="form-control col-sm-7 " /><br/>

                              <label htmlFor="web"className="form-group col-sm-4 text-right">Web</label>
                              <input type="text" id="web" name="web" onChange={handleChange} value={companyFormData.web || ""}className="form-control col-sm-7" /><br/>
                              
                              <label htmlFor="email_id"className="form-group col-sm-4 text-right  ">Email id</label>
                              <input type="email" id="email_id" name="email_id"  onChange={ (event) => validate_items(event.target.name,event.target.value,/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,"invalid Emailid")} value={companyFormData.email_id|| ""} className="form-control col-sm-7 " /><br/>
                              {error.email_id && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.email_id}</strong><br/></>)}
                             
                              <label htmlFor="is_batching_report_needed"className="form-group col-sm-4 text-right ">Batching Report</label>
                          <select id="is_batching_report_needed" required name="is_batching_report_needed" onChange={handleChange} value={companyFormData.is_batching_report_needed || 'false'}className="browser-default custom-select col-sm-7 mandatory-form-control">
                              <option value=''>Please select</option>
                              <option value='true'>Yes</option>
                              <option value='false'>No</option>
                         </select> <br></br><br></br>

                          <h5 className='col-sm-11'><b>Accounting Dates</b></h5><br/> 

                            <label htmlFor="commencement_dt"className="form-group col-sm-4 text-right">Commencement date </label>
                            <input type="date" id="commencement_dt" name="commencement_dt" onChange={handleChange} value={companyFormData.commencement_dt|| ""} className="form-control col-sm-7 rounded mandatory-form-control" required maxLength="50"/><br/>
                            
                            <label htmlFor="opening_dt"className="form-group col-sm-4 text-right  "> Opening date </label>
                            <input type="date" id="opening_dt" name="opening_dt" onChange={handleChange} value={companyFormData.opening_dt || ""}className="form-control col-sm-7 rounded mandatory-form-control" required  maxLength="50"/><br/>
                            
                            <label htmlFor="closing_dt"className="form-group col-sm-4 text-right  ">Closing date </label>
                            <input type="date"  id="closing_dt"  name="closing_dt" onChange={handleChange} value={companyFormData.closing_dt || ""}className="form-control col-sm-7 rounded mandatory-form-control" required maxLength="50"/><br/>
                            <br></br>
                            
                      </div>
                  </Col>
                  <Col xl={6} lg={12} md={12}>
                  <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel " >   
                      {/* <div className="form-row table-bordered border-primary border p-2 mb-3" style={{backgroundColor:"rgba(68,158,228,0.9)",borderRadius:"10px",fontWeight:"bold"}}> */}
                      <h5 className='col-sm-11'><b>Company Statuatory</b></h5><br/>
                          <label htmlFor="pan_no"className="form-group col-sm-4 text-right  ">PAN No </label>
                          <input type="text" id="pan_no" required name="pan_no" onChange={ (event) => validate_items(event.target.name,event.target.value,/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/,"invalid PANNo")}  value={companyFormData.pan_no || ""}className="form-control col-sm-7 mandatory-form-control"  placeholder="Please fill the PAN field *"  /><br/>
                          {error.pan_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.pan_no}</strong><br/></>)}

                          <label htmlFor="cin_no"className="form-group col-sm-4 text-right  ">CIN No</label>
                          <input type="text" id="cin_no" name="cin_no"  onChange={handleChange}
                          // onChange={ (event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid CIN No")} 
                          value={companyFormData.cin_no || ""}className="form-control col-sm-7 "  /><br/>
                          {error.cin_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.cin_no}</strong><br/></>)}


                          <label htmlFor="gst_no"className="form-group col-sm-4 text-right  ">GST No </label>
                          <input type="text" id="gst_no" name="gst_no" onChange={ (event) => validate_items(event.target.name,event.target.value,/^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}$/,"invalid GSTNo")}  value={companyFormData.gst_no || ""}className="form-control col-sm-7 mandatory-form-control" required placeholder="Please fill the Gst field *" /><br/>
                          {error.gst_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.gst_no}</strong><br/></>)}
                          
                          <label htmlFor="lut_no"className="form-group col-sm-4 text-right  ">LUT No</label>
                          <input type="number" id="lut_no" name="lut_no" onChange={handleChange}
                          // onChange={ (event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid LUTNo")} 
                          value={companyFormData.lut_no|| ""}className="form-control col-sm-7 " /><br/>
                          {error.lut_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.lut_no}</strong><br/></>)}
                              
                          <label htmlFor="cst_no"className="form-group col-sm-4 text-right  ">CST No</label>
                          <input type="text" id="cst_no" name="cst_no"  onChange={handleChange}
                          // onChange={ (event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid CSTNo")}
                           value={companyFormData.cst_no || ""}className="form-control col-sm-7 "  /><br/>
                          {error.cst_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.cst_no}</strong><br/></>)}
                          
                          <label htmlFor="tan_no"className="form-group col-sm-4 text-right  ">TAN No</label>
                          <input type="text" id="tan_no" name="tan_no" onChange={handleChange} 
                          // onChange={ (event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid TANNo")} 
                          value={companyFormData.tan_no || ""}className="form-control col-sm-7 "  /><br/>
                          {error.tan_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.tan_no}</strong><br/></>)}

                          <label htmlFor="pf_no"className="form-group col-sm-4 text-right  ">PF No</label>
                          <input type="text" id="pf_no" name="pf_no"  onChange={handleChange} 
                          // onChange={ (event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid PFNo")}  
                          value={companyFormData.pf_no || ""} className="form-control col-sm-7 "  /><br/>
                          {error.pf_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.pf_no}</strong><br/></>)}

                          <label htmlFor="esi_no"  className="form-group col-sm-4 text-right  ">ESI No</label>
                          <input type="text" id="esi_no" name="esi_no" onChange={handleChange} 
                          // onChange={ (event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid ESINo")} 
                          value={companyFormData.esi_no || ""} className="form-control col-sm-7 " /><br/>
                          {error.esi_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.esi_no}</strong><br/></>)}

                           </div>

                           <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel" >
                      {/* <div className="form-row table-bordered border-primary border p-2 mb-3" style={{backgroundColor:"rgba(68,158,228,0.9)",borderRadius:"10px",fontWeight:"bold"}}> */}
                      <h5 className='col-sm-11'><b>Files Uploading Centre</b></h5><br></br>

                           <label htmlFor="logo"className="form-group col-sm-4 text-right">Logo</label>
                            <input type="file" id="logo" onChange={handleChange} name="logo" />
                            {companyFormData.id && (
                              <> 
                              <label className="form-group col-sm-4 text-right  ">Current Logo</label>
                              <a target="_blank" rel="noopener noreferrer" href={`${imageUrl}${companyFormData.logo}`}  style={{color:"black"}}className="form-group col-sm-7 ">{companyFormData.logo?getFileName(companyFormData.logo):''}</a>
                              </>
                           )}
             
             
                            <label htmlFor="bg_image"className="form-group col-sm-4 text-right">Background Image</label>
                            <input type="file" id="bg_image" onChange={handleChange} name="bg_image" />
                            {companyFormData.id && (
                              <> 
                              <label className="form-group col-sm-4 text-right  ">Current BG Image</label>
                              <a target="_blank" rel="noopener noreferrer" href={`${imageUrl}${companyFormData.bg_image}`}  style={{color:"black"}}className="form-group col-sm-7 ">{companyFormData.bg_image?getFileName(companyFormData.bg_image):''}</a>
                              </>
                           )}
                            <label htmlFor="seal"className="form-group col-sm-4 text-right">Seal</label>
                            <input type="file"id="seal" name="seal" onChange={handleChange} />
                            
                            {companyFormData.id && (
                            <>
                            <label className="form-group col-sm-4 text-right  ">Current Seal</label>
                            <a target="_blank" rel="noopener noreferrer" href={`${imageUrl}${companyFormData.seal}`}  style={{color:"black"}}className="form-group col-sm-7 ">{companyFormData.seal?getFileName(companyFormData.seal):''}</a>
                            </>)} 

</div>

                
                      <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel" >
                      {/* <div className="form-row table-bordered border-primary border p-2 mb-3" style={{backgroundColor:"rgba(68,158,228,0.9)",borderRadius:"10px",fontWeight:"bold"}}> */}
                      <h5 className='col-sm-11'><b>Company Status</b></h5><br/>

                      <StatusDropDown status={companyFormData.status} onChange={handleChange}/>

                          <label htmlFor="user_remarks" className="form-group col-sm-4 text-right">Remarks</label>
                          <input type="text" id="user_remarks" name="user_remarks"onChange={handleChange} value={companyFormData.user_remarks || ""} maxLength="1000" placeholder="IF ANY"  className="form-control  col-sm-7" /><br/><br/>
                          
                      </div>
                  </Col>
               </Row>             
          </div>
            <div className="footer text-center">
            <table border="0" cellPadding="5" style={{display: 'inline-table'}}>
                        <tbody>
                          <tr>
                            <td>
                            <Button  type="submit"  style={{width:"80px",fontWeight:"bold"}} className="btn btn-twitter">
                              {(id ? 'Update' : 'Save')}
                            </Button>
                            </td>
                            {(!id ? <td><Button type="button" className="btn btn-twitter"style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Cancel</Button></td> : '')}
                            <td>
                            <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>
                            </td>
                            <td>
                            <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                            </td>

                          </tr>
                        </tbody>                        
                      </table>
            </div>

            {
              id && 
              <div className="container">
              <Row>
              <Col xl={12} lg={24} md={24}>
           
                <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel" >
                    <div><b>No.of Plants Associated with this Company :{plants.length}</b></div>
                    <table border={3} cellPadding={5} cellSpacing={5} width={"100%"} align={"center"}>
                      <thead style={{ fontSize: '14px', color: 'white',backgroundColor: 'darkblue', textAlign: 'center' }}>
                      <tr>
                        
                        <td style={{fontWeight: "bold"}}>Plant Name</td>
                        <td style={{fontWeight: "bold"}}>Plant Address</td>
                        <td style={{fontWeight: "bold"}}>Plant Alias</td>
                      </tr>
                      </thead> 
                      <tbody>
                        {plants.map(cmp => (
                          <tr key={cmp.id} >
                            
                            <td>{cmp.name}</td>
                            <td>{cmp.address_1}</td>
                            <td>{cmp.alias}</td>
                            
                          </tr>
        
                        ))}


                      </tbody>
                    </table>
                </div>
              </Col>
              </Row>
            </div>
            }
            
          </form>
        </div>
    </>
  );
}

export default Company;
