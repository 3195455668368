import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllWorkSchedules, deleteWorkSchedule, } from "../../services/WorkScheduleServices";
import { displayError } from "../../helpers";

function WorkScheduleTable() {
    const InitialData = {
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true); 

    const columns = useMemo(
        () => [
            {
                fieldName: 'plant', 
                headerName: 'Plant'
            },
            {
                fieldName: 'schedule_no', 
                headerName: 'Job No'
            },
            {
                fieldName: "delivery_date",
                headerName: 'Delivery Date'
            },
            {
                fieldName: "delivery_time",
                headerName: 'Delivery Time'
            },
            {
                fieldName: "customer",
                headerName: "Customer"
            },
            {
                fieldName: "consignee",
                headerName: "Site"
            },
            {
                fieldName: "scheduled_qty",
                headerName: "Scheduled Qty"
            },
            {
                fieldName: "balance_qty",
                headerName: "Balance Qty"
            },
            {
                fieldName: "grade",
                headerName: "Grade"
            },
            {
                fieldName: "delivery_mode",
                headerName: "Mode Of Delivery"
            },
            {
                fieldName: "salesrep_alias",
                headerName: "CSM"
            },
            {
                fieldName: "concrete_structure",
                headerName: "Concrete Structure"
            },
            {
                fieldName: "status",
                headerName: "Status"
            },
            {
                fieldName: "status_2",
                headerName: "Schedule Status"
            },
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
            // {
            //     fieldName: "is_schedule_completed",
            //     headerName: "Is Schedule completed?"
            // },
                        // {
            //     fieldName: "schedule_date",
            //     headerName: 'Job Date'
            // },
            // {
            //     fieldName: "order_no",
            //     headerName: 'SalesOrder No'
            // },
            // {
            //     fieldName: "order_date",
            //     headerName: "SalesOrder Date"
            // },
             // {
            //     fieldName: "order_quantity",
            //     headerName: "Order Qty"
            // },
            // {
            //     fieldName: "supplied_qty",
            //     headerName: "Delivered Qty"
            // },
            // {
            //     fieldName: "accepted_qty",
            //     headerName: "Accepted Qty"
            // },
            // {
            //     fieldName: "pump",
            //     headerName: "Pump No"
            // },
        ],
        [],);

    const onEditWorkSchedule = (workscheduleId, event) => {
       navigate(`/WorkScheduleEdit/${workscheduleId}/`);
    };

    const onDeleteWorkSchedule = (workscheduleId, event) => {
        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 

            setIsLoading(true);
            deleteWorkSchedule(cookies,workscheduleId)
            .then(response => {
                console.log(`workschedule with id ${workscheduleId} deleted`);
                setRefreshKey(oldKey => oldKey +1);
                Swal.fire('Deleted Successfully!', '', 'success');
            })
            .catch(error => {
                setRefreshKey(oldKey => oldKey +1);
                console.log(error.response.data);
                displayError(error.response.data,'Delete Failed');
            }); 
            
            
          } else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(ws => {
                const csvRow = {
                    plant:ws.plant,
                    schedule_no: ws.schedule_no,
                    schedule_date: ws.schedule_date,
                    scheduled_qty:ws.scheduled_qty,
                    order_quantity:ws.order_quantity,
                    supplied_qty:ws.supplied_qty,
                    accepted_qty:ws.accepted_qty,
                    balance_qty:ws.balance_qty,
                    delivery_mode:ws.delivery_mode.toUpperCase(),
                    order_no:ws.order_no,
                    order_date:ws.order_date,
                    consignee:ws.consignee,
                    pump:ws.pump,
                    customer:ws.customer,
                    status:ws.status,
                    salesrep:ws.salesrep
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of WorkShedule Form',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(ws => ({
                plant:ws.plant,
                schedule_no: ws.schedule_no,
                schedule_date: ws.schedule_date,
                scheduled_qty:ws.scheduled_qty,
                supplied_qty:ws.supplied_qty,
                accepted_qty:ws.accepted_qty,
                order_quantity:ws.order_quantity,
                balance_qty:ws.balance_qty,
                delivery_mode:ws.delivery_mode.toUpperCase(),
                order_no:ws.order_no,
                order_date:ws.order_date,
                customer:ws.customer,
                consignee:ws.consignee,
                pump:ws.pump,
                status:ws.status,
                salesrep:ws.salesrep
            })),
            'WorkScheduleList.pdf'
        );
    };

    useEffect(() => {
        setIsLoading(true);
        getAllWorkSchedules(cookies)
        .then( response => {
            const filter_criteria=response.filter_criteria;
            console.log(JSON.stringify(filter_criteria)+"filter_criteria")
            const work_schedule_list= response.work_schedule_list.filter(obj=>filter_criteria.show_workschedules.includes(obj.status)&&filter_criteria.workschedule_status.includes(String(obj.status_2)))
            console.log(JSON.stringify(work_schedule_list)+"work_schedule_list");
    
            const tableData = work_schedule_list
            .map(ws => ({
                id :ws.id,
                plant:ws.plant.alias_name,
                schedule_no: ws.schedule_no,
                schedule_date: ws.schedule_date,
                scheduled_qty:ws.scheduled_qty,
                accepted_qty:ws.accepted_qty?ws.accepted_qty:0,
                supplied_qty:ws.supplied_qty?ws.supplied_qty:0,
                order_quantity:ws.sales_order_detail.order_quantity,
                balance_qty:ws.balance_qty,
                delivery_mode:ws.delivery_mode.toUpperCase(),
                order_no:ws.sales_order_detail.order_no,
                order_date:ws.sales_order_detail.order_date,
                delivery_date:ws.delivery_date,
                delivery_time:ws.delivery_time,
                consignee:ws.sales_order_detail.consignee.project_name,
                customer:ws.sales_order_detail.consignee.customer.name,
                grade:ws.sales_order_detail.grade,
                concrete_structure:ws.sales_order_detail.concrete_structure,
                salesrep:ws.sales_order_detail.consignee.customer.salesrep.name,
                salesrep_alias:ws.sales_order_detail.consignee.customer.salesrep.alias,
                pump:ws.pump.name,
                status:ws.status,
                status_2:ws.status_2?'Active':'Inactive',
                is_schedule_completed:ws.is_schedule_completed?'Yes':'No',
                hide_row_edit:ws.is_schedule_completed?'Yes':'No'
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>LIST OF SCHEDULES</h3>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='LIST OF SCHEDULES'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditWorkSchedule}
                            onRowDelete={onDeleteWorkSchedule}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                            hideDelete={true}
                        />
                </div>
                </div> 


            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/WorkSchedule")}}>Back</button>
        </div>

        
    );

}



export default WorkScheduleTable;