import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import "../../App.css"
import { getAllCompanies,deleteCompany} from "../../services/CompanyServices";
import { displayError } from "../../helpers";
function CompanyTable() {

    const InitialData = {
        total_pages: 0,
        records: [],
      };
      const [isLoading, setIsLoading] = useState(true);  
      const [refreshKey, setRefreshKey] = useState(0);
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [cookies] = useCookies(['myToken']); 
    const columns = useMemo(
        () => [
            {
                fieldName: 'entity_name', 
                headerName: 'Company Name'
            },
            {
                fieldName: 'alias',
                headerName: 'Alias',
               
            },
            {
                fieldName: "address",
                headerName: 'Address'
            },
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
        ],
        [],);

    const onEditCompany = (companyId, event) => {
        //const companyId = event.target.id;
        console.log(`Company with ${companyId} edited`);
        navigate(`/CompanyEdit/${companyId}/`);
    };

    const onDeleteCompany = (companyId, event) => {

        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 
            setIsLoading(true);
            deleteCompany(cookies, companyId)
            .then(response => {
                console.log(`Company with id ${companyId} deleted`);
                setRefreshKey(oldKey => oldKey +1);
                Swal.fire('Deleted Successfully!', '', 'success');
            })
            .catch(error => {
                setRefreshKey(oldKey => oldKey +1);
                console.log(error.response.data);
                displayError(error.response.data,'Delete Failed');
            }); 
          } else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                    entity_name: r.entity_name,
                    alias: r.alias,
                    address: r.address}
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'Companies List',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({entity_name: r.entity_name, alias: r.alias, address: r.address})),
            'Companies.pdf'
        );
    };

    useEffect(() => {
        getAllCompanies(cookies)
        .then(data => {
            

            const tableData = data.company_data.map(company => ({
                entity_name: company.entity_name,
                alias: company.alias,
                address: `${company.address_1},\n${company.address_2},\n${company.address_3}`,
                id: company.id,    
            }));
            setData({
            total: data.total,
            records: tableData
            });
            setIsLoading(false);
        }); 
    }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
                <h3 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Companies</h3>
                <br/>
                <div className="container item-list-table-container">
                    <div className="table-responsive">
        
                        <div className="inline-block">
                            <ReactTableWrapper
                                title='Companies List'
                                columns={columns}
                                data={data.records}
                                onRowEdit={onEditCompany}
                                onRowDelete={onDeleteCompany}
                                onCSVExport={handleCSVExport}
                                onPDFExport={handlePDFExport}
                                isLoading={isLoading}
                            />
                        </div>
                    </div> 
                </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/Company")}}>Back</button>
            </div>
        </div>
        );
}

export default CompanyTable;