import {getAllGangs} from '../../services/GangServices'

export function checkForDuplicateGang(name,value, cookies, existingId) {
    
    
    let nameCheck = new Promise((resolve, reject) => {
      getAllGangs(cookies,value)
      .then((data) => {

        let isUserExists = false;
        data.gang_list.forEach(gang =>{  
             //Same Gang Name mentioned it denoted already exist.
          if((gang.entity_name.toUpperCase())=== value.trim().toUpperCase()
          && parseInt(existingId)!==gang.id){
            console.log(`gang exits with the name - ${value}`)
            isUserExists =true;
          }
        });
        resolve(isUserExists);
      });
    });
    return nameCheck;
  }