import React from 'react';
import { useState, useEffect ,useMemo} from "react";
import Swal from "sweetalert2";
import { Row, Col, Button } from "reactstrap";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { useCookies } from 'react-cookie';
import TableRowsCubeTestReport from "./TableRowsCubeTestReport";
import SummaryIcon from '../../components/SummaryIcon';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { getUserAllowedPlants } from '../../services/PlantServices';
import { getCubeTestReportNumber,createCubeTestReport } from '../../services/CubeTestReportServices';
import { getDeliveryChallanListForCTR } from '../../services/DeliveryChallanServices';
import NumberSetting from '../../components/NumberSetting';
import QuestionIcon from '../../components/QuestionIcon';
import TableRowsAge from "../../forms/qualitycontrol/TableRowsAge";
import { displayError } from '../../helpers';
import { getDisplayDate } from '../../utils/DateUtils';
import ReactTablePopUpSubmitWrapper from "../../components/ReactTablePopUpSubmitWrapper";
import { showDcGroupPop } from './CubeTestReportUtils';
import { getCurrentDate } from '../../utils/DateUtils';

function CubeTestReport() 
{
  const [category] = useCookies(['myCategory']);
  const [superuser] = useCookies(['mySuperuser']);
  const [cookies] = useCookies(['myToken']);
  const [inputs, setInputs] = useState({});
  const [inProgress, setInProgress] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dcGroup,setDcGroup]=useState([]);
  const [SelectedDcGroup,setSelectedDcGroup]=useState({});
  const [showDcModal, setShowDcModal] = useState(false);
  const [plants, setPlants] = React.useState([]);
  const [csrRows, setCsrRows] = useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);  
  
  const Help = () => {
      if(inputs.plant_id && inputs.casting_dt){
        getDeliveryChallanListForCTR(cookies,inputs.plant_id,getDisplayDate(inputs.casting_dt))
        .then( response => {
          const tableData = response.delivery_challan_list.map((item,index) => ({...item,id:index+1}));
          setDcGroup(tableData)
          setIsLoading(false);
          setShowDcModal(true)
        })
        .catch((error) =>
        {
          setIsLoading(false);
          displayError(error.response.data," Failed");
        })
      }
      else{
        Swal.fire('Please choose plant and dc date.', '', 'info')
      }
  }

  React.useEffect(() => {
    setInputs(values => ({...values,['csr_date']: getCurrentDate()}))
  }, []);

  const columns =useMemo(
    () => [
        {
            fieldName: "id",
            headerName: "ROW_ACTIONS",
            size:40
        },
        {
            fieldName: 'delivery_challan_date',
            headerName: 'DC Date',
            size:50
        },
        {
            fieldName: "customer_name",
            headerName: 'Customer',
            size:180
        },
        {
            fieldName: "project_name",
            headerName: 'Site',
            size:180
        },
        {
            fieldName: 'product_name',
            headerName: 'Grade',
            size:50
        },
        {
          fieldName: 'total_dc_qty',
          headerName: 'Dc Qty',
          size:50
        },
      ],
  [],);

  const CloseDc = () => {
    setShowDcModal(false);
  }

  const Close = () => {
    setShowModal(false);
    // console.log(JSON.stringify(rowsData)+"rowsData Close");
    // console.log(inputs.casting_dt+"inputs.casting_dt");
    const newCsrRows = [];
    let serialNumber = 1; // Start serial number
    rowsData.forEach((row) => {
      const { age, no_of_moulds, id } = row;
      const moulds = parseInt(no_of_moulds, 10);
      const testingDate = new Date(inputs.casting_dt);
      // console.log(testingDate.getDate()+"testingDate"+"age"+Number(age)+typeof(age))
      testingDate.setDate(testingDate.getDate() + Number(age));
      
      for (let i = 1; i <= moulds; i++) {
        newCsrRows.push({
          sl_no:serialNumber,
          age: parseInt(age, 10),
          cube_id: `${id}`,
          testing_date: testingDate.toISOString().split("T")[0],
          no_of_rows:moulds,
          act_weight:0,
          act_load:0,
          cus_weight:0,
          cus_load:0,
          act_strength:0,
          ref_cube_strength:0,
          act_difference:0,
          cus_strength:0,
          cus_difference:0
        });
        serialNumber++; // Increment the serial number for each cube_id
      }
    });
    setCsrRows(newCsrRows);
    setInputs(values => ({ ...values, ['no_of_moulds']: newCsrRows.length }))
  }
      
  React.useEffect(() => {
    //Load data for Plant dropdown
    getUserAllowedPlants(cookies)
    .then (
      plantList => {
        const plants = plantList.plant_list.filter(obj => obj.status).map(
            plant => {
                return { value: plant.id, label: plant.plant_alias }
            }
        );
        setPlants(plants);
      }
    )
  }, []);  

  const navigate = useNavigate();
  const deleteTableRows = (index)=>{
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  }

  const handletableChange = (index, evnt)=>{
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  }

  const handleChangeDcGroupDetails = (value,event) =>{
    const dcGroupId = value;
    const propertyName = 'dc_group_id';
    if (dcGroupId) {
      setInputs(values =>({...values,[propertyName]:dcGroupId}));
      const selected_dc_group = dcGroup.filter(obj => obj.id == dcGroupId)[0];
      console.log(JSON.stringify(selected_dc_group)+"selected_dc_group")
      setSelectedDcGroup(selected_dc_group);
      setIsLoading(true);
    }
    setShowDcModal(false);
  }
  
  const addTableRows = () => {
    const rowsInput={
        age:0,
        no_of_moulds:0,
        id:''
    }
    setRowsData([...rowsData, rowsInput])
  }

  const handleChangeCsr = (index, evnt)=>{
    const { name, value } = evnt.target;
    const CsrRowsInput = [...csrRows];
    CsrRowsInput[index][name] = value;
    if (name==='act_load' || name === 'cus_load'){
      const strength=(value/22.5).toFixed(2);
      const age =parseInt(CsrRowsInput[index]['age']);
      const no_of_rows =CsrRowsInput[index]['no_of_rows'];
      var total = parseFloat(strength);
      var per_csr = age/(4.7+(0.832*age))*100;
      var grade =SelectedDcGroup.grade_number;
      // console.log(grade+"grade");
      var cube_strength = (parseInt(grade)*per_csr/100).toFixed(2);
      CsrRowsInput[index]['ref_cube_strength']=cube_strength;
      CsrRowsInput.map((row,row_index)=>{
        // console.log(JSON.stringify(row)+"row"+row_index+"row_index");
        if((parseInt(row['age']) === age) && (row_index != index)){
          if (name==='act_load'){
          total+= parseFloat(row['act_strength'])
          }
          else{
            total+= parseFloat(row['cus_strength'])
          }
        }
      })
      var average = (total/no_of_rows).toFixed(2);
      CsrRowsInput.map((row_1,row_index_1)=>{
        // console.log(JSON.stringify(row)+"row"+row_index+"row_index");
        if(parseInt(row_1['age']) === age){
          if (name==='act_load'){
            CsrRowsInput[index]['act_strength'] = strength;
            CsrRowsInput[row_index_1]['act_average'] = average;
            CsrRowsInput[row_index_1]['act_difference']= (parseFloat(average)-CsrRowsInput[row_index_1]['act_strength']).toFixed(2);
            CsrRowsInput[index]['cus_load'] = value;
            CsrRowsInput[index]['cus_strength'] = strength;
            CsrRowsInput[row_index_1]['cus_average'] = average;
            CsrRowsInput[row_index_1]['cus_difference']= (parseFloat(average)-CsrRowsInput[row_index_1]['act_strength']).toFixed(2);
            if(parseFloat(strength)>=parseFloat(cube_strength)){
              CsrRowsInput[index]['act_strength_result']= ''
              CsrRowsInput[index]['cus_strength_result']= ''
            }
            else{
              CsrRowsInput[index]['act_strength_result']= ''
              CsrRowsInput[index]['cus_strength_result']= ''
            }
            if(parseFloat(average)>=parseFloat(cube_strength)){
              CsrRowsInput[row_index_1]['act_result']= ''
              CsrRowsInput[row_index_1]['cus_result']= ''
            }
            else{
              CsrRowsInput[row_index_1]['act_result']= ''
              CsrRowsInput[row_index_1]['cus_result']= ''
            }
        }
        else{
          CsrRowsInput[index]['cus_strength'] = strength;
          CsrRowsInput[row_index_1]['cus_average'] = average;
          CsrRowsInput[row_index_1]['cus_difference']= (parseFloat(average)-CsrRowsInput[row_index_1]['cus_strength']).toFixed(2);
          if(parseFloat(strength)>=parseFloat(cube_strength)){
            CsrRowsInput[index]['cus_strength_result']= ''
          }
          else{
            CsrRowsInput[index]['cus_strength_result']= ''
          }
          if(parseFloat(average)>=parseFloat(cube_strength)){
            CsrRowsInput[row_index_1]['cus_result']= ''
          }
          else{
            CsrRowsInput[row_index_1]['cus_result']= ''
          }
        }
        }
      })
      // console.log(total+"total");
    }
    else if(name==='act_weight'){
      CsrRowsInput[index]['cus_weight'] = value;
    }
    // console.log(JSON.stringify(CsrRowsInput)+"CsrRowsInput");
    setCsrRows(CsrRowsInput);
  }

  const handleSubmit=(event)=> {
    event.preventDefault();
    console.log(JSON.stringify(inputs)+"inputs submit");
    console.log(JSON.stringify(csrRows)+csrRows.length+"csrRows");
    if(inputs.no_of_moulds > 0){
      Swal.fire({title: 'Do you want to save?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 
            console.log("can submit");
            createCubeTestReport(cookies, {
              plant_id:inputs.plant_id,
              customer_id:SelectedDcGroup.customer_id,
              consignee_id:SelectedDcGroup.consignee_id,
              report_no:inputs.csr_no,
              report_date:getDisplayDate(inputs.csr_date),
              prefix:inputs.prefix,
              casting_dt:getDisplayDate(inputs.casting_dt),
              technician_name:inputs.technician_name?inputs.technician_name:'',
              cement_type:inputs.cement_type?inputs.cement_type:'',
              cured_place:inputs.cured_place?inputs.cured_place:'',
              cement_content:inputs.cement_content?inputs.cement_content:'',
              grade:SelectedDcGroup.product_id,
              no_of_moulds:inputs.no_of_moulds?parseInt(inputs.no_of_moulds):0,
              user_remarks:inputs.user_remarks,
              dc_ids_list:SelectedDcGroup.dc_ids_list,
              supplied_qty:SelectedDcGroup.total_dc_qty,
              report_list:csrRows.map(q=>({
                age:parseInt(q.age),
                age_date:getDisplayDate(q.testing_date),
                sample_id:q.cube_id,
                act_weight:parseFloat(q.act_weight)?parseFloat(q.act_weight):0,
                act_load:parseFloat(q.act_load)?parseFloat(q.act_load):0,
                act_strength:parseFloat(q.act_strength)?parseFloat(q.act_strength):0,
                act_average:parseFloat(q.act_average)?parseFloat(q.act_average):0,
                act_difference:parseFloat(q.act_difference)?parseFloat(q.act_difference):0,
                act_result:(q.act_result)?(q.act_result):0,
                cus_weight:parseFloat(q.cus_weight)?parseFloat(q.cus_weight):0,
                cus_load:parseFloat(q.cus_load)?parseFloat(q.cus_load):0,
                cus_strength:parseFloat(q.cus_strength)?parseFloat(q.cus_strength):0,
                cus_average:parseFloat(q.cus_average)?parseFloat(q.cus_average):0,
                cus_difference:parseFloat(q.cus_difference)?parseFloat(q.cus_difference):0,
                cus_result:(q.cus_result),
                user_remarks:q.remarks_dtl,
              }))
            })
            .then(response => {
                Swal.fire("Saved!", "", "success");
                Reload();
            }).catch((error) => {
                displayError(error.response.data,"Saved Failed");
            });
          } 
          else if (result.isDismissed) 
          {    
            Swal.fire('Not saved', '', 'info')  
          }
        });
    }
    else{
      Swal.fire('No of moulds must be greater than 0', '', 'info')  
    }
  }
        
  const Reload = () => {
    window.location.reload();
  }
    
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    console.log(name+"name"+value+"value")
    setInputs(values => ({ ...values, [name]: value }))
    if(name === 'plant_id' && value){
      getCubeTestReportNumber(cookies,value)
      .then(response => {
        setIsLoading(false);
        setInputs(values => ({...values,['csr_num_prefix']:response.prefix+response.cube_test_report_no,['csr_no']: response.cube_test_report_no,['prefix']:response.prefix}));
      })
      .catch((error) =>
      {
        setIsLoading(false);
        displayError(error.response.data," Failed");
      })
    } 
    else if (name === 'plant_id' && !value) {
      setInputs(values => ({...values, ['csr_no']: ''}));
    }
  }
  
  const Cancel = () => {
    setInputs({})
    setRowsData([])
    setDcGroup([])
    setSelectedDcGroup([])
  }
   
  const view = () => {
    navigate('/CubeTestReportTable')
  } 

  const Back = () => {
    navigate('/Home')
  }
      
  const showDcDetails = () => {
    if (SelectedDcGroup.id) {
      showDcGroupPop(SelectedDcGroup);
    }
  }
   
  const addAge=()=>{
    // console.log(inputs.casting_dt && SelectedDcGroup.grade_number+"inputs.casting_dt && SelectedDcGroup addAge")
    if(inputs.casting_dt && SelectedDcGroup.grade_number){
      setShowModal(true);
    }
    else{
      Swal.fire('Please choose casting date and Dc.', '', 'info')
    }
  }
      
  const handleDcDetails = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if(name === "creation_date"){
        setInputs(values => ({...values, 'csr_date': event.target.value}))
    } 
    else {
        setInputs(values => ({...values, [name]: event.target.value}))
    }
  }
      
  const updateCsrNumberAndPrefix = (prefix, serial_no) => {
    setInputs(values => ({...values,
        ['csr_num_prefix']:(prefix+serial_no),
        ['csr_no']:serial_no,
        ['prefix']:prefix}));
  }

  return (
        <>
          <div id="outer-container" className="App" >
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <LoadingOverlay inProgress={inProgress}/>
            <div id="page-wrap">
              <form onSubmit={handleSubmit} >
                <div id="header">
                  <h3 className="text font-weight-bold page-title">COMPRESSIVE STRENGTH REPORT </h3>
                </div>
                
                <FloatingControls tableLink="/CubeTestReportTable" onCancel={Cancel} enableCancel={true}/>
                <div className="container">
                    <Row>
                      <Col xl={6} lg={12} md={12}>
                        <div className="form-row table-bordered  shadow p-2 my-2 border-secondary p-2 mb-3  form-control-panel " >
                          <label htmlFor="plant_id" className="form-group col-sm-3 text-right">Plant Location</label>
                          <select id="plant_id" name="plant_id" className="form-control col-sm-7  browser-default custom-select   mandatory-form-control" required onChange={handleChange} value={inputs.plant_id || ""}>
                            <option value="">Select Plant</option>
                            {plants.map((item) => (
                              <option key={item.value} value={item.value}>
                                                  {item.label}</option>
                            ))}
                          </select><br />
                        
                          <label htmlFor="casting_dt" className="form-group col-sm-3 text-right">Casting Date</label>
                          <input id="casting_dt" type="date" className="form-control col-sm-7  mandatory-form-control" name="casting_dt" required onChange={handleChange} value={inputs.casting_dt || ""}/><br/>
                    
                          <QuestionIcon onClickHandler={Help}/>
                          <SummaryIcon onClickHandler={showDcDetails}/>   
                    
                          <div className="modal" tabIndex="-1" style={{ display: showDcModal ? 'block' : 'none' }}>
                            <div className="modal-dialog modal-xl" style={{height: "500px"}} >
                              <div className="modal-content">
                                  <div className="modal-body display">
                                    <div className="container item-list-table-container">
                                      <ReactTablePopUpSubmitWrapper
                                          title='List of Group Form'
                                          columns={columns}
                                          data={dcGroup}
                                          onRowSubmit={handleChangeDcGroupDetails}
                                          isLoading={isLoading}
                                          onClose = {CloseDc}
                                      />
                                    </div> 
                                  </div>
                              </div>
                            </div>
                          </div>      

                          <label htmlFor="cement_type" className="form-group col-sm-3 text-right">Cement Type</label>
                          <input type="text" className="form-control col-sm-7"  id="cement_type" name="cement_type"  onChange={handleChange} value={inputs.cement_type || ""}  /><br />
                        
                          <label htmlFor="cement_content" className="form-group col-sm-3 text-right">Cem Content</label>
                          <input type="text" className="form-control col-sm-7"   id="cement_content" name="cement_content"   onChange={handleChange} value={inputs.cement_content || ""} /><br/>
                          
                          <label htmlFor="technician_name" className="form-group col-sm-3 text-right">Technician Name</label>
                          <input type="text" className="form-control col-sm-7" name="technician_name" id="technician_name"  onChange={handleChange} value={inputs.technician_name || ""} /><br />
                        </div>
                      </Col>
                  
                      <Col xl={6} lg={12} md={12}>
                        <div className="form-row table-bordered shadow p-2 my-2  border-secondary p-2 mb-3 form-control-panel" >
                          <NumberSetting 
                            handleDateChange={handleDcDetails} 
                            serial_no={inputs.csr_no} 
                            creation_date={inputs.csr_date}
                            creation_time={inputs.csr_time}
                            prefix={inputs.prefix}
                            company_id={inputs.plant_id}
                            voucher_type={"compressive_strength_report"}
                            handleNumberAndPrefixUpdate={updateCsrNumberAndPrefix}
                            cookies={cookies}
                            serial_no_title={'Report No'}
                            hideTime={true}/>
                            
                          <label htmlFor="no_of_moulds" className="form-group col-sm-4 text-right">No.of Moulds Casted</label>
                          <input type="text" className="form-control col-sm-7"  id="no_of_moulds"  readOnly value = {inputs.no_of_moulds||0} style={{ backgroundColor: "white", cursor: "not-allowed" }} /><br />
      
                          <label htmlFor="cured_place" className="form-group col-sm-4 text-right">Mould Casting Area</label>
                          <select id="cured_place" name="cured_place"  required onChange={handleChange} value={inputs.cured_place} className="form-control  col-sm-7 mandatory-form-control browser-default custom-select"  >
                            <option value=""></option>
                            <option value="plant">Plant</option>
                            <option value="site">Site</option>
                          </select>
                          <br/><br/>
                        
                          <label htmlFor="Remarks_hdr" className="form-group col-sm-4 text-right"></label>  
                          <button type="button" onClick={addAge}  style={{ background: 'none',
                              color: 'blue',
                              border: 'none',
                              padding: '0',
                              cursor: 'pointer',
                              textDecoration: 'underline' }}>
                          AGE FOR REPORT
                          </button>
                          
                          <div className="modal" tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }}>
                            <div className="modal-dialog modal-xl" style={{height: "500px",width:"500px"}} >
                              <div className="modal-content">
                                  <div className="modal-body display">
                                    <div className="container item-list-table-container">
                                      <table className="table order-list" id="myTable"  style={{textAlign:"center",borderRadius:"10px"}}>
                                        <thead>
                                          <tr>
                                            <th><button type="button" className="btn btn-success" onClick={addTableRows} >+</button></th>
                                            <th>Age</th>
                                            <th>No of moulds</th>
                                            <th>Id</th>
                                          </tr>
                                        </thead>
                                        <tbody >
                                            <TableRowsAge rowsData={rowsData} deleteTableRows={deleteTableRows} handletableChange={handletableChange} />
                                        </tbody>
                                      </table>
                                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={Close}>Close</button> 
                                    </div> 
                                  </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    <div className="form-row  shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
                      <table className="table-bordered table-responsive searchTable table order-list" id="myTable" style={{textAlign:"center",borderRadius:"10px",backgroundColor:"RGB(188,232,253)"}}>
                        <thead style={{backgroundColor:"#bce8fd"}}>
                          <tr>
                              <th   style={{verticalAlign:"middle"}}>Sl No</th>
                              <th  style={{verticalAlign:"middle"}}>Cube Id</th>
                              <th  style={{verticalAlign:"middle"}}>Age</th>
                              <th  style={{verticalAlign:"middle"}}>Testing Date</th>
                              <th  style={{verticalAlign:"middle"}}>Weight(gms)</th>
                              <th  style={{verticalAlign:"middle"}}>Dimension(mm)</th>
                              <th  style={{verticalAlign:"middle"}}>Area mm2</th>
                              <th  style={{verticalAlign:"middle",display:"None"}}>Reference Value</th>
                              <th  style={{verticalAlign:"middle"}}>Max Load(kn)</th>
                              <th  style={{verticalAlign:"middle"}}>Strength(mpa)</th>
                              <th  style={{verticalAlign:"middle"}}>Average Strength(mpa)</th>
                              <th  style={{verticalAlign:"middle",display:"None"}}>Difference</th>
                              <th  style={{verticalAlign:"middle"}}>Customer Weight(gms)</th>
                              <th  style={{verticalAlign:"middle"}}>Customer Max Load(kn)</th>
                              <th  style={{verticalAlign:"middle"}}>Customer Strength(mpa)</th>
                              <th  style={{verticalAlign:"middle"}}>Customer Average Strength(mpa)</th>
                              <th  style={{verticalAlign:"middle",display:"None"}}>Customer Difference</th>
                              <th  style={{verticalAlign:"middle"}}>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          <TableRowsCubeTestReport csrRows={csrRows}  handleChangeCsr={handleChangeCsr}   />
                        </tbody>
                      </table>
                    </div> 

                    <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel" >
                      <label htmlFor="user_remarks" className="form-group col-sm-3 text-left">Remarks</label>
                      <textarea rows="2" cols="50" className="form-control col-sm-9" id="user_remarks" name="user_remarks" onChange={handleChange} value={inputs.user_remarks || ""}></textarea>
                    </div>  
                </div>
                    
                <div className="footer text-center">
                  {/* {((superuser['mySuperuser']) || (category['myCategory'][0].Is_cubetestreport_for_so_add === true)) &&( */}
                      <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Save</Button> 
                  {/* )}&nbsp;&nbsp; */}
                  {/* {((superuser['mySuperuser']) || (category['myCategory'][0].Is_cubetestreport_for_so_add === true)) &&( */}
                      <Button type="reset" className="btn btn-twitter"  style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Cancel</Button>
                  {/* )}&nbsp;&nbsp; */}
                  {/* {((superuser['mySuperuser']) || (category['myCategory'][0].Is_cubetestreport_for_so_view === true)|| (category['myCategory'][0].Is_cubetestreport_for_so_edit === true) || (category['myCategory'][0].Is_cubetestreport_for_so_delete === true)) &&(   */}
                      <Button className="btn btn-twitter"   type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>
                  {/* )}&nbsp;&nbsp; */}
                      <Button className="btn btn-twitter"   type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                </div>
              </form>
            </div>
          </div>
        </>
  );
}
export default CubeTestReport;
