import React from 'react';
import './App.css';
import { BrowserRouter, Redirect, Route,Link,NavLink, Routes, Navigate,
  Outlet} from 'react-router-dom';
import { useNavigate } from 'react-router';
import {  useState, useEffect } from 'react';
import Home from "./Home";
import CompanyForm from "./forms/admin/CompanyForm";
import CompanyTable from "./forms/admin/CompanyTable";
import CompanyEdit from "./forms/admin/CompanyForm";
import UnitForm from "./forms/admin/UnitForm";
import UnitTable from "./forms/admin/UnitTable";
import UnitEdit from "./forms/admin/UnitForm";
import Tax from "./forms/admin/Tax";
import TaxEdit from "./forms/admin/TaxEdit";
import Gang from "./forms/admin/Gang";
import GangEdit from "./forms/admin/GangEdit";
import Vendor from "./forms/purchase/Vendor";
import VendorTable from "./forms/purchase/VendorTable";
import VendorEdit from "./forms/purchase/VendorEdit";
import Product from "./forms/admin/Product";
import ProductEdit from "./forms/admin/ProductEdit";
import Permission from "./forms/admin/Permission";
import PermissionEdit from "./forms/admin/PermissionEdit";
import IpAddress from "./forms/admin/IpAddress";
import IpAddressEdit from "./forms/admin/IpAddressEdit";
import User from "./forms/admin/User";
import UserTable from "./forms/admin/UserTable";
import UserEdit from "./forms/admin/UserEdit";
import Login from "./Login";
import { CookiesProvider } from 'react-cookie';
import NumberSetting from "./forms/admin/NumberSetting";
import NumberSettingEdit from "./forms/admin/NumberSettingEdit";
import ApprovalSetting from "./forms/admin/ApprovalSetting";
import ApprovalSettingTable from "./forms/admin/ApprovalSettingTable";
import ApprovalSettingEdit from "./forms/admin/ApprovalSettingEdit";
import CustomerMain from "./forms/marketing/CustomerMain";
import CustomerMainEdit from "./forms/marketing/CustomerMainEdit";
import CustomerSite from "./forms/marketing/CustomerSite";
import CustomerSiteTable from "./forms/marketing/CustomerSiteTable";
import CustomerSiteEdit from "./forms/marketing/CustomerSiteEdit";
import SalesOrder from "./forms/marketing/SalesOrder";
import SalesOrderEdit from "./forms/marketing/SalesOrderEdit";
//import SOApproval from "./SOApproval";
import DesignMix from "./forms/qualitycontrol/DesignMix";
import DesignMixEdit from "./forms/qualitycontrol/DesignMixEdit";
import MoistureCorrection from "./forms/qualitycontrol/MoistureCorrection"
import MoistureCorrectionTable from "./forms/qualitycontrol/MoistureCorrectionTable";
import MoistureCorrectionEdit from "./forms/qualitycontrol/MoistureCorrectionEdit";
import CubeTestReport from "./forms/qualitycontrol/CubeTestReport";
import CubeTestReportEdit from "./forms/qualitycontrol/CubeTestReportEdit";
import CubeTestReportTable from "./forms/qualitycontrol/CubeTestReportTable";
import WorkSchedule from "./forms/Planning/WorkSchedule";
import WorkScheduleEdit from "./forms/Planning/WorkScheduleEdit";
import JobCompletion from "./forms/Planning/JobCompletion";
 import PumpClearance from "./forms/dispatch/PumpClearance";
import PumpClearanceEdit from "./forms/dispatch/PumpClearanceEdit";
import DeliveryChallan from "./forms/dispatch/DeliveryChallan";
import DeliveryChallanEdit from "./forms/dispatch/DeliveryChallanEdit";
import DeliveryChallanTable from "./forms/dispatch/DeliveryChallanTable";
import CheckIn from "./forms/gate/CheckIn";
import CheckInEdit from "./forms/gate/CheckInEdit";
import CheckOut from "./forms/gate/CheckOut";
import CheckOutTable from "./forms/gate/CheckOutTable";
import CheckOutEdit from "./forms/gate/CheckOutEdit";
import VehicleGatePass from "./forms/gate/VehicleGatePass";
import VehicleGatePassTable from "./forms/gate/VehicleGatePassTable";
import VehicleGatePassEdit from "./forms/gate/VehicleGatePassEdit";
import Equipment from "./forms/Transport/Equipment";
import EquipmentEdit from "./forms/Transport/EquipmentEdit";
import ReceiptVoucher from "./forms/accounts/ReceiptVoucher";
import ReceiptVoucherEdit from "./forms/accounts/ReceiptVoucherEdit";
import  ReceiptVoucherTable from "./forms/accounts/ReceiptVoucherTable";
import CreditNote from "./forms/accounts/CreditNote";
import CreditNoteEdit from "./forms/accounts/CreditNoteEdit";
import PurchaseOrder from "./forms/purchase/PurchaseOrder";
import PurchaseOrderEdit from "./forms/purchase/PurchaseOrderEdit";
import PurchaseOrderTable from "./forms/purchase/PurchaseOrderTable";
import PurchaseInvoice from "./forms/purchase/PurchaseInvoice";
import PurchaseInvoiceEdit from "./forms/purchase/PurchaseInvoiceEdit";
import Quotation from "./forms/marketing/Quotation";
import QuotationEdit from "./forms/marketing/QuotationEdit";
import QuotationTable from "./forms/marketing/QuotationTable";
import ConsumptionNote from "./consumption/ConsumptionNote";
import CustomerDataReport from "./forms/reports/CustomerDataReport";
import ProductionReport from "./forms/reports/ProductionReport";
import EquipmentReport from "./forms/reports/EquipmentReport";
import ReceiptReport from "./forms/reports/ReceiptReport";
import AccountReceivableReport from "./forms/reports/AccountReceivableReport";
import AgeWiseSummary from "./forms/reports/AgeWiseSummary";
import ReportAll from "./forms/reports/ReportAll"; 
import SalesRepForm from './forms/admin/SalesRepForm';
import SalesRepresentativeTable from './forms/admin/SalesRepresentativeTable';
import GroupForm from "./forms/admin/GroupForm";
import GroupFormEdit from "./forms/admin/GroupFormEdit";
import GroupFormTable from "./forms/admin/GroupFormTable";
import Plant from "./forms/admin/Plant";
import PlantEdit from "./forms/admin/PlantEdit";
import PlantTable from "./forms/admin/PlantTable";
import PlantConfigurationTable from "./forms/admin/PlantConfigurationTable";
import PlantConfiguration from "./forms/admin/PlantConfiguration";
import PlantConfigurationEdit from "./forms/admin/PlantConfigurationEdit";
import ProductTable from "./forms/admin/ProductTable";
import PermissionTable from "./forms/admin/PermissionTable";
import CustomerMainTable from "./forms/marketing/CustomerMainTable";
import SalesOrderTable from "./forms/marketing/SalesOrderTable";
import DesignMixTable from "./forms/qualitycontrol/DesignMixTable";
import WorkScheduleTable from "./forms/Planning/WorkScheduleTable";
import PumpClearanceTable from "./forms/dispatch/PumpClearanceTable";
import PurchaseInvoiceTable from "./forms/purchase/PurchaseInvoiceTable";
import CheckInTable from "./forms/gate/CheckInTable";
import EquipmentTable from "./forms/Transport/EquipmentTable";
import BankTable from "./forms/accounts/BankTable";
import CreditNoteTable from "./forms/accounts/CreditNoteTable";
import DispatchReport from "./forms/reports/DispatchReport";
import DispatchReportSummary from "./forms/reports/DispatchReportSummary";
import SalesPostingReport from "./forms/reports/SalesPostingReport";
import LedgerAccount from "./forms/reports/LedgerAccount";
import TaxTable from "./forms/admin/TaxTable";
import GangTable from "./forms/admin/GangTable";
import IPAddressTable from "./forms/admin/IPAddressTable";
import NumberSettingTable from "./forms/admin/NumberSettingTable";
import Bank from "./forms/accounts/Bank";
import BankEdit from "./forms/accounts/BankEdit";
import Settings from './forms/admin/Settings';
import GoodsReceiptNote from "./forms/store/GoodsReceiptNote";
import GoodsReceiptNoteEdit from "./forms/store/GoodsReceiptNoteEdit";
import GoodsReceiptNoteTable from "./forms/store/GoodsReceiptNoteTable";
import ConcreteCalculator from "./forms/utility/ConcreteCalculator";
import AgeWiseReport from "./forms/reports/AgeWiseReport";
import NotFound from "./NotFound";  
import { useCookies } from 'react-cookie';
import { getBgImageLogo} from './services/LoginServices';
import { getApiBaseUrl } from "./services/serviceconfig";
import bg_image from "./img/bg_image.png";
function App() {
  const [backgroundImage,setBackgroundImage] = useState(bg_image);
  const [cookies] = useCookies(['myToken']);
  const imageUrl = getApiBaseUrl().slice(0, -4);
  React.useEffect(() => {
    getBgImageLogo(cookies)
    .then (
      response => {
          const companies = response.company_list.filter(obj => obj.status)
          console.log(JSON.stringify(companies)+"companies getBgImageLogo App");
          const company = companies[0];
          if (company && (company.bg_image)){
            console.log(JSON.stringify(company)+"company getBgImageLogoApp");
            setBackgroundImage(`${imageUrl}${company.bg_image}`);
          }
          else {
                setBackgroundImage(bg_image);
        }
      }
    )
}, []);
    const [category, setCategory,removeCategory] = useCookies(['myCategory']);
    const [superuser,setSuperuser,removeSuperuser] = useCookies(['mySuperuser']);
    
    const AdminWrapper = () => {
      return ((superuser['mySuperuser']) || (category['myCategory'].includes("is_rmc_admin") === true)) ? <Outlet /> : <Navigate to="/Home" />;
    };
    const SuperuserOnlyWrapper = () => {
      return (superuser['mySuperuser']) ? <Outlet /> : <Navigate to="/Home" />;
    };
    const MarkettingWrapper = () => {
      return ((superuser['mySuperuser']) || (category['myCategory'].includes("is_marketting") === true)) ? <Outlet /> : <Navigate to="/Home" />;
    };
    const QtyCtrlWrapper = () => {
      return ((superuser['mySuperuser']) || (category['myCategory'].includes("is_qty_ctrl") === true)) ? <Outlet /> : <Navigate to="/Home" />;
    };
    const PlanningWrapper = () => {
      return ((superuser['mySuperuser']) || (category['myCategory'].includes("is_planning") === true)) ? <Outlet /> : <Navigate to="/Home" />;
    };
    const DispatchWrapper = () => {
      return ((superuser['mySuperuser']) || (category['myCategory'].includes("is_dispatch")  === true)) ? <Outlet /> : <Navigate to="/Home" />;
    };
    const EquipmentWrapper = () => {
      return ((superuser['mySuperuser']) || (category['myCategory'].includes("is_transport") === true)) ? <Outlet /> : <Navigate to="/Home" />;
    };
    const GateWrapper = () => {
      return ((superuser['mySuperuser']) || (category['myCategory'].includes("is_gate") === true)) ? <Outlet /> : <Navigate to="/Home" />;
    };
    const ConsumptionNoteWrapper = () => {
      return ((superuser['mySuperuser']) || (category['myCategory'][0].is_gate === true)) ? <Outlet /> : <Navigate to="/Home" />;
    };
    const AccountsWrapper= () => {
      return ((superuser['mySuperuser']) || (category['myCategory'].includes("is_accounts") === true)) ? <Outlet /> : <Navigate to="/Home" />;
    };
    const PurchaseWrapper = () => {
      return ((superuser['mySuperuser']) || (category['myCategory'].includes("is_purchase") === true)) ? <Outlet /> : <Navigate to="/Home" />;
    };
    const StoreWrapper= () => {
      return ((superuser['mySuperuser']) || (category['myCategory'].includes("is_store") === true)) ? <Outlet /> : <Navigate to="/Home" />;
    };
    const ReportWrapper= () => {
      return ((superuser['mySuperuser']) || (category['myCategory'].includes("view_accounts_reports") === true) || (category['myCategory'].includes("view_transport_reports") === true) || (category['myCategory'].includes("view_marketting_reports") === true)|| (category['myCategory'].includes("view_dispatch_reports") === true) || (category['myCategory'].includes("view_store_reports") === true)) ? <Outlet /> : <Navigate to="/Home" />;
    };
  return (
    <BrowserRouter>
    <CookiesProvider>
    <div className="App" id="outer-container" style={{ backgroundImage: `url(${backgroundImage})` }} >
        <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/Home" element={<Home />} />
            
            <Route element={<SuperuserOnlyWrapper />}>
                <Route path="/Permission" element={<Permission />} />
                <Route path="/PermissionTable" element={<PermissionTable />} />
                <Route path="/PermissionEdit/:id"  element={<PermissionEdit />} />
                <Route path="/IpAddress" element={<IpAddress />} />
                <Route path="/IPAddressTable" element={<IPAddressTable />} />
                <Route path="/IpAddressEdit/:id"  element={<IpAddressEdit />} />
                <Route path="/User" element={<User />} />
                <Route path="/UserTable" element={<UserTable />} />
                <Route path="/UserEdit/:id"  element={<UserEdit />} /> 
            </Route>
            
            <Route element={<AdminWrapper />}>
                <Route path="/Company" element={<CompanyForm/>} /> 
                <Route path="/CompanyTable" element={<CompanyTable />} />
                <Route path="/CompanyEdit/:id" element={<CompanyForm />} />
                <Route path="/Plant" element={<Plant />} />
                <Route path="/PlantTable" element={<PlantTable />} />
                <Route path="/PlantEdit/:id"  element={<PlantEdit />} />
                <Route path="/Unit" element={<UnitForm />} />
                <Route path="/UnitTable" element={<UnitTable />} />
                <Route path="/UnitEdit/:id"  element={<UnitForm />} />
                <Route path="/SalesRep" element={<SalesRepForm />} />
                <Route path="/SalesRepTable" element={<SalesRepresentativeTable />} />
                <Route path="/SalesRepEdit/:id"  element={<SalesRepForm />} />
                <Route path="/Tax" element={<Tax />} />
                <Route path="/TaxTable" element={<TaxTable />} />
                <Route path="/TaxEdit/:id"  element={<TaxEdit />} />
                <Route path="/Gang" element={<Gang />} />
                <Route path="/GangTable" element={<GangTable />} />
                <Route path="/GangEdit/:id"  element={<GangEdit />} />
                <Route path="/Product" element={<Product />} />
                <Route path="/ProductTable" element={<ProductTable />} />
                <Route path="/ProductEdit/:id"  element={<ProductEdit />} />
                <Route path="/NumberSetting" element={<NumberSetting />}/>
                <Route path="/NumberSettingTable" element={<NumberSettingTable />}/>
                <Route path="/NumberSettingEdit/:id"  element={<NumberSettingEdit />} />
                <Route path="/ApprovalSetting" element={<ApprovalSetting />}/>
                <Route path="/ApprovalSettingTable" element={<ApprovalSettingTable />}/>
                <Route path="/ApprovalSettingEdit/:id"  element={<ApprovalSettingEdit />} />
                <Route path="/PlantConfiguration" element={<PlantConfiguration />} />
                <Route path="/PlantConfigurationTable" element={<PlantConfigurationTable />} />
                <Route path="/PlantConfigurationEdit/:id"  element={<PlantConfigurationEdit />} />
            </Route>
          
            <Route element={<MarkettingWrapper />}>
                <Route path="/CustomerMain" element={<CustomerMain />} />
                <Route path="/CustomerMainTable" element={<CustomerMainTable />} />
                <Route path="/CustomerMainEdit/:id"  element={<CustomerMainEdit />} />
                <Route path="/CustomerSite" element={<CustomerSite />} />
                <Route path="/CustomerSiteTable" element={<CustomerSiteTable />} />
                <Route path="/CustomerSiteEdit/:id"  element={<CustomerSiteEdit />} />
                <Route path="/Quotation" element={<Quotation />} />
                <Route path="/QuotationEdit/:id"  element={<QuotationEdit />} />
                <Route path="/QuotationTable" element={<QuotationTable />} /> 
                <Route path="/SalesOrder" element={<SalesOrder />} />
                <Route path="/SalesOrderTable" element={<SalesOrderTable />} />
                <Route path="/SalesOrderEdit/:id"  element={<SalesOrderEdit />} />
                {/* <Route path="/SOApproval/:id"  element={<SOApproval />} /> */}
            </Route>

            <Route element={<QtyCtrlWrapper />}>
                <Route path="/DesignMix" element={<DesignMix />} />
                <Route path="/DesignMixTable" element={<DesignMixTable />} />
                <Route path="/DesignMixEdit/:id" element={< DesignMixEdit/>} />
                <Route path = "/MoistureCorrection" element={<MoistureCorrection />}/>
                <Route path="/MoistureCorrectionTable" element={<MoistureCorrectionTable />} />
                <Route path="/MoistureCorrectionEdit/:id" element={< MoistureCorrectionEdit/>} />
                <Route path = "/CubeTestReport" element={<CubeTestReport />}/>
                <Route path = "/CubeTestReportEdit/:id" element={<CubeTestReportEdit />}/>
                <Route path="/CubeTestReportTable" element={<CubeTestReportTable />} />
            </Route>

            <Route element={<PlanningWrapper />}>
                <Route path="/WorkSchedule" element={<WorkSchedule />} />
                <Route path="/WorkScheduleTable" element={<WorkScheduleTable />} />
                <Route path="/WorkScheduleEdit/:id" element={<WorkScheduleEdit />} />
                <Route path="/JobCompletion/:id" element={<JobCompletion />} />
            </Route>

            <Route element={<DispatchWrapper />}>
                <Route path="/PumpClearance" element={<PumpClearance />} />
                <Route path="/PumpClearanceTable" element={<PumpClearanceTable />} />
                <Route path="/PumpClearanceEdit/:id" element={<PumpClearanceEdit />} />
                <Route path="/DeliveryChallan" element={<DeliveryChallan />} />
                <Route path="/DeliveryChallanEdit/:id" element={<DeliveryChallanEdit />} />
                <Route path="/DeliveryChallanTable" element={<DeliveryChallanTable />} />
            </Route>

            <Route element={<EquipmentWrapper />}>
                <Route path="/Equipment" element={<Equipment />} />
                <Route path="/EquipmentTable" element={<EquipmentTable />} />
                <Route path="/EquipmentEdit/:id" element={<EquipmentEdit />} />
            </Route>

            <Route element={<PurchaseWrapper />}>
              <Route path="/Vendor" element={<Vendor />} />
              <Route path="/VendorTable" element={<VendorTable />} />
              <Route path="/VendorEdit/:id"  element={<VendorEdit />} />
              <Route path="/PurchaseOrder" element={<PurchaseOrder />} />
              <Route path="/PurchaseOrderEdit/:id" element={<PurchaseOrderEdit/>} />
              <Route path="/PurchaseOrderTable" element={<PurchaseOrderTable/>} />
              <Route path="/PurchaseInvoice" element={<PurchaseInvoice />} />
              <Route path="/PurchaseInvoiceEdit/:id"  element={<PurchaseInvoiceEdit />} />
              <Route path="/PurchaseInvoiceTable" element={<PurchaseInvoiceTable />} />
            </Route>
            
            <Route element={<GateWrapper />}>
                <Route path="/CheckIn" element={<CheckIn/>} />
                <Route path="/CheckInTable" element={<CheckInTable/>} />
                <Route path="/CheckInEdit/:id" element={<CheckInEdit/>} />
                <Route path="/CheckOut" element={<CheckOut/>} />
                <Route path="/CheckOutTable" element={<CheckOutTable/>} />
                <Route path="/CheckOutEdit/:id" element={<CheckOutEdit/>} />
                <Route path="/VehicleGatePass" element={<VehicleGatePass/>} />
                <Route path="/VehicleGatePassEdit/:id" element={<VehicleGatePassEdit/>} />
                <Route path="/VehicleGatePassTable" element={<VehicleGatePassTable/>} />
            </Route>
            
            <Route element={<AccountsWrapper />}>
                <Route path="/GroupForm" element={<GroupForm />} />
                <Route path="/GroupFormEdit/:id" element={<GroupFormEdit />} /> 
                <Route path="/GroupFormTable" element={<GroupFormTable />} />
                {/* <Route path="/SalesInvoice" element={<SalesInvoice/>} />
                <Route path="/SalesInvoiceEdit/:id" element={<SalesInvoiceEdit/>} />
                <Route path="/SalesInvoiceTable" element={<SalesInvoiceTable/>} />  */}
                <Route path="/Bank" element={<Bank/>} />
                <Route path="/BankEdit/:id" element={<BankEdit/>} />
                <Route path="/BankTable" element={<BankTable/>} />
                <Route path="/ReceiptVoucher" element={<ReceiptVoucher/>} />
                <Route path="/ReceiptVoucherEdit" element={<ReceiptVoucherEdit/>} />
                <Route path="/ReceiptVoucherTable" element={<ReceiptVoucherTable/>} />
                <Route path="/CreditNote" element={<CreditNote/>} />
                <Route path="/CreditNoteEdit" element={<CreditNoteEdit/>} />
                <Route path="/CreditNoteTable" element={<CreditNoteTable/>} />
            </Route>
            
            <Route element={<StoreWrapper />}>
                <Route path="/GoodsReceiptNote" element={<GoodsReceiptNote />} />
                <Route path="/GoodsReceiptNoteTable" element={<GoodsReceiptNoteTable />} />
                <Route path="/GoodsReceiptNoteEdit/:id" element={<GoodsReceiptNoteEdit/>} />
            </Route>
    
            <Route element={<ConsumptionNoteWrapper />}>
                <Route path="/ConsumptionNote" element={<ConsumptionNote/>} />
            </Route>

            <Route element={<ReportWrapper />}>
              <Route path="/ReportAll" element={<ReportAll />} />
            </Route>

              <Route path="/CustomerDataReport"  element={<CustomerDataReport />} />
              <Route path="/CubeTestReport"  element={<CubeTestReport />} />
              <Route path="/ProductionReport"  element={<ProductionReport />} />
              <Route path="/EquipmentReport"  element={<EquipmentReport />} />
              <Route path="/ConcreteCalculator"  element={<ConcreteCalculator />} />
              <Route path="/ReceiptReport"  element={<ReceiptReport />} />
              <Route path="AccountReceivableReport"  element={<AccountReceivableReport />} />
              <Route path="/AgeWiseSummary"  element={<AgeWiseSummary />} />
              <Route path="/DispatchReport" element={<DispatchReport />} />
              <Route path="/DispatchReportSummary" element={<DispatchReportSummary />} />
              <Route path="/GoodsReceiptNote" element={<GoodsReceiptNote />} />
              <Route path="/SalesPostingReport" element={<SalesPostingReport />} />
              <Route path="/AgeWiseReport" element={<AgeWiseReport />} />
              <Route path="/LedgerAccount" element={<LedgerAccount />} />
            {/* only match this when no other routes match */}
            <Route path ="*" element={<NotFound />} />
        </Routes>
        <Settings/>
    </div>
    </CookiesProvider>
    </BrowserRouter>
  );
}
export default App;
                

