import {
    Row,
    Col,
    Button,
  } from 'reactstrap';
  import { useNavigate } from 'react-router';
  import Sidebar from '../../Sidebar';
  import {background} from '../../img/images.jpg';
  import Swal from "sweetalert2";
  import { useState } from "react";
  import { Link, useParams } from 'react-router-dom';
  import React, { Component, Fragment} from 'react';
  import { BiSave } from "react-icons/bi";
  import { IoIosEye } from "react-icons/io";
  import { AiOutlineHome } from "react-icons/ai";
  import { useCookies } from 'react-cookie';
  import {displayError, displayErrorToast,parseBoolean} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import { checkForDuplicateSalesReps } from './SalesRepUtils';
import { getFileName } from '../../utils/FileNameUtils';
import { 
  updateSalesRepresentative, 
  getSalesRepresentativeDetails, 
  createSalesRepresentative } from '../../services/SalesRepresentativeServices';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import StatusDropDown from '../../components/StatusDropDown';
import { getApiBaseUrl } from "../../services/serviceconfig"; 
function SalesRepForm() 
  {
    var imageUrl = getApiBaseUrl().slice(0, -4);
    console.log(imageUrl+"imageUrl");
    const [cookies] = useCookies(['myToken']);
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({
      srName:'',
      alias:'',
      srAdd1:'',
      srAdd2:'',
      srAdd3:'',
      srPincode:'',
      srPhoneNo:'',
      srMobileNo:'',
      srEmailid:'',
      srPanNo:'',
      srgsttype:'',
      srGstNo:'',
      srContactPerson:'',
      srContactMobileNo:'',
      SrContactDesignation:'',
      srContactEmailid:'',
      srCreditLimit:0,
      srRefName:'',
      srrefMobileNo:'',
      srRemarks:'',
      category:'Staff',
      status:true
    });
    const [selectedFile, setSelectedFile] = useState({
          sr_signature: "",
        });
    const [error, setError] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(true);
    const [inProgress, setInProgress] = useState(false);
    
    const handleChange = (event) => {
      const name = event.target.name;
      const value = event.target.value;
   try{   
        if(name === 'srName'|| name === 'alias')
        {
        if(!value.trim())                //Sales Representative Name and alias must not be empty,otherwise showing error message 
          {
            setError(values => ({...values, [name]: "Must not be empty."}))
            setIsSubmitting(false); 
          } else {
            setError(values => ({...values, [name]: ''}))
            setIsSubmitting(true); 
          }
        }
        if (name === "sr_signature") {
          if (event.target.files[0]) {
            // File is selected
            console.log(event.target.files[0].name + " event.target.files[0].name");
            console.log(name + " name");
            setSelectedFile((values) => ({
              ...values,
              [name]: event.target.files[0],
            }));
          } else {
            // No file selected (deselected)
            console.log("No file selected for " + name);
            setSelectedFile((values) => ({
              ...values,
              [name]: null, // You can set it to null or handle it as needed
            }));
          }
        }
          setInputs(values => ({...values, [name]: value}))
      }
       catch(e)
       {
        displayErrorToast(e);
       } 
    }

    const validate_items = (name,value,regular_expr,error_message) =>
    {
       try{
         
            if(value.trim())
            {
                if(!regular_expr.test(value.trim()))
                {
                    setError(values => ({...values, [name]: error_message}))
                    setIsSubmitting(false); 
                }
                else
                {
                    setError(values => ({...values, [name]: ''}))
                    setIsSubmitting(true);
                }
            }
            else
            {
              setError(values => ({...values, [name]: ''}))
              setIsSubmitting(true);
                // setError(values => ({...values, [name]: 'must not be empty...'}))
                // setIsSubmitting(false);
            }
            setInputs(values => ({...values, [name]: value}))
        }
     
       catch (e)
          {
           displayErrorToast(e);
          }
     }  
    
 const handleSubmit=(event)=> {
        event.preventDefault();

        var salesRepId = event.currentTarget.dataset.id;
        checkForDuplicateSalesReps(event.target.srName.value, cookies, salesRepId)
        .then(isNameExists => {
          if (isNameExists) {
            setError(values => ({...values, [event.target.srName.name]: 'Sales Representative name already exists.'}))
            
          } else {
            try{     
              if ((Object.values(error).every(x => x === '')) && isSubmitting) {    //If the fields are correct details it is valid choosing yes or no option,otherwise it is invalid
                if (salesRepId) {
                  handleUpdate(salesRepId);
                } else {
                  handleCreate();
                }
              } else {
                Swal.fire('Please check * marked fields are filled and details given are valid...', '', 'info')   
              }  
            } catch(e) {
              displayErrorToast(e);
            }    
          }
        });
                    
    }

    const handleCreate = () => {
      Swal.fire({title: 'Do you want to save?',  
      showCancelButton: true,  
      confirmButtonText: `Yes`,  
      cancelButtonText: `No`,
      }).then((result) => {  
        if (result.isConfirmed) {
          setInProgress(true); 
          createSalesRepresentative(cookies, constructFormData())
          .then(function (response) {
            Swal.fire('Saved!', '', 'success') 
            // Reload();
            Cancel();
          }).catch(function (error) {
            console.log(error);
            displayError(error.response.data,'Save Failed');
          });
          setInProgress(false);
          
        } 
        else if (result.isDismissed) 
        {    
          Swal.fire('Not saved', '', 'info')  
        }
      });
    };
    const constructFormData=()=>{
      const EMPTY_STRING = '';
      const dataArray = new FormData();
      if (selectedFile.sr_signature) {
        dataArray.append("signature", selectedFile.sr_signature);
      }
         dataArray.append("entity_name",(inputs.srName).toUpperCase());
         dataArray.append("alias", inputs.alias);
         dataArray.append("category", inputs.category);
         dataArray.append("address_1",inputs.srAdd1 || EMPTY_STRING);
         dataArray.append("address_2",inputs.srAdd2 || EMPTY_STRING);
         dataArray.append("address_3",inputs.srAdd3 || EMPTY_STRING);
         dataArray.append("pincode",inputs.srPincode || EMPTY_STRING);
         dataArray.append("gst_type",inputs.srgsttype || EMPTY_STRING);
         dataArray.append("phone_number",inputs.srPhoneNo || EMPTY_STRING);
         dataArray.append("mobile_number",inputs.srMobileNo || EMPTY_STRING);
         dataArray.append("email_id",inputs.srEmailid || EMPTY_STRING);
         dataArray.append("contact_person",inputs.srContactPerson || EMPTY_STRING);
         dataArray.append("ref_name",inputs.srRefName || EMPTY_STRING);
         dataArray.append("credit_limit",inputs.srCreditLimit );
         dataArray.append("pan_no",inputs.srPanNo || EMPTY_STRING);
         dataArray.append("gst_no",inputs.srGstNo || EMPTY_STRING);
         dataArray.append("contact_email_id",inputs.srContactEmailid || EMPTY_STRING);
         dataArray.append("contact_designation",inputs.SrContactDesignation || EMPTY_STRING);
         dataArray.append("contact_mobile_no",inputs.srContactMobileNo || EMPTY_STRING);
         dataArray.append("ref_mobile_no",inputs.srrefMobileNo || EMPTY_STRING);
         dataArray.append("user_remarks",inputs.srRemarks || EMPTY_STRING);
         dataArray.append("status",parseBoolean(inputs.status) );
         return dataArray;
    }
    const handleUpdate = (salesRepId) => {
      Swal.fire({title: 'Do you want to Update?',  
      showCancelButton: true,  
      confirmButtonText: `Yes`,  
      cancelButtonText: `No`,
      }).then((result) => {  
        if (result.isConfirmed){ 
          // updateSalesRepresentative(cookies, constructFormData(), salesRepId);
          // setInProgress(false);
          // Swal.fire('Updated!', '', 'success') 
          // Reload();
          setInProgress(true);
          updateSalesRepresentative(cookies, constructFormData(), salesRepId)
          .then(function (response) {
            Swal.fire('Updated!', '', 'success') 
            Reload();
          }).catch(function (error) {
            console.log(error);
            displayError(error.response.data,'Update Failed');
          });
          setInProgress(false);
        } 
        else if (result.isDismissed) 
        {    
          Swal.fire('Not Updated', '', 'info')  
        }
      });
    };
    
    const Reload = () =>{
      navigate('/SalesRepTable')
    } 
                        
    const view = () => {
      navigate('/SalesRepTable')
    }
    
    const Back = () => {
      navigate('/Home')
    }
    
    const Cancel = () => {
      setInputs(() => "")
    }

    const {id}=useParams()
    React.useEffect(() => {
      if (id) {
        setInProgress(true);
        getSalesRepresentativeDetails(cookies, id)
          .then(response =>setInputs(values => {
            setInProgress(false);
            return {...values,
              ['id']: response.id, 
              ['srRefName']: response.ref_name,
              ['srCreditLimit']:response.credit_limit,
              ['srName']:response.entity_name,
              ['alias']:response.alias,
              ['category']:response.category,
              ['srrefMobileNo']:response.ref_mobile_no,
              ['srAdd1']:response.address_1,
              ['srAdd2']:response.address_2,
              ['srAdd3']:response.address_3,
              ['srPincode']:response.pincode,
              ['srPhoneNo']:response.phone_number,
              ['srMobileNo']:response.mobile_number,
              ['srEmailid']:response.email_id,
              ['srPanNo']:response.pan_no,
              ['srGstNo']:response.gst_no,
              ['srgsttype']:response.gst_type, 
              ['srContactPerson']:response.contact_person,
              ['srContactMobileNo']:response.contact_mobile_no, 
              ['SrContactDesignation']:response.contact_designation, 
              ['srContactEmailid']:response.contact_email_id,
              ['srRemarks']:response.user_remarks,
              ['status']:String(response.status),
              ['signature']:response.signature}
          }))
          .catch(error => console.log(error));
        }
      }, []);
      
           
   
    return(
      <>
        <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <ErrorToast/>
            <LoadingOverlay inProgress={inProgress}/>
            <div id="page-wrap">
              <form className="form" onSubmit={handleSubmit} data-id={inputs.id}  encType="multipart/form-data">
                <div id="header"> 
                  <h4 className = "text font-weight-bold page-title">
                  {(id ? 'Update ' : '')}Sales Representative Registration & Setup
                  </h4>
                </div>
                <FloatingControls tableLink="/SalesRepTable" onCancel={Cancel} enableCancel={!id}/>
                
                <div className="container"  > 
                  <Row> 
                    <Col xl={4} lg={12} md={12}>
                      <div className="form-row table-bordered shadow p-2 my-2 border p-2 mb-3 bg-blue form-control-panel">
                        <h5 className='col-sm-11'><b>Sales Representative Details</b></h5><br/><br/>
                        
                        <label htmlFor="srName" className="form-group col-sm-4 text-right">Name </label>
                        <input type="text" id="srName" name="srName" className="form-control col-sm-7  mandatory-form-control" onChange={handleChange} value={inputs.srName || ""} placeholder="Please fill the NAME field *" required />
                        {error.srName && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.srName}</strong><br/></>)}

                        <label htmlFor="alias" className="form-group col-sm-4 text-right">Alias </label>
                        <input type="text" id="alias" name="alias" className="form-control col-sm-7  mandatory-form-control" onChange={handleChange} value={inputs.alias || ""} placeholder="Please fill the alias field *" required />
                        {error.alias && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.alias}</strong><br/></>)}

                        <label htmlFor="category" className="form-group col-sm-4 text-right">Category</label>
                        <select name="category" id="category" onChange={handleChange} value={inputs.category || ""}className="form-control col-sm-7 browser-default custom-select">
                          <option value="Staff">Staff</option>
                          <option value="Agent">Agent</option>
                        </select>
                       
                        <label htmlFor="srAdd1" className="form-group col-sm-4 text-right">Address</label>
                        <input type="text" id="srAdd1" name="srAdd1" onChange={handleChange} value={inputs.srAdd1 || ""} className="form-control col-sm-7" />
                        
                        <label htmlFor="srAdd2" className="form-group col-sm-4 text-right"></label>
                        <input type="text" id="srAdd2" name="srAdd2" onChange={handleChange} value={inputs.srAdd2 || ""} className="form-control col-sm-7" />
                        
                        <label htmlFor="srAdd3" className="form-group col-sm-4 text-right"></label>
                        <input type="text" id="srAdd3" name="srAdd3" onChange={handleChange} value={inputs.srAdd3 || ""} className="form-control col-sm-7" />

                        <label htmlFor="srPincode"className="form-group col-sm-4 text-right  ">Pin Code</label>
                        <input name="srPincode" id="srPincode" onChange={ (event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid Pincode")} value={inputs.srPincode || ""} className="form-control col-sm-7" type = "number" />
                        {error.srPincode && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.srPincode}</strong><br/></>)}

                        <label htmlFor="srPhoneNo" className="form-group col-sm-4 text-right">Phone No.</label>
                        <input type="number" id="srPhoneNo" name="srPhoneNo" onChange={handleChange} value={inputs.srPhoneNo || ""} className="form-control col-sm-7" maxLength="50" />

                        <label htmlFor="srMobileNo" className="form-group col-sm-4 text-right">Mobile No.</label>
                        <input type="text" id="srMobileNo" name="srMobileNo"  placeholder="Please enter exactly 10 digits"
                        //  onChange={handleChange} pattern="\d{10}"
                        onChange={ (event) => validate_items(event.target.name,event.target.value,/^\d{10}$/,"invalid Mobile No")}
                        value={inputs.srMobileNo || ""} className="form-control col-sm-7" maxLength="10" />
                        {error.srMobileNo && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.srMobileNo}</strong><br/></>)}

                        <label htmlFor="srEmailid"className="form-group col-sm-4 text-right  ">Email id</label>
                        <input type="email" id="srEmailid" name="srEmailid"  onChange={ (event) => validate_items(event.target.name,event.target.value,/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,"invalid Emailid")}
                        value={inputs.srEmailid|| ""} className="form-control col-sm-7 " />
                        {error.srEmailid && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.srEmailid}</strong><br/></>)}

                        <label htmlFor="srCreditLimit" className="form-group col-sm-4 text-right">Credit Limit</label>
                        <input type="number" step="any" id="srCreditLimit" name="srCreditLimit" onChange={handleChange} value={inputs.srCreditLimit || 0} className="form-control col-sm-7" />
                      </div>
                    </Col>
                    
                    <Col xl={4} lg={12} md={12}>
                      <div className="form-row table-bordered shadow p-2 my-2 border p-2 mb-3 bg-blue form-control-panel" >

                        <h5 className='col-sm-11'><b> Contact Details</b></h5><br/> <br/>   
                        
                        <label htmlFor="srContactPerson" className="form-group col-sm-4 text-right">Contact Person</label>
                        <input type="text" id="srContactPerson" 
                        // onChange={handleChange} pattern="[a-zA-Z\s]+"
                        onChange={ (event) => validate_items(event.target.name,event.target.value,/^[a-zA-Z\s]+$/,"invalid Contact Person")}
                        value={inputs.srContactPerson || ""} name="srContactPerson" className="form-control col-sm-7" 
                        />
                        {error.srContactPerson && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.srContactPerson}</strong><br/></>)}

                        <label htmlFor="SrContactDesignation" className="form-group col-sm-4 text-right">Designation</label>
                        <input type="text" id="SrContactDesignation" onChange={handleChange} value={inputs.SrContactDesignation || ""} name="SrContactDesignation" className="form-control col-sm-7"  />

                        <label htmlFor="srContactEmailid"className="form-group col-sm-4 text-right  ">Email id</label>
                        <input type="email" id="srContactEmailid" name="srContactEmailid"  onChange={ (event) => validate_items(event.target.name,event.target.value,/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,"invalid Emailid")} value={inputs.srContactEmailid|| ""} className="form-control col-sm-7 " />
                        {error.srContactEmailid && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.srContactEmailid}</strong><br/></>)}

                        <label htmlFor="srContactMobileNo" className="form-group col-sm-4 text-right">Mobile No.</label>
                        <input type="text" id="srContactMobileNo" 
                        // onChange={handleChange} pattern="\d{10}"
                        onChange={ (event) => validate_items(event.target.name,event.target.value,/^\d{10}$/,"invalid Mobile No")}
                        placeholder="Please enter exactly 10 digits" value={inputs.srContactMobileNo || ""} name="srContactMobileNo" className="form-control col-sm-7" maxLength="10" />
                        {error.srContactMobileNo && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.srContactMobileNo}</strong><br/></>)}

                        <label htmlFor="srRefName" className="form-group col-sm-4 text-right">Referred by</label>
                        <input type="text" id="srRefName" name="srRefName"
                        //  onChange={handleChange} pattern="[a-zA-Z\s]+"
                        onChange={ (event) => validate_items(event.target.name,event.target.value,/^[a-zA-Z\s]+$/,"invalid Name of the Referred")}
                        value={inputs.srRefName || ""} className="form-control col-sm-7"  />
                        {error.srRefName && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.srRefName}</strong><br/></>)}

                        <label htmlFor="srrefMobileNo" className="form-group col-sm-4 text-right">Mobile No.</label>
                        <input type="text" id="srrefMobileNo" name="srrefMobileNo"  placeholder="Please enter exactly 10 digits" 
                        // onChange={handleChange} pattern="\d{10}"
                        onChange={ (event) => validate_items(event.target.name,event.target.value,/^\d{10}$/,"invalid Mobile No")}
                        value={inputs.srrefMobileNo || ""} className="form-control col-sm-7" maxLength="10" />
                        {error.srrefMobileNo && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.srrefMobileNo}</strong><br/></>)}
                      </div>
                    
                      <div className="form-row table-bordered shadow p-2 my-2 border p-2 mb-3 bg-blue form-control-panel" >
                      <h5 className='col-sm-11'><b> Files Uploading Centre</b></h5><br/> <br/>   
                        <label htmlFor="sr_signature" className="form-group col-sm-4 text-right">Signature</label>
                        <input type="file" id="sr_signature"  name="sr_signature" onChange={handleChange}  className="form-control col-sm-7"/>
                        {id&&
                        (<><label className="form-group col-sm-4 text-right  ">Current Sign</label>
                        <a target="_blank" rel="noopener noreferrer" href={`${imageUrl}${inputs.signature}`}  style={{color:"black"}}className="form-group col-sm-7 ">{inputs.signature?getFileName(inputs.signature):''}</a>
                        </>)}                                                  
                      </div>
                    </Col>

                    <Col xl={4} lg={12} md={12}>
                      <div className="form-row table-bordered shadow p-2 my-2 border p-2 mb-3 bg-blue form-control-panel" >
                      
                        <h5 className='col-sm-11'><b> Statutory Details</b></h5><br/> <br/>   
                        
                        <label htmlFor="srgsttype" className="form-group col-sm-4 text-right">GST Type </label>
                        <select  name="srgsttype" onChange={handleChange} value={inputs.srgsttype || ""} className="browser-default custom-select col-sm-7">
                          <option value=''>Please select</option>
                          <option value='Regular'>Regular</option>
                          <option value='Consumer'>Consumer</option>
                          <option value='Composite'>Composite</option>
                          <option value='N/A'>N/A</option>
                        </select>
                        
                        <label htmlFor="srGstNo" className="form-group col-sm-4 text-right">GST No.</label>
                        <input type="text" id="srGstNo" name="srGstNo" onChange={ (event) => validate_items(event.target.name,event.target.value,/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,"invalid GSTNo")}  value={inputs.srGstNo || ""} className="form-control col-sm-7" placeholder="Please fill the GST field *" />
                        {error.srGstNo && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.srGstNo}</strong><br/></>)}

                        <label htmlFor="srPanNo" className="form-group col-sm-4 text-right">PAN No.</label>
                        <input type="text" id="srPanNo" name="srPanNo" onChange={ (event) => validate_items(event.target.name,event.target.value,/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,"invalid PANNo")} value={inputs.srPanNo || ""} className="form-control col-sm-7" placeholder="Please fill the PAN NO field *"/><br/> <br/>
                        {error.srPanNo && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.srPanNo}</strong><br/></>)}
                        
                        </div>
                    
                    <div className="form-row table-bordered shadow p-2 my-2 border p-2 mb-3 bg-blue form-control-panel" >
                    
                      
                        <h5 className='col-sm-11'><b> Status Details</b></h5><br/>  
                        
                        <label htmlFor="srRemarks" className="form-group col-sm-4 text-right">Remarks</label>
                        <input type="text" id="srRemarks" name="srRemarks" onChange={handleChange} value={inputs.srRemarks || ""} className="form-control col-sm-7" />

                        <StatusDropDown status={inputs.status} onChange={handleChange}/> 
                      </div>
                    </Col>
                  </Row> 
                </div>

                <div className="footer text-center">
                  <table border="0" cellPadding="5" style={{display: 'inline-table'}}>
                    <tbody>
                      <tr>
                        <td>
                          <Button  type="submit"  style={{width:"80px",fontWeight:"bold"}} className="btn btn-twitter">
                            {(id ? 'Update' : 'Save')}
                          </Button>
                        </td>
                        {(!id ? <td><Button type="button" className="btn btn-twitter"style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Cancel</Button></td> : '')}
                          <td>
                          <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>
                          </td>
                        <td>
                          <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                        </td>
                      </tr>
                    </tbody>                        
                  </table>
                </div>
              </form><br/>
            </div><br/>
        </div> 
        </>
    );
  }
  export default SalesRepForm;