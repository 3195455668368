import { getAllApprovalSetting } from "../../services/ApprovalSettingService";

const soConditions = [
  { value:"advance_amount", label: "SO Advance Amt <= 0" },
  {value:"credit_limit",label:"SO Customers Credit limit < SO TotalAmt"}
];

export function getSalesOrderApprovalConditions(isDisabled) {

  if (isDisabled) {
    return soConditions.map(condition => ({...condition, isdisabled: isDisabled}))
  } else {
    return soConditions;
  }
}

export function getSelectedSalesOrderConditions(selectedConditions) {
  return getSalesOrderApprovalConditions()
  .filter(condition => selectedConditions.includes(condition.value))
    // .search(condition.value) >= 0)
}

