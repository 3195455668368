import {getAllUnits} from '../../services/UnitServices'

export function checkForDuplicateUnits(unitName, cookies, existingId) {
    
    
    let nameCheck = new Promise((resolve, reject) => {
      getAllUnits(cookies,unitName)
      .then((data) => {

        let isUserExists = false;
        data.unit_list.forEach(unit =>{           //Same Unit Name mentioned it denoted already exist.
          if((unit.name.toUpperCase())=== unitName.trim().toUpperCase()
          && parseInt(existingId)!==unit.id){
            console.log(`Unit exits with the name - ${unitName}`)
            isUserExists =true;
          }
        });
        resolve(isUserExists);
      });
    });
    return nameCheck;
  }