import {
    Row,
    Col,
    Card,
    CardHeader,
    CardBody,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    Button,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu,
  } from 'reactstrap';
  import { useNavigate } from 'react-router';
  import Sidebar from '../../Sidebar';
  import background from "../../img/images.jpg";
  import Swal from "sweetalert2";
  import { useState } from "react";
  import { Link, useParams } from 'react-router-dom';
  import React, { Component, Fragment} from 'react';
  import { BiSave } from "react-icons/bi";
  import { IoIosEye } from "react-icons/io";
  import { AiOutlineHome } from "react-icons/ai";
  import { useCookies } from 'react-cookie';
import { displayErrorToast,displayError, parseBoolean } from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { updateUnit,getUnitDetails,createUnit } from '../../services/UnitServices';
import { checkForDuplicateUnits } from './UnitUtils';
import StatusDropDown from '../../components/StatusDropDown';
  const axios = require('axios').default;
  function UnitForm() 
  {
    const [cookies] = useCookies(['myToken']);
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({});
    const [inProgress, setInProgress] = useState(false);
    const [error, setError] = useState({
        unitName:'',
        unitSymbol:''
      });
    const [isSubmitting, setIsSubmitting] = useState(true);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
     try{   
          if(name === 'name')
          {
          if(!value.trim())                //Unit Name must not be empty,otherwise showing error message 
            {
              setError(values => ({...values, [name]: "Unit Name must not be empty..."}))
              setIsSubmitting(false); 
            } else {
              setError(values => ({...values, [name]: ''}))
              setIsSubmitting(true); 
            }
          }
            setInputs(values => ({...values, [name]: value}))
        }
         catch(e)
         {
          displayErrorToast(e);
         } 
      }
 
      const handleSubmit = (event) => {
        event.preventDefault();
               try{
            checkForDuplicateUnits(
                event.target.name.value,
                cookies,
                id)
          .then(isNameExists => {
              
            if (isNameExists) {
              setError(values => ({...values, [event.target.name.name]: 'Unit name already exists.'}));
            } else {
  
              if (Object.values(error).every((x) => !x) && isSubmitting) {        //If the fields are correct details it is valid choosing yes or no option,otherwise it is invalid
                
                const action = id ? "Update" : "Save";
                Swal.fire({title: `Do you want to ${action}?`,  
                showCancelButton: true,  
                confirmButtonText: `Yes`,  
                cancelButtonText: `No`,
                    }).then((result) => {  
                        if (result.isConfirmed){ 
                            if (id) {
                                handleUpdate();
                              } else {
                                handleCreate();
                              }
                        } 
                        else if (result.isDismissed) 
                        {    
                        Swal.fire(`Not ${action}d`, '', 'info')  
                        }
                    });
                
              } else {
                Swal.fire("Please check * marked fields are filled and details given are valid...","","info" );
              }
            }
        }); 
      } catch (e) {
          displayErrorToast(e);
      } 
            
     } 
     const constructFormData = () => {
      return {
        name: (inputs.name).toUpperCase(),
        symbol: inputs.symbol,
        quantity:inputs.quantity,
        decimal_place:inputs.decimal_place,
        user_remarks:inputs.user_remarks,
        status:parseBoolean(inputs.status)

      }
    }
                      
  const handleCreate=(event)=> {
        
       
        setInProgress(true); 
        createUnit(cookies, constructFormData())
        .then(function (response) {
            Swal.fire('Saved!', '', 'success') 
            Cancel();
        }).catch(function (error) {
            console.log(error);
            displayError(error.response.data,'Save Failed');
        });
        setInProgress(false);
              
            
  };
              
    const handleUpdate = () => {
       
        setInProgress(true);
        updateUnit(cookies, constructFormData(), id)
        .then(function (response) {
            Swal.fire('Updated!', '', 'success') 
            Reload();
        }).catch(function (error) {
            console.log(error);
            displayError(error.response.data,'Save Failed');
        });
        setInProgress(false);
      
    };

    const Reload = () =>{
      navigate('/UnitTable')
    } 
    
    const view = () => {
      navigate('/UnitTable')
    }
    
    const Back = () => {
      navigate('/Home')
    }
    const Cancel = () => {
      setInputs(() => "")
    }
    
    const {id}=useParams()
    React.useEffect(() => {
      if (id) {
        setInProgress(true);
        getUnitDetails(cookies, id)
          .then(response => {
            setInProgress(false);
            setInputs({...response, ['status']:String(response.status)});
          })
          .catch(error => {
            setInProgress(false);
            displayError(error.response.data, "Loading Failed");
          });
        }
      }, []);
      
         
    return(
        <>
    <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <ErrorToast/>
            <LoadingOverlay inProgress={inProgress}/>
            <div id="page-wrap">
                <form className="form" onSubmit={handleSubmit}  data-id={inputs.id}>
                    <div id="header">
                        <h4 className = "text font-weight-bold page-title">{(id ? 'Update ' : '')}Unit Registration & Setup</h4>
                    </div>
                    <FloatingControls tableLink="/UnitTable" onCancel={Cancel} enableCancel={!id}/>
              <div className="container-fluid">
              <Row>
                          <Col xl={4} lg={12} md={12}>
                          </Col>
                  <Col xl={4} lg={12} md={12}>
                      <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue " style={{backgroundColor:"RGB(188,232,253)" ,borderRadius:"10px",fontWeight:"bold",fontFamily:"'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif"}}>
                        
                          
                          <label htmlFor="name" className="form-group col-sm-4 text-right">Unit Name </label>
                          <input type="text" id="name" className="form-control col-sm-7 mandatory-form-control"required name="name" onChange={handleChange} value={inputs.name || ""} /><br/><br/>
                          {error.name && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.name}</strong><br/></>)}
                          
                          <label htmlFor="symbol" className="form-group col-sm-4 text-right">Symbol </label>
                          <input type="text" id="symbol" className="form-control col-sm-7 mandatory-form-control" name="symbol"onChange={handleChange}  required value={inputs.symbol || ""}/><br/><br/>
                          {error.symbol && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.symbol}</strong><br/></>)}
  
                          <label htmlFor="quantity" className="form-group col-sm-4 text-right">Qty </label>
                          <input type="number" step="any" id="quantity" className="form-control col-sm-7 mandatory-form-control" name="quantity" onChange={handleChange} required value={inputs.quantity || ""}/><br/><br/>
                          
                          <label htmlFor="decimal_place" className="form-group col-sm-4 text-right">Decimal Places</label>
                          <input type="number" id="decimal_place" className="form-control col-sm-7" name="decimal_place" onChange={handleChange} value={inputs.decimal_place || ""}/><br/><br/>
                          
                          <label htmlFor="user_remarks" className="form-group col-sm-4 text-right">Remarks</label>
                          <input type="text" id="user_remarks" className="form-control col-sm-7" name="user_remarks" onChange={handleChange} value={inputs.user_remarks || ""}/><br/><br/>
                          
                          <StatusDropDown status={inputs.status} onChange={handleChange}/>
                      </div>
                      </Col>
              </Row> 
              </div>
              <div className="footer text-center">
              <table border="0" cellPadding="5" style={{display: 'inline-table'}}>
                          <tbody>
                            <tr>
                              <td>
                              <Button  type="submit"  style={{width:"80px",fontWeight:"bold"}} className="btn btn-twitter">
                                {(id ? 'Update' : 'Save')}
                              </Button>
                              </td>
                              {(!id ? <td><Button type="button" className="btn btn-twitter"style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Cancel</Button></td> : '')}
                              <td>
                              <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>
                              </td>
                              <td>
                              <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                              </td>
                            </tr>
                          </tbody>                        
                        </table>
              </div>
                  </form> 
              </div><br/>
              </div> 
              
          </>
    );
  }
  export default UnitForm;
