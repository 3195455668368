
import { getApiBaseUrl,getApiClient } from "./serviceconfig";

var apiBaseUrl = getApiBaseUrl();
export async function getAllSalesRepresentatives(cookies,searchString) {
     let url = `${apiBaseUrl}/entity_salesrep/`
     if(searchString) {
      url = url + `?name=${searchString}`;
    }
   
    return getApiClient(cookies).get(url)
    .then(response => response.data);
    
}

export function createSalesRepresentative(cookies, inputs) {

  return getApiClient(cookies).post(`${apiBaseUrl}/entity_salesrep/`, inputs, {
    headers: {
      "Content-Type": "multipart/form-data",
  }})
  
}

export function updateSalesRepresentative(cookies, inputs, salesRepId) {

  return getApiClient(cookies).put(`${apiBaseUrl}/entity_salesrep/${salesRepId}/`, inputs, {
    headers: {
      "Content-Type": "multipart/form-data",
  }})
    
    
}

export async function getSalesRepresentativeDetails(cookies, salesRepId) {

  return getApiClient(cookies).get(`${apiBaseUrl}/entity_salesrep/${salesRepId}/`)
              .then(response => response.data)
}

export function deleteSalesRepresentative(cookies, salesRepId) {

  return getApiClient(cookies).delete(`${apiBaseUrl}/entity_salesrep/${salesRepId}/`)
}