import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Redirect, Route,Link,NavLink, Routes } from 'react-router-dom';
import { useState,useRef,useEffect} from "react";
import Swal from "sweetalert2";
import {Row,
    Col,
    // Card,
  Button,
} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';

import '../../button.css';
import {displayErrorToast,displayError} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import {  createPlant } from '../../services/PlantServices';
import { getAllCompanies,getCompanyDetails } from '../../services/CompanyServices';
import StatusDropDown from '../../components/StatusDropDown';
import { getIndianStates } from '../../utils/PicklistProvider';

function Plant() {
    const [cookies] = useCookies(['myToken']);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [inputs, setInputs] = useState({});

    const navigate = useNavigate();
    const [companies, setCompanies] = useState([]);
    const [error, setError] = useState({});
    const [inProgress, setInProgress] = useState(false);
    const [selectedFile, setSelectedFile] = useState({
      plant_logo: "",
      plant_seal: "",
      plant_br_logo: "",
    });

    const [isSubmitting, setIsSubmitting] = useState(true);
  

    // useEffect( () => {
    //   getAllCompanies(cookies)
    //   .then(response => {
    
    //       const company=[]
    //                       for(var j=0; j<response.company_data.length; j++) 
    //                       {
    //                           if(response.company_data[j].status)
    //                           {
    //                             company.push(response.company_data[j]);
                                
    //                           }
    //                       }
    //                       setCompanies(company);
                        

    //   });
   
    // }, []);

    React.useEffect(() => {
      getAllCompanies(cookies)

      .then (
          companyList => {
              
              const companies = companyList.company_data.filter(obj => obj.status).map(
                company => {
                      return { value: company.id, label: company.entity_name }
                  }
              );
              setCompanies(companies);
          }
      )
  }, []);

 
  const constructFormData = () => {
 
   const EMPTY_STRING = '';
   const dataArray = new FormData();
   if (selectedFile.logo) {
    dataArray.append("plant_logo", selectedFile.logo);
  }
  if (selectedFile.plant_br_logo) {
    dataArray.append("plant_br_logo", selectedFile.plant_br_logo);
  }
  if (selectedFile.seal) {
    dataArray.append("plant_seal", selectedFile.seal);
  }
  if (selectedFile.qc_in_charge_sign) {
    dataArray.append("qc_in_charge_sign", selectedFile.qc_in_charge_sign);
  }
  if (selectedFile.accounts_in_charge_sign) {
    dataArray.append("accounts_in_charge_sign", selectedFile.accounts_in_charge_sign);
  }
  if (selectedFile.qc_seal) {
    dataArray.append("qc_seal", selectedFile.qc_seal);
  }
         dataArray.append("entity_company_id",inputs.entity_company_id);
         dataArray.append("plant_alias", inputs.plant_alias);
         dataArray.append("entity_name", inputs.entity_name);
         dataArray.append("address_1",inputs.address_1 || EMPTY_STRING);
         dataArray.append("address_2",inputs.address_2 || EMPTY_STRING);
         dataArray.append("address_3",inputs.address_3 || EMPTY_STRING);
         dataArray.append("pincode",inputs.pincode || EMPTY_STRING);
         dataArray.append("state",inputs.state || EMPTY_STRING);
         dataArray.append("phone_number",inputs.phone_number || EMPTY_STRING);
         dataArray.append("mobile_number",inputs.mobile_number || EMPTY_STRING);
         dataArray.append("email_id",inputs.email_id || EMPTY_STRING);
         dataArray.append("plant_web",inputs.plant_web || EMPTY_STRING);
         dataArray.append("plant_commence_dt",inputs.plant_commence_dt );
         dataArray.append("account_opening_dt",inputs.opening_dt );
         dataArray.append("account_closing_dt",inputs.closing_dt );
         dataArray.append("pan_no",inputs.pan_no );
         dataArray.append("gst_no",inputs.gst_no );
         dataArray.append("plant_cst_no",inputs.plant_cst_no || EMPTY_STRING);
         dataArray.append("plant_lut_no",inputs.plant_lut_no || EMPTY_STRING);
         dataArray.append("plant_tan_no",inputs.plant_tan_no || EMPTY_STRING);
         dataArray.append("plant_pfno",inputs.plant_pfno || EMPTY_STRING);
         dataArray.append("plant_esino",inputs.plant_esino || EMPTY_STRING);
         dataArray.append("plant_in_charge",inputs.plant_in_charge || EMPTY_STRING);
         dataArray.append("accounts_in_charge",inputs.accounts_in_charge || EMPTY_STRING);
         dataArray.append("qc_in_charge",inputs.qc_in_charge || EMPTY_STRING);
         dataArray.append("qc_incharge_designation",inputs.qc_incharge_designation || EMPTY_STRING);
         dataArray.append("plant_mixer_capacity",inputs.plant_mixer_capacity );
         dataArray.append("status",inputs.status );
         dataArray.append("user_remarks",inputs.user_remarks || EMPTY_STRING);
         dataArray.append("plant_serial_no",inputs.plant_serial_no );
         dataArray.append("plant_model",inputs.plant_model || EMPTY_STRING);
         dataArray.append("plant_make", inputs.plant_make || EMPTY_STRING);
        //  dataArray.append("plant_seal", selectedFile.seal );
        //  dataArray.append("plant_logo", selectedFile.logo );


         return dataArray;
  }
  
  

  //   const handleChange = (event) => {
  //     const name = event.target.name;
  //     const value = event.target.value;
  //     try{
  //           if (name === "entity_name") {            //Company name must not be empty,otherwise showing error message  
            
  //             if (!value.trim()) {
  //               console.log("Company data is empty");
  //               setError((values) => ({
  //                 ...values,
  //                 [name]: "Company Name must not be empty...",
  //               }));
  //               setIsSubmitting(false);
  //             } else {
  //               setError((values) => ({
  //                 ...values,
  //                 [name]: '',
  //               }));
  //               setIsSubmitting(true);
  //             }
            
  //           } else if (name === "logo" || name === "seal") {
  //             console.log(event.target.files[0].name + "event.target.files[0].name");
  //             console.log(name + "name");
  //             setSelectedFile((values) => ({
  //               ...values,
  //               [name]: event.target.files[0],
  //             }));
  //           }

  //           setInputs((values) => ({ ...values, [name]: event.target.value }));
  //         }
  
  //   catch (e)
  //   {
  //    displayErrorToast(e);
  //   }
  // }

//   const handleChange = (event) => {
//     const name = event.target.name;
//     const value = event.target.value;
//     try{
//           if (name === "entity_name") {            //Company name must not be empty,otherwise showing error message  
          
//             if (!value.trim()) {
//               console.log("Plant data is empty");
//               setError((values) => ({
//                 ...values,
//                 [name]: "Plant Name must not be empty...",
//               }));
//               setIsSubmitting(false);
//             } else {
//               setError((values) => ({
//                 ...values,
//                 [name]: '',
//               }));
//               setIsSubmitting(true);
//             }
          
//           } else if (name === "logo" || name === "seal") {
//             console.log(event.target.files[0].name + "event.target.files[0].name");
//             console.log(name + "name");
//             setSelectedFile((values) => ({
//               ...values,
//               [name]: event.target.files[0],
//             }));
//           }
//           setInputs(values => ({...values, [name]: event.target.value}))
//         }

//   catch (e)
//   {
//    displayErrorToast(e);
//   }
// }

// const handleChangeLogo = (event) => {
//   const name = event.target.name;
//   const value = event.target.value;
//   try{
//     if (name === "logo" || name === "seal") {
//       if (event.target.files[0]) {
//         // File is selected
//         console.log(event.target.files[0].name + " event.target.files[0].name");
//         console.log(name + " name");
//         setSelectedFile((values) => ({
//           ...values,
//           [name]: event.target.files[0],
//         }));
//       } else {
//         // No file selected (deselected)
//         console.log("No file selected for " + name);
//         setSelectedFile((values) => ({
//           ...values,
//           [name]: null, // You can set it to null or handle it as needed
//         }));
//       }
//     }
    

//         setInputs((values) => ({ ...values, [name]: event.target.value }));
//       }

// catch (e)
// {
//  displayErrorToast(e);
// }
// }

const handleChange = (event) => {
  const name = event.target.name;
  const value = event.target.value;
  try{
        if (name === "entity_name") {            //Company name must not be empty,otherwise showing error message  
        
          if (!value.trim()) {
            console.log("Company data is empty");
            setError((values) => ({
              ...values,
              [name]: "Plant Name must not be empty...",
            }));
            setIsSubmitting(false);
          } else {
            setError((values) => ({
              ...values,
              [name]: '',
            }));
            setIsSubmitting(true);
          }
        
        } 
        else if (name === 'entity_company_id'){
          console.log(value+typeof(value)+isSubscribed)
          if (isSubscribed && value){
            getCompanyDetails(cookies,value)
       
            .then((data) => {
              console.log(JSON.stringify(data)+"data2")
          
              const copiedvalues = {
                entity_name: data.entity_name,
                plant_web: data.web,
                pan_no: data.pan_no,
                gst_no: data.gst_no,
                address_1:data.address_1,
                address_2:data.address_2,
                address_3:data.address_3,
                pincode:data.pincode,
                state:data.state
              };
              console.log(JSON.stringify(copiedvalues)+"copiedvalues")
          
              setInputs(values => ({...values, ...copiedvalues}));
            });
          }
          else{
            const selectedCompany = companies.find((item) => item.value === parseInt(value));
            console.log(JSON.stringify(selectedCompany)+JSON.stringify(companies)+"selectedCompany");
            const plantName = selectedCompany ? selectedCompany.label : '';
            setInputs((values) => ({ ...values, ['entity_name']: plantName }));
          }
        }
        
  //       else if((name === "state") && (value)){
  //         // const stateCode = value.slice(-2);
  //         const stateCode = value.slice(-3,-1);
  //         if(inputs.gst_no){
  //         const gstCode = inputs.gst_no.slice(0, 2);
      
  //   if (stateCode !== gstCode) {
     
  //     setError((values) => ({ ...values, [name]: 'GST state code does not match selected state.' }));
  //     setIsSubmitting(false);
  //   }
  //   else{
  //     setError((values) => ({ ...values, [name]: '' }));
  //     setIsSubmitting(true);
  //   }
  // }
  //       }

        if (name === "logo" || name === "seal" || name === 'plant_br_logo'|| name === 'accounts_in_charge_sign' || name === 'qc_in_charge_sign' || name==='qc_seal') {
          if (event.target.files[0]) {
            // File is selected
            console.log(event.target.files[0].name + " event.target.files[0].name");
            console.log(name + " name");
            setSelectedFile((values) => ({
              ...values,
              [name]: event.target.files[0],
            }));
          } else {
            // No file selected (deselected)
            console.log("No file selected for " + name);
            setSelectedFile((values) => ({
              ...values,
              [name]: null, // You can set it to null or handle it as needed
            }));
          }
        }
        setInputs((values) => ({ ...values, [name]: event.target.value }));
      }

catch (e)
{
 displayErrorToast(e);
}
}

        const handleCopy = (event) => { 

   
          if (event.target.checked && inputs.entity_company_id != '') 
          
      
          {
            let companyId =inputs.entity_company_id;

           
            console.log(companyId+"companyId")
       
            getCompanyDetails(cookies,companyId)
       
                          .then((data) => {
                            console.log(JSON.stringify(data)+"data2")
                        
                            const copiedvalues = {
                              entity_name: data.entity_name,
                              plant_web: data.web,
                              pan_no: data.pan_no,
                              gst_no: data.gst_no,
                              address_1:data.address_1,
                              address_2:data.address_2,
                              address_3:data.address_3,
                              pincode:data.pincode,
                              state:data.state
                            };
                            console.log(JSON.stringify(copiedvalues)+"copiedvalues")
                        
                            setInputs(values => ({...values, ...copiedvalues}));
                          });
            setIsSubscribed(true)
                        }
           else
            {
            const emptyvalues = {
              entity_name: '',
              plant_web: '',
              pan_no: '',
              gst_no: '',
              address_1:'',
              address_2:'',
              address_3:'',
              pincode:'',
              state:''
            };
            setIsSubscribed(false)
            setInputs(values => ({...values, ...emptyvalues}));
         }
          
        }
      
        const handleSubmit=(event)=> {
          event.preventDefault();
          console.log(JSON.stringify(error),isSubmitting+"error submit")
          try{
          if ((Object.values(error).every(x => !x)) && isSubmitting) {  //If all the required fields are field u can select the save button
                  Swal.fire({title: 'Do you want to save?',  
                  showCancelButton: true,  
                  confirmButtonText: `Yes`,  
                  cancelButtonText: `No`,
                  }).then((result) => {  
                    if (result.isConfirmed) 
                    { 
                      const dataArray = constructFormData();
                      console.log(JSON.stringify(dataArray)+"dataArray");
                      createPlant(cookies, dataArray)
                      
                      .then(function (response) 
                      {
                        //console.log(JSON.stringify(response)+"tax_form");
                        Swal.fire('Saved!', '', 'success') 
                      Reload();
                    
                    }).catch(function (error) 
                      {
                        console.log(error.response.data);
                        displayError(error.response.data,"Saved Failed");
                        //Swal.fire(error) 
                      });
              
                    
                    }
                    else if(result.isDismissed) 
                    {    
                      // Swal.fire('Not saved', '', 'info')  
                      Swal.fire('Not saved', '', 'info')  
                    }
                  });
          }
        } 
        catch (e)
        {
         displayErrorToast(e);
        } 
      }

 
const validate_items = (name, value, regular_expr, error_message) => {
  try {
    const trimmedValue = value.trim();
    // setError((values) => ({ ...values, [name]: '' })); // Clear previous errors
    // setIsSubmitting(true);

    if (!trimmedValue) {
      if (name === 'pan_no' || name === 'gst_no') {
        setError((values) => ({ ...values, [name]: 'must not be empty...' }));
        setIsSubmitting(false); 
      }
    } else if (!regular_expr.test(trimmedValue)) {
      setError((values) => ({ ...values, [name]: error_message }));
      setIsSubmitting(false); 
    } else {
      setError((values) => ({ ...values, [name]: '' })); // Clear the error for this field
      setIsSubmitting(true);
      // if (name === 'gst_no') {
      //   // Convert GST number to uppercase
      //   const upperCaseValue = trimmedValue.toUpperCase();
      //   // const stateCode = inputs.state.slice(-2);
      //   const stateCode = inputs.state.slice(-3,-1);
      //   const gstCode = upperCaseValue.slice(0, 2);

      //   if (stateCode !== gstCode) {
      //     console.log(stateCode,gstCode+"stateCode+gstCode")
      //     setError((values) => ({
      //       ...values,
      //       [name]: 'GST code does not match selected state Code.',
      //     }));
      //   }
      //   else{
      //     setError((values) => ({ ...values, [name]: '' })); 
      //   }
      // }
    }
  } catch (e) {
    displayErrorToast(e);
  } 
  finally {
    // setIsSubmitting(false);
    console.log(name+value+"name value")
    setInputs((values) => ({ ...values, [name]: value }));
  }
};


      const Reload = () => {
        navigate("/PlantTable");
      };
    
      const view = () => {
        navigate("/PlantTable");
      };
    
      const Back = () => {
        navigate("/Home");
      };
     
      const Cancel = () => {
        setInputs(() => "")
      }

    return (
    <>
     <div id="outer-container"  className="App" > 
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <ErrorToast/>
        <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
        {/* <form className="form" onSubmit={handleSubmit} data-id={inputs.id}> */}
        <form onSubmit={handleSubmit}  encType="multipart/form-data">
          <div id="header">
              <h4 className = "text font-weight-bold page-title">Plant Registration & Setup</h4>
          </div>
          <FloatingControls tableLink="/PlantTable" onCancel={Cancel} enableCancel={true}/>
            <div className="container"> 
                <Row> 
                <Col xl={6} lg={12} md={12}>   
                {/* #99ccff */}
                              <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel" >
                           
                                <h5 className='col-sm-11'><b>Plant Details</b></h5>
                              <label htmlFor="entity_company_id"className="form-group col-sm-4 text-right"> Company Name </label>
                                  <select id="entity_company_id"name="entity_company_id" className="browser-default custom-select form-control col-sm-7 mandatory-form-control" onChange={handleChange} value={inputs.entity_company_id || ""} required>
                                    <option value=''>Please Select</option>

                                                 {companies.map((cmp) => (
                                    <option key={cmp.value} value={cmp.value}>
                                      {cmp.label}
                                          </option>
                                        ))}

                                      </select><br/>

                               
                              
                                <label htmlFor="entity_name" className="form-group col-sm-4 text-right">Plant Name </label>
                                <input type="text" id="entity_name" name="entity_name" onChange={handleChange} value={inputs.entity_name || ""} className="form-control col-sm-7 mandatory-form-control"  placeholder="Please fill the NAME field *" required  /><br/>
                                {error.entity_name && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.entity_name}</strong><br/></>)}

                                <label htmlFor="plant_alias"className="form-group col-sm-4 text-right">Alias</label>
                                <input type="text" id="plant_alias" name="plant_alias" onChange={handleChange} value={inputs.plant_alias || ""}  className="form-control col-sm-7 mandatory-form-control"  placeholder="Please fill the Alias Field *" required/><br/>

                                <label  htmlFor="copyvalues"className="form-group col-sm-4 text-right">Same as Company</label>
                                <label className="col-sm-7"><input type="checkbox" id="copyvalues" name="copyvalues"  value={isSubscribed}
                   onClick={handleCopy} /></label>


                                <input type="text" id="plant_alias" name="plant_alias" onChange={handleChange} value={inputs.plant_alias || ""} style={{display:"None"}} className="form-control col-sm-7 "  placeholder="Please fill the Alias Field *" /><br/>
                                
                                <label htmlFor="address_1"className="form-group col-sm-4 text-right">Address</label>
                                <input type="text" id="address_1" name="address_1" onChange={handleChange} value={inputs.address_1 || ""} className="form-control col-sm-7  rounded " /><br/>
                                
                                <label htmlFor="address_2" className="form-group col-sm-4 text-right"></label>
                                <input type="text" id="address_2"  name="address_2" onChange={handleChange} value={inputs.address_2 || ""} className="form-control col-sm-7 rounded "  /><br/>
                                
                                <label htmlFor="address_3"  className="form-group col-sm-4 text-right"></label>
                                <input type="text" id="address_3" name="address_3" onChange={handleChange} value={inputs.address_3 || ""} className="form-control col-sm-7 rounded "  /><br/>
                                <label htmlFor="pincode"className="form-group col-sm-4 text-right">Pin Code</label>
                                <input name="pincode" id="pincode" onChange={handleChange } value={inputs.pincode || ""} className="form-control col-sm-7" type ="text" maxLength="6" /><br/>
                                
                                <label htmlFor="state"className="form-group col-sm-4 text-right  ">State</label>
                              <select id="state"name="state" className="browser-default custom-select form-control col-sm-7 mandatory-form-control" onChange={handleChange} required value={inputs.state || ""}>
                              <option value="">Select State</option>
                                    {
                                      getIndianStates()
                                      .map(indian_state => <option key={indian_state.value} value={indian_state.value}>{indian_state.name}</option>
                                      )
                                    }
                                  </select><br/>
                                  {error.state && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.state}</strong><br/></>)}
                               
                                  <label htmlFor="phone_number" className="form-group col-sm-4 text-right">Phone No</label>
                            <input type="tel" id="phone_number" name="phone_number" onChange={handleChange} 
                            // onChange={(event)=>validate_items(event.target.name,event.target.value,/^[0-9]{10}$/,"Invalid Phone Number")} 
                            value={inputs.phone_number || ""} className="form-control col-sm-7"/><br />
                            {error.phone_number && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.phone_number}</strong><br/></>)}

                            <label htmlFor="mobile_number"className="form-group col-sm-4 text-right  ">Mobile No</label>
                              <input type="text" id="mobile_number" name="mobile_number"onChange={ (event) => validate_items(event.target.name,event.target.value,/^\+?[0-9]{1,4}[-\s]?[0-9]{7,10}$/,"invalid Mobile No")} value={inputs.mobile_number|| ""} pattern="[0-9]{10}"  maxLength="10" className="form-control col-sm-7 " /><br/>
                              {error.mobile_number && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.mobile_number}</strong><br/></>)}


                                <label htmlFor="plant_web"className="form-group col-sm-4 text-right">Web</label>
                                <input type="text" id="plant_web" name="plant_web" onChange={handleChange} value={inputs.plant_web || ""}className="form-control col-sm-7" /><br/>
                                
                                <label htmlFor="email_id"className="form-group col-sm-4 text-right">Email id</label>
                                <input type="email" id="email_id" name="email_id"  onChange={ (event) => validate_items(event.target.name,event.target.value,/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,"invalid Emailid")} value={inputs.email_id|| ""} className="form-control col-sm-7 " /><br/>
                                {error.email_id && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.email_id}</strong><br/></>)} 
                                
                                <label htmlFor="plant_commence_dt"className="form-group col-sm-4 text-right">Commencement Date </label>
                                <input type="date" id="plant_commence_dt" name="plant_commence_dt" onChange={handleChange} value={inputs.plant_commence_dt|| ""} className="form-control col-sm-7 mandatory-form-control rounded" required maxLength="50"/>
                                
                                <label htmlFor="opening_dt"className="form-group col-sm-4 text-right">Account Opening Date </label>
                                <input type="date" id="opening_dt" name="opening_dt" onChange={handleChange} value={inputs.opening_dt || ""}className="form-control col-sm-7 mandatory-form-control rounded" required  maxLength="50"/><br/>
                                
                                <label htmlFor="closing_dt"className="form-group col-sm-4 text-right">Account Closing Date </label>
                                <input type="date"  id="closing_dt"  name="closing_dt" onChange={handleChange} value={inputs.closing_dt || ""}className="form-control col-sm-7 mandatory-form-control rounded" required maxLength="50"/><br/>
                                
                                                     
                          </div>

                          <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel" >
                              <h5 className='col-sm-11'><b>Management and Signature Repository</b></h5>
                              
                              <label htmlFor="plant_in_charge" className="form-group col-sm-4 text-right">Plant Incharge</label>
                              <input type="text" id="plant_in_charge" onChange={handleChange} value={inputs.plant_in_charge || ""}name="plant_in_charge" className="form-control col-sm-7" />
                            
                              <label htmlFor="accounts_in_charge" className="form-group col-sm-4 text-right">Accounts Incharge</label>
                              <input type="text" id="accounts_in_charge" onChange={handleChange} value={inputs.accounts_in_charge || ""}name="accounts_in_charge" className="form-control col-sm-7" />                            
                              <label htmlFor="accounts_in_charge_sign"className="form-group col-sm-4 text-right">Signature</label>
                              <input type="file" id="accounts_in_charge_sign"  name="accounts_in_charge_sign" onChange={handleChange} />


                              
                              <label htmlFor="qc_in_charge" className="form-group col-sm-4 text-right">QC incharge</label>
                              <input type="text" id="qc_in_charge" onChange={handleChange} value={inputs.qc_in_charge || ""}name="qc_in_charge" className="form-control col-sm-7" />
                              <label htmlFor="qc_incharge_designation" className="form-group col-sm-4 text-right">Designation</label>
                              <input type="text" id="qc_incharge_designation" onChange={handleChange} value={inputs.qc_incharge_designation || ""}name="qc_incharge_designation" className="form-control col-sm-7" />
                              <label htmlFor="qc_in_charge_sign"className="form-group col-sm-4 text-right">Signature</label>
                              <input type="file" id="qc_in_charge_sign"  name="qc_in_charge_sign" onChange={handleChange} />
  


                          </div>


                    </Col>
                    <Col xl={6} lg={12} md={12}>  
                            <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue  form-control-panel">
                     
                        <h5 className='col-sm-11'><b>Company Statutory</b></h5>
                        <label htmlFor="pan_no"className="form-group col-sm-4 text-right">PAN No </label>
                            <input type="text" id="pan_no" required name="pan_no" onChange={ (event) => validate_items(event.target.name,event.target.value,/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/,"invalid PANNo")}  value={inputs.pan_no || ""} className="form-control col-sm-7 mandatory-form-control"  placeholder="Please fill the PAN field *"  /><br/>
                            {error.pan_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.pan_no}</strong><br/></>)}
                            
                          
                            <label htmlFor="gst_no"className="form-group col-sm-4 text-right  ">GST No </label>
                          <input type="text" id="gst_no" name="gst_no" onChange={ (event) => validate_items(event.target.name,event.target.value,/^[0-9]{2}[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}[1-9A-Za-z]{1}Z[0-9A-Za-z]{1}$/,"invalid GSTNo")}  value={inputs.gst_no || ""}className="form-control col-sm-7 mandatory-form-control" style={{ textTransform: 'uppercase' }} required placeholder="Please fill the Gst field *" /><br/>
                          {error.gst_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.gst_no}</strong><br/></>)}
                            
                                                      
                            <label htmlFor="plant_lut_no"className="form-group col-sm-4 text-right">LUT No</label>
                            <input type="number" id="plant_lut_no" name="plant_lut_no" onChange={handleChange} 
                            // onChange={ (event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid LUTNo")} 
                            value={inputs.plant_lut_no|| ""}className="form-control col-sm-7 " />
                            {error.plant_lut_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.plant_lut_no}</strong></>)}
                                
                            <label htmlFor="plant_cst_no"className="form-group col-sm-4 text-right">CST No</label>
                            <input type="text" id="plant_cst_no" name="plant_cst_no" onChange={handleChange} 
                            //  onChange={ (event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid CSTNo")} 
                             value={inputs.plant_cst_no || ""}className="form-control col-sm-7 "  />
                            {error.plant_cst_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.plant_cst_no}</strong></>)}
                            
                            <label htmlFor="plant_tan_no"className="form-group col-sm-4 text-right">TAN No</label>
                            <input type="text" id="plant_tan_no" name="plant_tan_no" onChange={handleChange} 
                            //  onChange={ (event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid TANNo")}
                              value={inputs.plant_tan_no || ""}className="form-control col-sm-7 "  />
                            {error.plant_tan_no && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.plant_tan_no}</strong></>)}

                            <label htmlFor="plant_pfno"className="form-group col-sm-4 text-right">PF No</label>
                            <input type="text" id="plant_pfno" name="plant_pfno"  onChange={handleChange} 
                            // onChange={ (event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid PFNo")}
                              value={inputs.plant_pfno || ""} className="form-control col-sm-7 "  />
                            {error.plant_pfno && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.plant_pfno}</strong></>)}

                            <label htmlFor="plant_esino"  className="form-group col-sm-4 text-right">ESI No</label>
                            <input type="text" id="plant_esino" name="plant_esino" onChange={handleChange} 
                            // onChange={ (event) => validate_items(event.target.name,event.target.value,/^[1-9][0-9]{5}$/,"invalid ESINo")} 
                            value={inputs.plant_esino || ""} className="form-control col-sm-7 " />
                            {error.plant_esino && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.plant_esino}</strong></>)}
                        </div>

                        <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue  form-control-panel">
                     
                            <h5 className='col-sm-11'><b>Plant Hub</b></h5>

                            <label  htmlFor="plant_mixer_capacity"className="form-group col-sm-4 text-right">Mixer Capacity </label>
                            <input type="number"  id="plant_mixer_capacity"required name="plant_mixer_capacity" onChange={handleChange} value={inputs.plant_mixer_capacity || ""}className="form-control col-sm-7 mandatory-form-control " /><br/>
                            
                            <label htmlFor="plant_make"className="form-group col-sm-4 text-right">Make</label>
                            <input type="text" id="plant_make"onChange={handleChange} value={inputs.plant_make || ""} name="plant_make" className="form-control col-sm-7" />
                            
                            <label htmlFor="plant_model"className="form-group col-sm-4 text-right">Model</label>
                            <input type="text" id="plant_model" onChange={handleChange} value={inputs.plant_model || ""}name="plant_model" className="form-control col-sm-7" />
                            
                            <label htmlFor="plant_serial_no" className="form-group col-sm-4 text-right">Plant serial No.</label>
                            <input type="text" id="plant_serial_no" onChange={handleChange} value={inputs.plant_serial_no || ""}name="plant_serial_no" className="form-control col-sm-7 mandatory-form-control " required/>
                            
                            <label htmlFor="logo"className="form-group col-sm-4 text-right">Plant Logo</label>
                              <input type="file" id="logo"  name="logo" onChange={handleChange} />
                              
                              <label htmlFor="seal"className="form-group col-sm-4 text-right">Plant Seal</label>
                              <input type="file" id="seal" name="seal" onChange={handleChange} />
                              <label htmlFor="plant_br_logo"className="form-group col-sm-4 text-right">BR Logo</label>
                              <input type="file" id="plant_br_logo"  name="plant_br_logo" onChange={handleChange} /><br></br>
                              <label htmlFor="qc_seal"className="form-group col-sm-4 text-right">Qc Seal</label>
                              <input type="file" id="qc_seal"  name="qc_seal" onChange={handleChange} />

                        
                        </div>
                        <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel">
                      
                      {/* <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-label form-control-panel"></div> */}
                      <h5 className='col-sm-11'><b>Plant Status</b></h5>
                      <StatusDropDown status={inputs.status} onChange={handleChange}/>

                      <label htmlFor="user_remarks" className="form-group col-sm-4 text-right">Remarks</label>
                      <input type="text" id="user_remarks" name="user_remarks"onChange={handleChange} value={inputs.user_remarks || ""} maxLength="1000" className="form-control  col-sm-7" /><br/><br/>

                  </div>
                    </Col>
                 </Row>             
            </div>
                              <div className="footer text-center">
                                <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Save</Button> &nbsp;&nbsp;
                                <Button type="reset" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Reset</Button> &nbsp;&nbsp;
                                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>&nbsp;&nbsp;
                                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
                              </div>
        </form>
        </div>
    </div>
         </>
      );
    }                             
  export default Plant;