import React from 'react';
import { useState } from "react";
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Button,
} from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import '../../App.css';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import TableRows from "../../forms/admin/TableRows";
import { Link } from 'react-router-dom';
import '../../button.css';
import {displayErrorToast,displayError} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { createNumberSetting, getAllNumberSetting } from "../../services/NumberSettingServices";
import { getAllPlants,getUserAllowedPlants } from '../../services/PlantServices';

function NumberSetting() {
  const [cookies] = useCookies(['myToken']);
  const [error, setError] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [inputs, setInputs] = useState({
   
    plant_id:'',
    settings_list:''
    
});

  const navigate = useNavigate();
 
  const VT_PURCHASEORDER = "purchaseorder";
  const VT_SALESORDER = "salesorder";
  const [plants, setPlants] = React.useState([]);


    React.useEffect(() => {
      // getAllPlants(cookies)
      getUserAllowedPlants(cookies)
    .then (
        plantList => {
            
            const plants = plantList.plant_list.filter(obj => obj.status).map(
              plant => {
                    return { value: plant.id, label: plant.plant_alias }
                }
            );
            setPlants(plants);
        }
    )
}, []);
  

  const handleChange2 = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    try{
        // if (name === 'voucher_type') {

        //   if (value === VT_SALESORDER) {

        //     document.getElementById("voucher_type").disabled = false;
        //     document.getElementById("voucher_type").required = true;
          
        //   } else if (value === VT_PURCHASEORDER) {

        //     document.getElementById("voucher_type").disabled = true;
        //     document.getElementById("voucher_type").required = false;
        //     setInputs(values => ({...values, ['voucher_type']:'false'}));
      
        //   }
        // }
     
        setInputs(values => ({...values, [name]: value}))
      }
      catch (e)
      {
       displayErrorToast(e);
      }
   }
  const [rowsData, setRowsData] = useState([]);
  const [inProgress, setInProgress] = useState(false);
 
  const addTableRows = () => {
    const rowsInput={
            prefix:'',
            starting_number:0,
            number_of_digits:0,
            valid_from_date:'',
            valid_upto_date:'',
            user_remarks:''
        }
        setRowsData([...rowsData, rowsInput])
  }
    
  const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
   }
 
  const handleChange = (index, evnt)=>{
        const { name, value } = evnt.target;
        const rowsInput = [...rowsData];
        rowsInput[index][name] = value;
        setRowsData(rowsInput);
  }
  
 

  const validateNumberSettings = async () => {

    return new Promise((resolve, reject) => {
      getAllNumberSetting(cookies).then(settingsList => {
      
        let message = null;
        const settingsForVoucherType = 
          settingsList.number_settings_list.filter(setting => setting.voucher_type === inputs.voucher_type);
        if (settingsForVoucherType.length > 0) {
          if (parseInt(inputs.plant_id) === -1
            && settingsForVoucherType.filter(setting => !setting.plant.id).length > 0) {
              message = `Number Setting for ${inputs.voucher_type} already exists for ALL plant`;
          } else if (parseInt(inputs.plant_id) === -1
            && settingsForVoucherType.filter(setting => setting.plant.id).length > 0) {
              message = `Already created individually for ${inputs.voucher_type}`;
          } else if (settingsForVoucherType.filter(setting => setting.plant.id === parseInt(inputs.plant_id)).length > 0) {
            message = `This plant is already registered for ${inputs.voucher_type}`;
          } else if (parseInt(inputs.plant_id) !== -1
            && settingsForVoucherType.filter(setting => !setting.plant.id).length > 0) {
              message = `Number Setting for ${inputs.voucher_type} already exists for ALL plant. Cannot create for individual`;
          }
          
        }
        
        resolve(message);
    
      })
    });
  }
  const handleSubmit=(event)=> {
    event.preventDefault();
 
    if ((Object.values(error).every(x => !x)) && isSubmitting) {  //If all the required fields are field u can select the save button
            Swal.fire({title: 'Do you want to save?',  
            showCancelButton: true,  
            confirmButtonText: `Yes`,  
            cancelButtonText: `No`,
            }).then((result) => {  
      
              if (result.isConfirmed) { 
                createNumberSetting(cookies, {
             
                  plant_id:((inputs.plant_id === 'all')?  -1: inputs.plant_id),
                  voucher_type:inputs.voucher_type,
         
                  settings_list:rowsData.map(settings => ({
                    ...settings,
                    starting_number: parseInt(settings.starting_number),
                    number_of_digits: parseInt(settings.number_of_digits)
                  }))
              })
        
                .then((response) =>
                {
                  //console.log(JSON.stringify(response)+"tax_form");
                  Swal.fire('Saved!', '', 'success') 
                view()
                  Cancel();
                }).catch((error) =>
                {
                  //console.log(error);
                  displayError(error.response.data,"Save Failed");
                  //Swal.fire(error) 
                })
                
              } 
              else if (result.isDismissed) 
              {    
                Swal.fire('Not saved', '', 'info')  
              }
            });
    }
 
  }
      const Reload = () => {
        window.location.reload();
      }      
      
      const view = () => {
        navigate('/NumberSettingTable')
      }        
      
      const Back = () => {
        navigate('/Home')
      }

      const Cancel = () => {
        setInputs(() => "");
        const rowsInput={
          voucher_type:'',
    plant_id:'',
    starting_number:'',
    number_of_digits:'',
    prefix:'',
    valid_from_date:'',
    valid_upto_date:'',
    user_remarks:''
        }
        setRowsData([rowsInput]);
      }
  return (
    <>
    
    <div id="outer-container"  className="App" > 
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <ErrorToast/>
        <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
          <form onSubmit={handleSubmit} >
            <div id="header">
              <h4 className="text font-weight-bold page-title" >Voucher Number Settings</h4>
            </div>
            <FloatingControls tableLink="/NumberSettingTable" onCancel={Cancel} enableCancel={true}/>
            <div className="container"> 
                <Row> 
                  <Col xl={3} lg={12} md={12}> </Col>
                  <Col xl={12} lg={12} md={6}> 
                    <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel">
                    <label htmlFor="voucher_type"className="form-group col-sm-2 text-right  ">Voucher Type</label>
                        <select  id="voucher_type" required name="voucher_type" onChange={handleChange2} value={inputs.voucher_type || ""}className="form-control  col-sm-4 browser-default custom-select  mandatory-form-control">
                            <option value=""></option>
                            <option value="sales_order">Sales Order</option> 
                            <option value="delivery_challan">Delivery Challan</option>
                            <option value="cube_test_report">Cube Test Report</option>
                            <option value="invoice">Invoice</option>
                            <option value="receipt_voucher">Receipt</option>
                            <option value="credit_note">Credit Note</option>
                            <option value="quotation">Quotation</option>
                            <option value="purchase_order">Purchase Order</option>
                            <option value="goods_reciept_note">Goods Receipt Note</option>
                        </select><br/>

      
                       <label htmlFor="plant_id"className="form-group col-sm-2 text-right  ">Plant</label>
                        <select  id="plant_id" required name="plant_id" onChange={handleChange2} value={inputs.plant_id || ""}className="form-control  col-sm-4 browser-default custom-select  mandatory-form-control">
                            <option value=""></option>
                            <option value='all'>All</option>
                            {/* <option value="-1">All</option> */}
                            {plants.map((item) => (
                                          <option key={item.value} value={item.value}>
                                            {item.label}
                                          </option>
                                        ))}
                        </select><br/>


                       
                  </div>
                  </Col>
          </Row> 
          {/*  */}
          {/* <div className="form-row table-bordered table-light sky blue shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
        <table className="table order-list" id="myTable" style={{textAlign:"center",borderRadius:"10px",backgroundColor:"RGB(188,232,253)"}}> */}
        <div className="form-row table-bordered table-light sky blue shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue container table-responsive">
        <table className="table order-list" id="myTable"  style={{textAlign:"center",borderRadius:"10px"}}>
         
        <thead style={{ backgroundColor: 'green' }}>
        <tr style={{ backgroundColor: "lightblue" }}>
                    <th><button className="btn btn-success" onClick={addTableRows} >+</button></th>
                    <th  style={{ textAlign: 'center', verticalAlign: 'middle' }} colSpan="1">Prefix</th>
                    <th  style={{ textAlign: 'center', verticalAlign: 'middle' }} colSpan="1">Starting Number</th>
                    <th style={{ textAlign: 'center', verticalAlign: 'middle' }} colSpan="1">Number of Digits</th>
                    <th  style={{ textAlign: 'center', verticalAlign: 'middle' }} colSpan="1">Valid Date (From)</th>
                    <th style={{ textAlign: 'center', verticalAlign: 'middle' }} colSpan="1">Valid Date (To)</th>
                    <th style={{ textAlign: 'center', verticalAlign: 'middle' }} colSpan="1">Remarks</th>
                  </tr>
            </thead>
            <tbody >
                <TableRows rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} />
            </tbody>
        </table>
        </div>
          </div>
              <div className="footer text-center"><br/>


              
                  <Button  type="submit"  style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(42,245,39,0.8)"}} className="btn btn-twitter">Save</Button> &nbsp;&nbsp;
                  <Button type="button"  style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter" onClick={Cancel}>Reset</Button> &nbsp;&nbsp;
                  <Button  type="button"style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter" onClick={view}>View</Button>&nbsp;&nbsp;
                  <Button  type="button"style={{width:"80px",fontWeight:"bold",backgroundColor:"RGB(58,29,238,0.8)"}} className="btn btn-twitter" onClick={Back}>Home</Button>
              </div>
              </form> 
          </div><br/>
          </div> 
          
      </>
  );
}
export default NumberSetting;
