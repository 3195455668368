import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllGangs, deleteGang } from "../../services/GangServices";
import { displayError } from "../../helpers";
function GangTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true);  
    const columns = useMemo(
        () => [
            {
                fieldName: 'entity_name', 
                headerName: 'Gang Name'
            },
            {
                fieldName: 'vendor_name', 
                headerName: 'Vendor Name'
            },
            {
                fieldName: 'contact_person', 
                headerName: 'Contact Person Name'
            },
           
            {
                fieldName: "contact_mobile_no",
                headerName: 'Mobile No'
            },
            
            {
                fieldName: 'status',
                headerName: 'Status'
            },
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
        ],
        [],);

    const onEditGang = (gangId, event) => {
       
       navigate(`/GangEdit/${gangId}/`);
    };

    const onDeleteGang = (gangId, event) => {

        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
            if (result.isConfirmed) { 

                setIsLoading(true);
                deleteGang(cookies, gangId)
                .then(response => {
                    console.log(`Gang with id ${gangId} deleted`);
                    setRefreshKey(oldKey => oldKey +1);
                    Swal.fire('Deleted Successfully!', '', 'success');
                })
                .catch(error => {
                    console.log(error.response.data);
                    displayError(error.response.data,'Delete Failed');
                }); 
                
                
              }
     else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
        //

    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                 
                    entity_name: r.entity_name,
                    entity_vendor_id: r.entity_vendor_id,
                    contact_person:r.contact_person,
                    contact_mobile_no:r.contact_mobile_no,
                    status:r.status ? "active":"inactive" ,
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Gangs',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
           
                entity_name: r.entity_name,
                entity_vendor_id: r.entity_vendor_id,
                contact_person:r.contact_person,
                contact_mobile_no:r.contact_mobile_no,
                status:r.status ? "active":"inactive" ,
            })),
            'Gangs.pdf'
        );
    };

    useEffect(() => {

        getAllGangs(cookies)
        .then( gangList => {
            console.log(JSON.stringify(gangList)+"data2")
            const tableData = gangList.gang_list.map(r => ({
                id :r.id,
                entity_name: r.entity_name,
                vendor_name: r.vendor_name,
                    contact_person:r.contact_person,
                    contact_mobile_no:r.contact_mobile_no,
                    status:r.status ? "active":"inactive",
                    hide_row_edit:r.system_field?"Yes":"No",
                    hide_row_delete:r.system_field?"Yes":"No"
            }));

            setData({
                total: data.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h4 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Gangs</h4>
            <br/>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper
                            title='List of Gangs'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditGang}
                            onRowDelete={onDeleteGang}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        />
                </div>
                </div> 
            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/Gang")}}>Back</button>
        </div>

        
    );

}



export default GangTable;
