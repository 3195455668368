import React from 'react';
import { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { Row, Col, Button } from "reactstrap";
import axios from "axios";
import Sidebar from '../../Sidebar';
import { useNavigate } from 'react-router';
import { BiSave } from "react-icons/bi";
import { IoIosEye } from "react-icons/io";
import { TiCancel } from "react-icons/ti";
import { AiOutlineHome } from "react-icons/ai";
import { useCookies } from 'react-cookie';
import { useLocation, Link } from 'react-router-dom';
import {displayErrorToast,displayError,parseBoolean} from '../../helpers';
import ErrorToast from '../../ErrorToast';
import { getAllPlants,getUserAllowedPlants } from '../../services/PlantServices';
import FloatingControls from '../../components/FloatingControls';
import LoadingOverlay from '../../components/LoadingOverlay';
import { createPlantConfiguration } from '../../services/PlantConfigurationServices';
import TableRowsPlantConfig from "../../forms/admin/TableRowsPlantConfig";

function PlantConfiguration() {
  const [isChecked, setIsChecked] = useState({
    "is_silo_1":false,
    "is_silo_2":false,
    "is_silo_3":false,
    "is_silo_4":false,
    "is_silo_5":false,
    "is_bin_1":false,
    "is_bin_2":false,
    "is_bin_3":false,
    "is_bin_4":false,
    "is_bin_5":false,
    "is_other_1":false,
    "is_other_2":false,
    "is_other_3":false,
    "is_other_4":false,
    "is_other_5":false
})
  const [columnList,setColumnList] =useState([]);
  const [category] = useCookies(['myCategory']);
  const [error, setError] = useState({});
  // const [isSubmitting, setIsSubmitting] = useState(true);
  const [superuser] = useCookies(['mySuperuser']);
  const [cookies] = useCookies(['myToken']);
  const [inProgress, setInProgress] = useState(false);
  const [inputs, setInputs] = useState({
    "target_cycle":1,
    "plant_id":'',
    "silo_1":"Cement1",
    "silo_2":"Cement2",
    "silo_3":"Flyash",
    "silo_4":"GGBS",
    "silo_5":"OPTION",
    "bin_1":"12 MM",
    "bin_2":"20 MM",
    "bin_3":"R SAND",
    "bin_4":"M SAND",
    "bin_5":"C SAND",
    "other_1": "Water1",
    "other_2":"Water2",
    "other_3":"Admix1",
    "other_4":"Admix2",
    "other_5":"Others",
    // "add_extra_columns":false,
    // "show_all_columns":false,
    "user_remarks":""
});
  const [columnNumber,setColumnNumber] = useState({
    "silo_1_no":0,
    "silo_2_no":0,
    "silo_3_no":0,
    "silo_4_no":0,
    "silo_5_no":0,
    "bin_1_no":0,
    "bin_2_no":0,
    "bin_3_no":0,
    "bin_4_no":0,
    "bin_5_no":0,
    "other_1_no":0,
    "other_2_no":0,
    "other_3_no":0,
    "other_4_no":0,
    "other_5_no":0
  })
  const [plants,setPlants] =React.useState([]);
  const [rowsData, setRowsData] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const addTableRows = () => {
    const rowsInput={
            header_name:'',
            column_number:0,
            value:'0'
        }
        setRowsData([...rowsData, rowsInput])
  }
    
  const deleteTableRows = (index)=>{
        const rows = [...rowsData];
        rows.splice(index, 1);
        setRowsData(rows);
  }

  const handletableChange = (index, evnt)=>{
    const { name, value } = evnt.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  }

  React.useEffect(() => {
    getUserAllowedPlants(cookies)
    .then (
        plantList => {
          // console.log(JSON.stringify(plantList)+"plantList")
          const plant = plantList.plant_list.filter(obj => obj.status).map(
              plant => {
                  return { value: plant.id, label: plant.plant_alias }
              }
          );
          setPlants(plant);
        }
    )
  }, []);

  const navigate = useNavigate();
  const validate_handle_change = (name, value, chkbox, error_field_name) => {
    console.log(name,value,chkbox,error_field_name+"validate_handle_change")
    let customErrorMessage = error_field_name + " must not be empty if its checkbox is checked."
    setError((values) => ({ ...values, [name]: (chkbox && (!(value.trim()))) ? customErrorMessage : "" }));
  };
  
  const handleColumnNo =(event) => {
    try {
      const name = event.target.name;
      const value = event.target.value;
      setColumnNumber(values => ({ ...values, [name]: event.target.value }))
    }
    catch(e)
    {
      displayErrorToast(e);
    } 
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    try{
      if(name === 'silo_1')
      {
        validate_handle_change(name,value,isChecked.is_silo_1,"Silo 1")
      }
      else if(name === 'silo_2')
      {
        validate_handle_change(name,value,isChecked.is_silo_2,"Silo 2")
      }
      else if(name === 'silo_3')
      {
        validate_handle_change(name,value,isChecked.is_silo_3,"Silo 3")
      }
      else if(name === 'silo_4')
      {
        validate_handle_change(name,value,isChecked.is_silo_4,"Silo 4")
      }
      else if(name === 'silo_5')
      {
        validate_handle_change(name,value,isChecked.is_silo_5,"Silo 5")
      }
      else if(name === 'bin_1')
      {
        validate_handle_change(name,value,isChecked.is_bin_1,"Bin 1")
      }
      else if(name === 'bin_2')
      {
        validate_handle_change(name,value,isChecked.is_bin_2,"Bin 2")
      }
      else if(name === 'bin_3')
      {
        validate_handle_change(name,value,isChecked.is_bin_3,"Bin 3")
      }
      else if(name === 'bin_4')
      {
        validate_handle_change(name,value,isChecked.is_bin_4,"Bin 4")
      }
      else if(name === 'bin_5')
      {
        validate_handle_change(name,value,isChecked.is_bin_5,"Bin 5")
      }
      else if(name === 'other_1')
      {
        validate_handle_change(name,value,isChecked.is_other_1,"Other 1")
      }
      else if(name === 'other_2')
      {
        validate_handle_change(name,value,isChecked.is_other_2,"Other 2")
      }
      else if(name === 'other_3')
      {
        validate_handle_change(name,value,isChecked.is_other_3,"Other 3")
      }
      else if(name === 'other_4')
      {
        validate_handle_change(name,value,isChecked.is_other_4,"Other 4")
      }
      else if(name === 'other_5')
      {
        validate_handle_change(name,value,isChecked.is_other_5,"Other 5")
      }
      else if(name === 'add_extra_columns'){
        if(value === 'true'){
          setShowModal(true);
        }
      }
      setInputs(values => ({ ...values, [name]: event.target.value }))
    }
    catch(e)
    {
      displayErrorToast(e);
    } 
  }

  const validate_checkbox = (name, value, input_field,error_field, error_field_name) => {
    console.log(name,value,input_field,error_field,error_field_name+"validate_checkbox ")
    let customErrorMessage = error_field_name + "  must not be empty if its checkbox is checked."
    setError((values) => ({ ...values, [error_field]: (value && (!(input_field.trim()))) ? customErrorMessage : "" }));
  };


  const handleCheckboxes = (event) => {
    try{
    const name = event.target.name;
    const value = event.target.checked;
    console.log(value+name+"name valuecheck")
    if(name === 'is_silo_1')
    {
      validate_checkbox(name,value,inputs.silo_1,"silo_1","Silo 1")
    }
    else  if(name === 'is_silo_2')
    {
      validate_checkbox(name,value,inputs.silo_2,"silo_2","Silo 2")
    }
    else if(name === 'is_silo_3')
    {
      validate_checkbox(name,value,inputs.silo_3,"silo_3","Silo 3")
    }
    else if(name === 'is_silo_4')
    {
      validate_checkbox(name,value,inputs.silo_4,"silo_4","Silo 4")
    }
    else if(name === 'is_silo_5')
    {
      validate_checkbox(name,value,inputs.silo_5,"silo_5","Silo 5")
    }
    else if(name === 'is_bin_1')
    {
      validate_checkbox(name,value,inputs.bin_1,"bin_1","Bin 1")
    }
    else if(name === 'is_bin_2')
    {
      validate_checkbox(name,value,inputs.bin_2,"bin_2","Bin 2")
    }
    else if(name === 'is_bin_3')
    {
      validate_checkbox(name,value,inputs.bin_3,"bin_3","Bin 3")
    }
    else if(name === 'is_bin_4')
    {
      validate_checkbox(name,value,inputs.bin_4,"bin_4","Bin 4")
    }
    else if(name === 'is_bin_5')
    {
      validate_checkbox(name,value,inputs.bin_5,"bin_5","Bin 5")
    }
    else if(name === 'is_other_1')
    {
      validate_checkbox(name,value,inputs.other_1,"other_1","Other 1")
    }
    else if(name === 'is_other_2')
    {
      validate_checkbox(name,value,inputs.other_2,"other_2","Other 2")
    }
    else if(name === 'is_other_3')
    {
      validate_checkbox(name,value,inputs.other_3,"other_3","Other 3")
    }
    else if(name === 'is_other_4')
    {
      validate_checkbox(name,value,inputs.other_4,"other_4","Other 4")
    }
    else if(name === 'is_other_5')
    {
      validate_checkbox(name,value,inputs.other_5,"other_5","Other 5")
    }
    setIsChecked(values => ({...values, [name]: value}))
    }
    catch(e)
    {
      displayErrorToast(e);
    } 
  }

  const handleSubmit=(event)=> {
    event.preventDefault();
    console.log(JSON.stringify(isChecked)+"ischecked submit");
    console.log(JSON.stringify(inputs)+"inputs submit");
    console.log(JSON.stringify(error)+"error  submit");
    console.log(JSON.stringify(rowsData)+rowsData.length+"rowsData");
    console.log(JSON.stringify(columnNumber)+"columnNumber  submit");
    // console.log(isSubmitting+"isSubmitting");
    var column_list = []
    
    // if (inputs.show_all_columns === 'true'){
      Object.keys(isChecked).map(key => {
        if (isChecked[key]){
          console.log(key+"key checked");
          // Extracting the index from the key
          const index = parseInt(key.split("_")[2]);
          const name =key.split("_")[1];

          // Constructing the key for columnNumber
          const columnKey = name+'_'+index+'_no';
          console.log(columnKey+"column key")
            if (parseInt(columnNumber[columnKey]) > 0){
              // setColumnList(prevArray => [...prevArray, parseInt(columnNumber[columnKey])]);
              column_list.push(parseInt(columnNumber[columnKey]))
            }
        }
      })
    var isValid = 1;
    if((inputs.add_extra_columns === 'true')){
      if(rowsData.length == 0){
      // alert("inputs.add_extra_columns === 'true') && (rowsData.length == 0")
        // setIsSubmitting(false);
        isValid = 0;
        Swal.fire('If add extra columns is true then atleast one extra columns must be filled in table.', '', 'info'); 
      }
      else {
       // Iterate over each object in the array
        for (let i = 0; i < rowsData.length; i++) {
        const obj = rowsData[i];
        
        // Check whether the 'header_name' property is  empty
          if (!obj.header_name || obj.header_name.trim() === '') {
            // alert('Header name of extra columns list must not be empty.')
            isValid = 0;
            Swal.fire('Header name of extra columns list must not be empty.', '', 'info') 
            // setIsSubmitting(false); 
          }
          // Check whether the 'column_number' is greater than 0 and less than or equal to 15 plus the array length
          else if ((obj.column_number <= 0) || (obj.column_number > 20)) {
            // alert('column_number  of extra columns list must  be greater than 0 and must not be greater than 20')
            // setIsSubmitting(false);
            isValid = 0;
            Swal.fire('column_number  of extra columns list must  be greater than 0 and must not be greater than 20', '', 'info')  
          }
          else{
            // setIsSubmitting(true)
            // setColumnList(prevArray => [...prevArray, parseInt(obj.column_number)]);
            column_list.push(parseInt(obj.column_number));
          }
        }
      }
    }
    console.log(JSON.stringify(column_list)+"column_list initial");
    const set = new Set();
    for (let item of column_list) {
      // If the item is already in the set, it's a duplicate
      if (set.has(item)) {
        console.log(item+"item");
        Swal.fire(`Duplicates are not allowed.${item}`, '', 'info') 
        isValid=0 
      }
      // Otherwise, add it to the set
      set.add(item);
    }
    const maxNumber = Math.max(...column_list); // Find the maximum number in the array
    for (let i = 1; i < maxNumber; i++) {
      // Check if each number less than the max is present
      if (!column_list.includes(i)) {
        Swal.fire(`Column numbers must be in sequence.${i} is missing`, '', 'info')  // If any number is missing, return false
        isValid = 0;
      }
    }
   

    if ((Object.values(error).every(x => !x)) && (isValid === 1)){
      console.log(JSON.stringify(column_list)+"column_list");
      Swal.fire({title: 'Do you want to save?',  
          showCancelButton: true,  
          confirmButtonText: `Yes`,  
          cancelButtonText: `No`,
          }).then((result) => {  
            if (result.isConfirmed) { 
              console.log("can submit");
                createPlantConfiguration(cookies, {
                  target_cycle:parseInt(inputs.target_cycle),
                  plant_id:inputs.plant_id,
                  silo_1:inputs.silo_1,
                  is_silo_1:isChecked.is_silo_1,
                  silo_2:inputs.silo_2,
                  is_silo_2:isChecked.is_silo_2,
                  silo_3:inputs.silo_3,
                  is_silo_3:isChecked.is_silo_3,
                  silo_4:inputs.silo_4,
                  is_silo_4:isChecked.is_silo_4,
                  silo_5:inputs.silo_5,
                  is_silo_5:isChecked.is_silo_5,
                  bin_1:inputs.bin_1,
                  is_bin_1:isChecked.is_bin_1,
                  bin_2:inputs.bin_2,
                  is_bin_2:isChecked.is_bin_2,
                  bin_3:inputs.bin_3,
                  is_bin_3:isChecked.is_bin_3,
                  bin_4:inputs.bin_4,
                  is_bin_4:isChecked.is_bin_4,
                  bin_5:inputs.bin_5,
                  is_bin_5:isChecked.is_bin_5,
                  other_1: inputs.other_1,
                  is_other_1:isChecked.is_other_1,
                  other_2:inputs.other_2,
                  is_other_2:isChecked.is_other_2,
                  other_3:inputs.other_3,
                  is_other_3:isChecked.is_other_3,
                  other_4:inputs.other_4,
                  is_other_4:isChecked.is_other_4,
                  other_5:inputs.other_5,
                  is_other_5:isChecked.is_other_5,
                  add_extra_columns:inputs.add_extra_columns?parseBoolean(inputs.add_extra_columns):false,
                  show_all_columns:inputs.show_all_columns?parseBoolean(inputs.show_all_columns):false,
                  user_remarks:inputs.user_remarks,
                  silo_1_no:parseInt(columnNumber.silo_1_no),
                  silo_2_no:parseInt(columnNumber.silo_2_no),
                  silo_3_no:parseInt(columnNumber.silo_3_no),
                  silo_4_no:parseInt(columnNumber.silo_4_no),
                  silo_5_no:parseInt(columnNumber.silo_5_no),
                  bin_1_no:parseInt(columnNumber.bin_1_no),
                  bin_2_no:parseInt(columnNumber.bin_2_no),
                  bin_3_no:parseInt(columnNumber.bin_3_no),
                  bin_4_no:parseInt(columnNumber.bin_4_no),
                  bin_5_no:parseInt(columnNumber.bin_5_no),
                  other_1_no:parseInt(columnNumber.other_1_no),
                  other_2_no:parseInt(columnNumber.other_2_no),
                  other_3_no:parseInt(columnNumber.other_3_no),
                  other_4_no:parseInt(columnNumber.other_4_no),
                  other_5_no:parseInt(columnNumber.other_5_no),
                  detail_list:rowsData.map(q=>({
                    header_name:q.header_name,
                    column_number:parseInt(q.column_number),
                    value:q.value
                  }))
               })
              .then(response => {
                  Swal.fire("Saved!", "", "success");
                  Reload();
              }).catch((error) => {
                  displayError(error.response.data,"Saved Failed");
              });
            } 
            else if (result.isDismissed) 
            {    
              Swal.fire('Not saved', '', 'info')  
            }
          });
    }
  }

  const Cancel = () => {
    setInputs(() => "")
  }

  const Reload = () => {
    window.location.reload();
  } 

  const view = () => {
    navigate('/PlantConfigurationTable')
  } 

  const Back = () => {
    navigate('/Home')
  }

  const Close = () => {
    setShowModal(false)
  }

  return(
    <>
      <div id="outer-container" className="App" >
        <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
        <ErrorToast/>
        <LoadingOverlay inProgress={inProgress}/>
        <div id="page-wrap">
          <form onSubmit={handleSubmit} >
            <div id="header">
              <h4 className="text font-weight-bold page-title" >Plant Configuration</h4>
            </div>
            <FloatingControls tableLink="/PlantConfigurationTable" onCancel={Cancel} enableCancel={true}/>
            <div className="container"> 
                <Row> 
                  <Col xl={3} lg={12} md={12}> </Col>
                  <Col xl={12} lg={12} md={6}> 
                    <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel">
                     <label htmlFor="plant_id"className="form-group col-sm-2 text-right">Plant</label>
                     <select id="plant_id" name="plant_id" className="browser-default custom-select form-control col-sm-3 mandatory-form-control" onChange={handleChange} value={inputs.plant_id || ""} required>
                       <option value=''>Please Select plant </option>
                       {plants.map((item) => (
                         <option key={item.value} value={item.value}>
                         {item.label}</option>
                         ))}
                     </select>
                     <label htmlFor="target_cycle" className="form-group col-sm-3 text-right">Target Cycle </label>
                     <input type="number" id="target_cycle" min="1" max="180" name="target_cycle" onChange={handleChange} value={inputs.target_cycle || 1} className="form-control col-sm-3"  placeholder="Please fill the target cycle."  /> <br/>
                         
                     <label htmlFor="show_all_columns" className="form-group col-sm-2 text-right">Show all Columns?</label>
                      <select name="show_all_columns" id="show_all_columns" onChange={handleChange} value={inputs.show_all_columns || false} className="browser-default custom-select form-control col-sm-3 mandatory-form-control"  required>
                        <option value="">Please select</option> 
                       <option value="false">No</option>
                       <option value="true">Yes</option>
                     </select>

                     <label htmlFor="add_extra_columns" className="form-group col-sm-3 text-right">Add extra Columns?</label>
                     <select name="add_extra_columns" id="add_extra_columns" onChange={handleChange} value={inputs.add_extra_columns || false} className="browser-default custom-select form-control col-sm-3 mandatory-form-control"  required>
                       <option value="">Please select</option> 
                       <option value="false">No</option>
                       <option value="true">Yes</option>
                     </select> 
                   </div>
                  </Col> 
                         
              
                  <Col xl={4} lg={12} md={12}>    
                    <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel" >
                      <h5 className='col-sm-11'><b>SILO</b></h5>
                      <label htmlFor="silo_1" className="form-group col-sm-2 text-right">Silo 1 </label>
                      <input type="text" id="silo_1" maxLength="10" name="silo_1" onChange={handleChange} value={inputs.silo_1 || ""} className="form-control col-sm-6 "  placeholder="Please fill the silo 1 field *"  /> &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      <input type="checkbox" className="is_store" name="is_silo_1" id="" checked={isChecked.is_silo_1 || false} onChange={handleCheckboxes}/> &nbsp;&nbsp;&nbsp;
                      <input type="number" min = {((isChecked.is_silo_1) && (inputs.show_all_columns ==='true')) ? "1":"0"} max="20" id="silo_1_no"  name="silo_1_no" onChange={handleColumnNo} value={columnNumber.silo_1_no || 0} className="form-control col-sm-2 "   /> &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      {error.silo_1 && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.silo_1}</strong><br/></>)}

                      <label htmlFor="silo_2" className="form-group col-sm-2 text-right">Silo 2 </label>
                      <input type="text" id="silo_2" maxLength="10" name="silo_2" onChange={handleChange} value={inputs.silo_2 || ""} className="form-control col-sm-6"  placeholder="Please fill the silo 2 field *"  />&nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      <input type="checkbox" className="is_store" name="is_silo_2" id="" checked={isChecked.is_silo_2 || false}  onChange={handleCheckboxes} />&nbsp;&nbsp;&nbsp;
                      <input type="number" min = {((isChecked.is_silo_2) && (inputs.show_all_columns ==='true')) ? "1":"0"}  max="20" id="silo_2_no"  name="silo_2_no" onChange={handleColumnNo} value={columnNumber.silo_2_no || 0} className="form-control col-sm-2 "   /> &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      {error.silo_2 && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.silo_2}</strong><br/></>)}

                      <label htmlFor="silo_3" className="form-group col-sm-2 text-right">Silo 3 </label>
                      <input type="text" id="silo_3"  maxLength="10" name="silo_3" onChange={handleChange} value={inputs.silo_3 || ""} className="form-control col-sm-6"  placeholder="Please fill the silo 3 field *"  />&nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      <input type="checkbox" className="is_store" name="is_silo_3" id="" checked={isChecked.is_silo_3 || false} onChange={handleCheckboxes}  />&nbsp;&nbsp;&nbsp;
                      <input type="number" min = {((isChecked.is_silo_3) && (inputs.show_all_columns ==='true')) ? "1":"0"}  max="20" id="silo_3_no"  name="silo_3_no" onChange={handleColumnNo} value={columnNumber.silo_3_no || 0} className="form-control col-sm-2 "   /> &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      {error.silo_3 && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.silo_3}</strong><br/></>)}

                      <label htmlFor="silo_4" className="form-group col-sm-2 text-right">Silo 4 </label>
                      <input type="text" id="silo_4" maxLength="10"  name="silo_4" onChange={handleChange} value={inputs.silo_4 || ""} className="form-control col-sm-6"  placeholder="Please fill the silo 4 field *"  />&nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      <input type="checkbox" className="is_store" name="is_silo_4" id="" checked={isChecked.is_silo_4 || false}  onChange={handleCheckboxes} />&nbsp;&nbsp;&nbsp;
                      <input type="number" min ={((isChecked.is_silo_4) && (inputs.show_all_columns ==='true')) ? "1":"0"} max="20" id="silo_4_no"  name="silo_4_no" onChange={handleColumnNo} value={columnNumber.silo_4_no || 0} className="form-control col-sm-2 "   /> &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      {error.silo_4 && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.silo_4}</strong><br/></>)}

                      <label htmlFor="silo_5" className="form-group col-sm-2 text-right">Silo 5 </label>
                      <input type="text" id="silo_5" maxLength="10"  name="silo_5" onChange={handleChange} value={inputs.silo_5 || ""} className="form-control col-sm-6"  placeholder="Please fill the silo 5 field *"  />&nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      <input type="checkbox" className="is_store" name="is_silo_5" id="" checked={isChecked.is_silo_5 || false}  onChange={handleCheckboxes} />&nbsp;&nbsp;&nbsp;
                      <input type="number" min = {((isChecked.is_silo_5) && (inputs.show_all_columns ==='true')) ? "1":"0"}  max="20" id="silo_5_no"  name="silo_5_no" onChange={handleColumnNo} value={columnNumber.silo_5_no || 0} className="form-control col-sm-2 "   /> &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      {error.silo_5 && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.silo_5}</strong><br/></>)}
                    </div>
                  </Col>
              
                  <Col xl={4} lg={12} md={12}>  
                    <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel">
                      <h5 className='col-sm-11'><b>AGGREGATE BINS</b></h5>

                      <label htmlFor="bin_1" className="form-group col-sm-2 text-right">Bin 1 </label>
                      <input type="text" id="bin_1" maxLength="10"  name="bin_1" onChange={handleChange} value={inputs.bin_1 || ""} className="form-control col-sm-6"  placeholder="Please fill the bin 1 field *"  /> &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      <input type="checkbox" className="is_store" name="is_bin_1" id="" checked={isChecked.is_bin_1 || false}  onChange={handleCheckboxes} />&nbsp;&nbsp;&nbsp;
                      <input type="number" min = {((isChecked.is_bin_1) && (inputs.show_all_columns ==='true')) ? "1":"0"}  max="20" id="bin_1_no"  name="bin_1_no" onChange={handleColumnNo} value={columnNumber.bin_1_no || 0} className="form-control col-sm-2 "   /> &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      {error.bin_1 && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.bin_1}</strong><br/></>)}

                      <label htmlFor="bin_2" className="form-group col-sm-2 text-right">Bin 2 </label>
                      <input type="text" id="bin_2" maxLength="10"  name="bin_2" onChange={handleChange} value={inputs.bin_2 || ""} className="form-control col-sm-6"  placeholder="Please fill the bin 2 field *"  />&nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      <input type="checkbox" className="is_store" name="is_bin_2" id="" checked={isChecked.is_bin_2 || false}  onChange={handleCheckboxes} />&nbsp;&nbsp;&nbsp;
                      <input type="number" min = {((isChecked.is_bin_2) && (inputs.show_all_columns ==='true')) ? "1":"0"}  max="20" id="bin_2_no"  name="bin_2_no" onChange={handleColumnNo} value={columnNumber.bin_2_no || 0} className="form-control col-sm-2 "   /> &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      {error.bin_2 && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.bin_2}</strong><br/></>)}
                       
                      <label htmlFor="bin_3" className="form-group col-sm-2 text-right">Bin 3 </label>
                      <input type="text" id="bin_3" maxLength="10"  name="bin_3" onChange={handleChange} value={inputs.bin_3 || ""} className="form-control col-sm-6"  placeholder="Please fill the bin 3 field *"  />&nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      <input type="checkbox" className="is_store" name="is_bin_3" id="" checked={isChecked.is_bin_3 || false}  onChange={handleCheckboxes} />&nbsp;&nbsp;&nbsp;
                      <input type="number" min = {((isChecked.is_bin_3) && (inputs.show_all_columns ==='true')) ? "1":"0"}  max="20" id="bin_3_no"  name="bin_3_no" onChange={handleColumnNo} value={columnNumber.bin_3_no || 0} className="form-control col-sm-2 "   /> &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      {error.bin_3 && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.bin_3}</strong><br/></>)}
                      
                      <label htmlFor="bin_4" className="form-group col-sm-2 text-right">Bin 4 </label>
                      <input type="text" id="bin_4" maxLength="10"  name="bin_4" onChange={handleChange} value={inputs.bin_4 || ""} className="form-control col-sm-6"  placeholder="Please fill the bin 4 field *"  />&nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      <input type="checkbox" className="is_store" name="is_bin_4" id="" checked={isChecked.is_bin_4 || false} onChange={handleCheckboxes}  />&nbsp;&nbsp;&nbsp;
                      <input type="number" min = {((isChecked.is_bin_4) && (inputs.show_all_columns ==='true')) ? "1":"0"} max="20"  id="bin_4_no"  name="bin_4_no" onChange={handleColumnNo} value={columnNumber.bin_4_no || 0} className="form-control col-sm-2 "   /> &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      {error.bin_4 && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.bin_4}</strong><br/></>)}
                      
                      <label htmlFor="bin_5" className="form-group col-sm-2 text-right">Bin 5 </label>
                      <input type="text" id="bin_5" maxLength="10"  name="bin_5" onChange={handleChange} value={inputs.bin_5 || ""} className="form-control col-sm-6"  placeholder="Please fill the bin 5 field *"  />&nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      <input type="checkbox" className="is_store" name="is_bin_5" id="" checked={isChecked.is_bin_5 || false}  onChange={handleCheckboxes} />&nbsp;&nbsp;&nbsp;
                      <input type="number" min ={((isChecked.is_bin_5) && (inputs.show_all_columns ==='true')) ? "1":"0"} max="20"  id="bin_5_no"  name="bin_5_no" onChange={handleColumnNo} value={columnNumber.bin_5_no || 0} className="form-control col-sm-2 "   /> &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      {error.bin_5 && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.bin_5}</strong><br/></>)}
                    </div>
                  </Col>

                  <Col xl={4} lg={12} md={12}>  
                    <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel">
                      <h5 className='col-sm-11'><b>OTHERS</b></h5>

                      <label htmlFor="other_1" className="form-group col-sm-3 text-right">Other 1 </label>
                      <input type="text" id="other_1" maxLength="10"  name="other_1" onChange={handleChange} value={inputs.other_1 || ""} className="form-control col-sm-5"  placeholder="Please fill the other 1 field *"  /> &nbsp;&nbsp;<br/>
                      <input type="checkbox" className="is_store" name="is_other_1" id="" checked={isChecked.is_other_1 || false}  onChange={handleCheckboxes} />
                      &nbsp;&nbsp;&nbsp;
                      <input type="number" min = {((isChecked.is_other_1) && (inputs.show_all_columns ==='true')) ? "1":"0"}  max="20" id="other_1_no"  name="other_1_no" onChange={handleColumnNo} value={columnNumber.other_1_no || 0} className="form-control col-sm-2 "   /> &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      {error.other_1 && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.other_1}</strong><br/></>)}
                      
                      <label htmlFor="other_2" className="form-group col-sm-3 text-right">Other 2 </label>
                      <input type="text" id="other_2" maxLength="10"  name="other_2" onChange={handleChange} value={inputs.other_2 || ""} className="form-control col-sm-5"  placeholder="Please fill the other 2 field *"  />&nbsp;&nbsp;<br/>
                      <input type="checkbox" className="is_store" name="is_other_2" id="" checked={isChecked.is_other_2 || false} onChange={handleCheckboxes}  />
                      &nbsp;&nbsp;&nbsp;
                      <input type="number" min = {((isChecked.is_other_2) && (inputs.show_all_columns ==='true')) ? "1":"0"}max="20"  id="other_2_no"  name="other_2_no" onChange={handleColumnNo} value={columnNumber.other_2_no || 0} className="form-control col-sm-2 "   /> &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      {error.other_2 && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.other_2}</strong><br/></>)}
                        
                      <label htmlFor="other_3" className="form-group col-sm-3 text-right">Other 3 </label>
                      <input type="text" id="other_3" maxLength="10"  name="other_3" onChange={handleChange} value={inputs.other_3 || ""} className="form-control col-sm-5"  placeholder="Please fill the other 3 field *"  />&nbsp;&nbsp;<br/>
                      <input type="checkbox" className="is_store" name="is_other_3" id="" checked={isChecked.is_other_3 || false} onChange={handleCheckboxes}  />
                      &nbsp;&nbsp;&nbsp;
                      <input type="number" min ={((isChecked.is_other_3) && (inputs.show_all_columns ==='true')) ? "1":"0"} id="other_3_no"   max="20" name="other_3_no" onChange={handleColumnNo} value={columnNumber.other_3_no || 0} className="form-control col-sm-2 "   /> &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      {error.other_3 && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.other_3}</strong><br/></>)}
                      
                      <label htmlFor="other_4" className="form-group col-sm-3 text-right">Other 4 </label>
                      <input type="text" id="other_4" maxLength="10"  name="other_4" onChange={handleChange} value={inputs.other_4 || ""} className="form-control col-sm-5"  placeholder="Please fill the other 4 field *"  />&nbsp;&nbsp;<br/>
                      <input type="checkbox" className="is_store" name="is_other_4" id="" checked={isChecked.is_other_4 || false}  onChange={handleCheckboxes} />
                      &nbsp;&nbsp;&nbsp;
                      <input type="number" min = {((isChecked.is_other_4) && (inputs.show_all_columns ==='true')) ? "1":"0"} id="other_4_no"  max="20"  name="other_4_no" onChange={handleColumnNo} value={columnNumber.other_4_no || 0} className="form-control col-sm-2 "   /> &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      {error.other_4 && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.other_4}</strong><br/></>)}
                      
                      <label htmlFor="other_5" className="form-group col-sm-3 text-right">Other 5 </label>
                      <input type="text" id="other_5" maxLength="10"  name="other_5" onChange={handleChange} value={inputs.other_5 || ""} className="form-control col-sm-5"  placeholder="Please fill the other 5 field *"  />&nbsp;&nbsp;<br/>
                      <input type="checkbox" className="is_store" name="is_other_5" id="" checked={isChecked.is_other_5 || false} onChange={handleCheckboxes}  />
                      &nbsp;&nbsp;&nbsp;
                      <input type="number" min = {((isChecked.is_other_5) && (inputs.show_all_columns ==='true')) ? "1":"0"} id="other_5_no" max="20"   name="other_5_no" onChange={handleColumnNo} value={columnNumber.other_5_no || 0} className="form-control col-sm-2 "   /> &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                      {error.other_5 && (<><strong className="form-group col-sm-12 text-center text-danger" >{error.other_5}</strong><br/></>)}
                      
                    </div>
                  </Col>
                </Row>  
                <div className="form-row table-bordered shadow p-2 my-2 border-secondary p-2 mb-3 bg-blue form-control-panel" >
                  <label htmlFor="user_remarks" className="form-group col-sm-2 text-left">Remarks</label>
                  <textarea rows="2" cols="50" className="form-control col-sm-12" id="user_remarks" name="user_remarks" onChange={handleChange} value={inputs.user_remarks || ""}></textarea>
                </div>   
                <div className="modal" tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }}>
                    <div className="modal-dialog modal-xl" style={{height: "500px",width:"500px"}} >
                      <div className="modal-content">
                        <div className="modal-body display">
                          <div className="container item-list-table-container">
                           <table className="table order-list" id="myTable"  style={{textAlign:"center",borderRadius:"10px"}}>
                              <thead>
                               <tr>
                                 <th><button className="btn btn-success" onClick={addTableRows} >+</button></th>
                                 <th>Header Name</th>
                                 <th>Column No</th>
                                 <th>Value</th>
                               </tr>
                             </thead>
                             <tbody >
                               <TableRowsPlantConfig rowsData={rowsData} deleteTableRows={deleteTableRows} handletableChange={handletableChange} />
                             </tbody>
                            </table>
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={Close}>Close</button> 
                          </div> 
                        </div>
                        {/* <div className="modal-footer"> 
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> 
                        </div> */}
                      </div>
                    </div>
                  </div>
               
               <div className="footer text-center">
                <Button  type="submit" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} >Save</Button> &nbsp;&nbsp;
                <Button type="reset" className="btn btn-twitter" style={{width:"80px",fontWeight:"bold"}} onClick={Cancel}>Reset</Button> &nbsp;&nbsp;
                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={view}>View</Button>&nbsp;&nbsp;
                <Button className="btn btn-twitter"  type="button"style={{width:"80px",fontWeight:"bold"}} onClick={Back}>Home</Button>
              </div>
              </div>
            </form>
          </div>
        </div>
    </>
  );
}
export default PlantConfiguration;