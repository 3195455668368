import {getAllPermission} from '../../services/PermissionServices'


export function checkForDuplicatePermission(name, value, cookies, existingId) {
    
    
    let nameCheck = new Promise((resolve, reject) => {
      getAllPermission(cookies)
      .then((data) => {

        let isUserExists = false;
        data.group_list.forEach(Permission=>{    //Same Permission Name mentioned it denoted already exist.
        
          if((Permission.name.toUpperCase()) === value.trim().toUpperCase()
            && parseInt(existingId) !== Permission.id){   
            
            console.log(`Permission exists with the name - ${value}`) 
            isUserExists = true;
     
          }
        
        resolve(isUserExists);
      });  
    })
    });

    return nameCheck;
  }

  export function updateSectionColor(checkboxClass,setSection) {
    const isChecked = document.querySelectorAll(`${checkboxClass}:checked`).length > 0;
    console.log(document.querySelectorAll(`${checkboxClass}:checked`).length+"isChecked");
    // substring(4) extracts characters from index 4 to the end of the checkboxClass string.goal is to remove .is_ in the checkboxClass variable.
    const sectionId =  checkboxClass.substring(4);
    console.log(checkboxClass,sectionId+"checkboxClass, sectionId");
    setSection(values => ({...values, [sectionId]: isChecked}));
    const section = document.getElementById(sectionId);
    section.style.color = isChecked ? 'green' : 'blue';
    section.style.backgroundColor = isChecked ? '#aaffc3':'white';
    return section;
    // lightgreen
}