function TableRows({rowsData, deleteTableRows, handleChange}) {


    return(
        
        rowsData.map((data, index)=>{
            const {prefix,valid_from_date,starting_number,number_of_digits,valid_upto_date,user_remarks}=data
            return(

        <tr  key={index} className="text-center detailclass" id="after-this1">
            <td><button className="btn btn-danger" onClick={()=>(deleteTableRows(index))}>x</button></td>
            <td><input type="text" required name="prefix" value={prefix} onChange={(evnt)=>(handleChange(index, evnt))} style={{width:"250px"}} className="form-control " /></td>
            <td><input type="number" colSpan="1" style={{width: "150px"}} className="form-control  mandatory-form-control" value={starting_number} onChange={(evnt)=>(handleChange(index, evnt))}  name="starting_number" required min="0"/></td>
            <td><input type="number" colSpan="1" style={{width: "150px"}} className="form-control  mandatory-form-control" value={number_of_digits} onChange={(evnt)=>(handleChange(index, evnt))}  name="number_of_digits" required min="0"/></td>
            <td><input type="date" id="valid_from_date" name="valid_from_date"value={valid_from_date} onChange={(evnt)=>(handleChange(index, evnt))}  style={{width:"160px"}}  className="form-control  rounded  mandatory-form-control" required  maxLength="50"/><br/>
            </td>
            <td><input type="date"  id="valid_upto_date"  name="valid_upto_date" value={valid_upto_date} onChange={(evnt)=>(handleChange(index, evnt))} style={{width:"160px"}} className="form-control  rounded  mandatory-form-control" required maxLength="50"/><br/></td>
            <td><input type="text" colSpan="1" style={{width: "150px"}} className="form-control" value={user_remarks} onChange={(evnt)=>(handleChange(index, evnt))}  name="user_remarks"/></td>
        </tr>

            )
        })
   
    )
    
}

export default TableRows;