import { useEffect, useState, useMemo } from "react";
import ReactTableWrapper from "../../components/ReactTableWrapper";
import { exportAsCSV } from "../../utils/CSVExporter";
import { exportAsPDF } from "../../utils/PDFExporter";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import Sidebar from '../../Sidebar';
import Swal from "sweetalert2";
import { useCookies } from 'react-cookie';
import { getAllUnits, deleteUnit } from "../../services/UnitServices";
import { displayError } from "../../helpers";
function UnitTable() {
    const InitialData = {
        total_pages: 0,
        records: [],
      };
    const [data, setData] = useState(InitialData);
    const navigate = useNavigate(); 
    const [refreshKey, setRefreshKey] = useState(0);
    const [cookies] = useCookies(['myToken']);
    const [isLoading, setIsLoading] = useState(true);  
    const columns = useMemo(
        () => [
            {
                fieldName: 'name', 
                headerName: 'Name'
            },
           
            {
                fieldName: "symbol",
                headerName: 'Symbol'
            },
            {
                fieldName: "user_remarks",
                headerName: 'Remarks'
            },
           
            {
                fieldName: 'status',
                headerName: 'Status',
            },
            {
                fieldName: "id",
                headerName: "ROW_ACTIONS"
            }
        ],
        [],);

    const onEditUnit = (unitId, event) => {
       
       navigate(`/UnitEdit/${unitId}/`);
    };

    const onDeleteUnit = (unitId, event) => {

        event.preventDefault();
        Swal.fire({title: 'Are you sure to Delete?',  
        showCancelButton: true,  
        confirmButtonText: `Yes`,  
        cancelButtonText: `No`,
        }).then((result) => {  
          if (result.isConfirmed) { 

            setIsLoading(true);
            deleteUnit(cookies, unitId)
            .then(response => {
                console.log(`Unit with id ${unitId} deleted`);
                Swal.fire('Deleted Successfully!', '', 'success');
                setRefreshKey(oldKey => oldKey +1)
            })
            .catch(error => {
                setRefreshKey(oldKey => oldKey +1);
                console.log(error.response.data);
                displayError(error.response.data,'Delete Failed');
            });  
           
          } else if (result.isDismissed) {    
            Swal.fire('Not Deleted', '', 'info')  
        }
        });
        //

    };

    const handleCSVExport = (event) => {
        console.log('CSV Export');
        exportAsCSV(
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(c => c.headerName),
            data.records.map(r => {

                const csvRow = {
                    name: r.name,
                    symbol: r.symbol,
                    user_remarks:r.user_remarks,
                    status:r.status
                }
                return csvRow;
                }));
    };

    const handlePDFExport = (event) => {
        console.log('PDF Export');
        exportAsPDF(
            'List of Units',
            columns
            .filter(col => col.headerName !== 'ROW_ACTIONS')
            .map(col=>({header: col.headerName, dataKey: col.fieldName })),
            data.records.map(r => ({
                    name: r.name,
                    symbol: r.symbol,
                    user_remarks:r.user_remarks,
                    status:r.status
            })),
            'Units.pdf'
        );
    };

    useEffect(() => {

        getAllUnits(cookies)
        .then( unitList => {
            const tableData = unitList.unit_list
            .map(unit => ({
                name: unit.name,
                symbol:unit.symbol,
                user_remarks:unit.user_remarks,
                status:unit.status? "Active":"Inactive",
                id: unit.id, 
                hide_row_edit:unit.system_field?"Yes":"No",
                hide_row_delete:unit.system_field?"Yes":"No" 
            }));

            setData({
                total: tableData.total,
                records: tableData
            });
            setIsLoading(false);
        });
        }, [refreshKey]);
    
    return (

        <div id="outer-container"  className="App" > 
            <Sidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'} />
            <div id="page-wrap">
            <h4 style={{color:"rgb(2, 2, 49)",textAlign:"center"}}>List of Units</h4>
                <div className="container item-list-table-container">
                <div className="table-responsive">
                <ReactTableWrapper    
                            title='List of Unit of Measurement'
                            columns={columns}
                            data={data.records}
                            onRowEdit={onEditUnit}
                            onRowDelete={onDeleteUnit}
                            onCSVExport={handleCSVExport}
                            onPDFExport={handlePDFExport}
                            isLoading={isLoading}
                        />
                </div>
                </div> 


            </div>
            <button style={{position:"relative",right:"40px"}}className="btn btn-fill btn-primary float-end" type="button" onClick={() =>{navigate("/Unit")}}>Back</button>
        </div>

        
    );

}



export default UnitTable;